import { useMakeFetch } from '@chipinside/fetcher'
import {
  AnimalIcon,
  BatchIcon,
  HardwareIcon,
  LoadingIconButton,
  SearchIcon,
  Translate,
} from '@chipinside/frontend'
import {
  Card,
  InputBase,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEqual } from 'lodash'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import uniqueId from 'lodash/uniqueId'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'sonner'

import FindMyCow, { FindMyCowButton } from '#/components/FindMyCow'
import ActionsDialog from '#/components/Modal/ActionDialog'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import useWidth from '#/store/hooks/useWidth'
import { endpoint } from '#/store/services/endpoints'
import Colors from '#/styles/Old/Colors'
import { getLink } from '#/utils/getLink'
import textFormatter from '#/utils/textFormatter'

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: 32,
    minWidth: 32,
    position: 'relative',
    margin: 'auto 8px !important',
  },
  root: {
    top: 0,
    width: '60vw',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    backgroundColor: '#ececec',
    borderRadius: 16,
    transition: 'width .25s ease, background-color .25s ease',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: 400,
      position: 'relative',
    },
    [theme.breakpoints.down('md')]: {
      'width': 300,
      '&.shrunk': {
        width: '100%',
        backgroundColor: 'transparent',
      },
    },
  },
  rootBlank: {
    'width': '100vw',
    'margin': '-6px 0',
    'top': 0,
    'bottom': 0,
    'position': 'absolute',
    'backgroundColor': '#fff',
    'zIndex': 2,
    [theme.breakpoints.up('md')]: {
      width: 0,
    },
    '&.shrunk': {
      width: 0,
    },
  },
  rootSuggestions: {
    top: 32,
    marginTop: 5,
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxWidth: 400,
  },
  listContainer: {
    padding: '16px 0',
    borderBottom: 'solid 1px #e0e0e0',
    flex: 1,
  },
  listRoot: {
    width: '100%',
    padding: 0,
  },
  listItem: {
    padding: 0,
    fontSize: '1em',
  },
  listItemLink: {
    'width': '100%',
    'padding': '4px 16px',
    'display': 'flex',
    'alignItems': 'center',
    'fontSize': '1em',
    'color': 'inherit',
    '&:hover': {
      color: Colors.primary,
    },
  },
  listTitle: {
    marginLeft: 16,
    fontSize: '1em',
  },
  input: {
    paddingLeft: 8,
    flex: 1,
    fontSize: '1em',
    minHeight: 32,
    backgroundColor: 'transparent',
  },
  iconButton: {
    margin: '0 7px',
    pointerEvents: 'none',
    padding: 0,
    transition: 'padding .5s ease-in-out',
    [theme.breakpoints.down('xl')]: {
      pointerEvents: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 10,
    },
  },
  avatar: {
    marginRight: 8,
    borderRadius: 15,
    width: 30,
    minWidth: 30,
    height: 30,
    minHeight: 30,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}))

function GlobalSearch() {
  const classes = useStyles()
  const width = useWidth()

  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const controller = endpoint.farm.global_search({
    farm: currentFarmSlug,
  })

  const [openSuggestions, setOpenSuggestions] = useState(false)
  const [shrunkSearch, setShrunkSearch] = useState(false)
  const [boundingLeftBox, setBoundingLeftBox] = useState(null)

  const [{ data, isLoading, error }, makeRequest] = useMakeFetch()

  const suggestionsTooltip = useRef()
  const elementRef = useRef()

  const bounding = elementRef?.current?.getBoundingClientRect()

  const dialogInitialState = {
    type: null,
    dialogOpened: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogContent: null,
    dialogOnClose: () => {},
  }
  const [dialogActions, setDialogActions] = useState(dialogInitialState)

  const handleConfirm = (item, type) => {
    setDialogActions({
      dialogOpened: true,
      dialogWidth: 'md',
      dialogContent: (
        <FindMyCow
          farm={currentFarmSlug}
          animal={item.slug}
          collar={item.code}
          type={type}
          onClose={() => setDialogActions(dialogInitialState)}
        />
      ),
      dialogOnClose: () => setDialogActions(dialogInitialState),
    })
  }

  useEffect(() => {
    if (bounding) {
      setBoundingLeftBox(bounding.left)
    }
  }, [bounding])

  // close if clicked on outside of element
  const handleClickOutsideSuggestions = evt => {
    if (!suggestionsTooltip?.current?.contains(evt.target)) {
      setOpenSuggestions(false)
    }
  }

  const handleClickOnResult = () => {
    setOpenSuggestions(false)
  }

  const getInfo = debounce(
    val => makeRequest({ controller, params: { search: val } }),
    1200,
  )

  const handleInputChange = event =>
    event?.target?.value && getInfo(event.target.value)

  const handleInputClick = event => {
    if (!isEmpty(event.target.value)) {
      setOpenSuggestions(true)
    }
  }

  const getLabelsTranslationsAndLink = (key, data) => {
    let formattedSubject = {
      label: null,
      none: Translate({ messageKey: 'datatable_no_records_found' }),
      quantity: (() => {
        const amount = !isNil(data.total) ? data.total : data.results.length
        return amount > 5
          ? Translate({
              messageKey: 'showing_quantity_of_total_results',
              params: { quantity: 5, total: amount },
            })
          : Translate({
              messageKey: 'results_found_plural',
              params: { num: amount },
            })
      })(),
    }

    switch (key) {
      case 'animals':
        formattedSubject = {
          ...formattedSubject,
          title: Translate({ messageKey: 'animal_plural' }),
          icon: <AnimalIcon />,
          text: item => textFormatter(item, ['earring', 'name']),
          link: item =>
            getLink({
              type: 'animal',
              farm: currentFarmSlug,
              slug: item.slug,
            }),
        }
        break

      case 'batches':
        formattedSubject = {
          ...formattedSubject,
          title: Translate({ messageKey: 'batch_plural' }),
          icon: <BatchIcon />,
          text: item => item.name,
          link: item =>
            getLink({
              type: 'batch',
              farm: currentFarmSlug,
              slug: item.slug,
              tab: 'monitoring',
            }),
        }
        break

      case 'collars':
        formattedSubject = {
          ...formattedSubject,
          title: Translate({ messageKey: 'collar_plural' }),
          icon: <HardwareIcon fontSize="small" />,
          text: item => item.code,
          link: item =>
            getLink({
              type: 'c-tech-map',
              farm: currentFarmSlug,
              id: item.code,
            }),
        }
        break
      default: //nothing to moo now but later
    }

    return formattedSubject
  }

  const showResultsSuggestions = data => {
    let content = null
    if (!isEmpty(data)) {
      // prevents errors with empty data entity
      const dataArray = Object.keys(data).filter(entity => data[entity].results)
      content = dataArray.map(entity => {
        let subject = getLabelsTranslationsAndLink(entity, data[entity])
        return (
          <div
            key={uniqueId()}
            className={classes.listContainer}
            style={{
              width: bounding
                ? `calc(${bounding.right}px - ${bounding.left}px)`
                : 400,
            }}
          >
            <div className={classes.listTitle}>
              <Typography
                key={uniqueId('title')}
                variant="h6"
                style={{ fontSize: '1em', fontWeight: 600 }}
                gutterBottom
              >
                {subject.title}
              </Typography>
              {!data[entity].total ? (
                <Typography
                  key={uniqueId('none')}
                  variant="subtitle2"
                  color="textSecondary"
                  gutterBottom
                  style={{ fontSize: '1em' }}
                >
                  {subject.none}
                </Typography>
              ) : (
                <Typography
                  key={uniqueId('quantity')}
                  variant="subtitle2"
                  color="textSecondary"
                  gutterBottom
                  style={{ fontSize: '1em', fontWeight: 400 }}
                >
                  {subject.quantity}
                </Typography>
              )}
            </div>
            <List key={uniqueId('values')} className={classes.listRoot}>
              {data[entity].results.map(value => {
                const link = subject.link(value)
                const text = subject.text(value)
                return (
                  <ListItem
                    key={uniqueId(value.slug)}
                    role={undefined}
                    dense
                    className={classes.listItem}
                    component="li"
                    onClick={handleClickOnResult}
                  >
                    <Link className={classes.listItemLink} to={link}>
                      <div className={classes.avatar}>{subject.icon}</div>
                      <p style={{ margin: 0, fontSize: '1em' }}>{text}</p>
                    </Link>
                    {value.collar || value.code ? (
                      <FindMyCowButton
                        style={{ marginRight: 10 }}
                        onClick={() =>
                          handleConfirm(value, value.code ? 'collar' : 'animal')
                        }
                      />
                    ) : null}
                  </ListItem>
                )
              })}
            </List>
          </div>
        )
      })
    }
    return content
  }

  useEffect(() => {
    if (!isLoading) {
      setOpenSuggestions(true)
      const gsi = document.getElementById('global-search-input')
      if (gsi) {
        gsi.focus()
        gsi.click()
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (error) {
      toast.error(error?.message)
    }
  }, [error])

  const shrunkClass = shrunkSearch ? '' : 'shrunk'

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSuggestions)
    return () =>
      document.removeEventListener('mousedown', handleClickOutsideSuggestions)
  }, [])

  return (
    <div className={[classes.wrapper, shrunkClass].join(' ')}>
      <Paper
        ref={elementRef}
        className={[classes.root, shrunkClass].join(' ')}
        elevation={0}
        style={{
          maxWidth: `calc(100vw - ${boundingLeftBox}px - 10px)`,
          minWidth: 36,
        }}
      >
        <LoadingIconButton
          loading={isLoading}
          icon={<SearchIcon fontSize={16} />}
          onClick={() => setShrunkSearch(!shrunkSearch)}
        />
        <InputBase
          id="global-search-input"
          className={classes.input}
          fullWidth
          placeholder={Translate({
            messageKey: ['sm', 'xs'].includes(width)
              ? 'search'
              : 'global_search',
          })}
          autoComplete="off"
          onChange={handleInputChange}
          onClick={handleInputClick}
          disabled={isLoading}
        />
      </Paper>
      {openSuggestions && !isLoading ? (
        <Card
          className={[classes.rootSuggestions, shrunkClass].join(' ')}
          ref={suggestionsTooltip}
        >
          <div>{showResultsSuggestions(data)}</div>
        </Card>
      ) : null}
      {dialogActions && <ActionsDialog dialogActions={dialogActions} />}
    </div>
  )
}

export default GlobalSearch
