import { isEmpty } from 'lodash'

import AnimalPlotlines from './animalEvents'
import BatchEvents from './batchEvents'
import dietPlotlines from './diet'
import milkPlotlines from './milkQuality'

export default function PlotLinesGenerator(
  plotTypes,
  plotData,
  range,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TIMEZONE,
  plotLinesKeys,
  graph_tick,
) {
  const startOf = graph_tick === 'hourly' ? 'hour' : 'day'

  const plots = []
  /* Add Events plotLines */
  if (!isEmpty(plotData) && !isEmpty(plotTypes)) {
    plotTypes.forEach(plotType => {
      switch (plotType) {
        case 'diets':
          // eslint-disable-next-line no-case-declarations
          const type =
            plotLinesKeys && plotLinesKeys[plotType]
              ? plotLinesKeys[plotType]
              : plotType
          plots.push(...dietPlotlines(plotData[plotType], range, type))
          break

        case 'milk_qualities':
          plots.push(...milkPlotlines(plotData[plotType], startOf))
          break

        case 'animal_events':
          plots.push(...AnimalPlotlines(plotData[plotType], startOf))
          break

        case 'events':
          plots.push(...BatchEvents(plotData[plotType], startOf))
          break
        default:
      }
    })
  }
  return plots
}
