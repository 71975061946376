import Colors from '#/styles/Colors'
import Palette from '#/styles/Palette'

const limit_basic_color = Palette.orange.self[500]

export const ChartBuilderColors = {
  series: {
    accumulated: Palette.orange.deep[400],
    departures: Palette.orange.dark[400],
    discards: Palette.red.peach[400],
    discarded_animals: Palette.red.peach[400],
    primiparous: Colors.animal.primiparous.backgroundColor,
    multiparous: Colors.animal.multiparous.backgroundColor,
    adult: Colors.animal.adult.backgroundColor,
    adult_average: Palette.black.self[100],
    weight_gained: Palette.orange.deep[500],
    pev: Colors.animal.primiparous.backgroundColor,
    empty: Colors.animal.primiparous.backgroundColor,
    /* Picos de atividade */
    activity_peak_1: Palette.red.salmon[300],
    activity_peak_2: Palette.red.self[600],

    // Gráfico concepção por del
    heifer: Colors.reproduction.heifer.backgroundColor,
    vwp_traditional: Colors.reproduction.emptyPostDelivery.backgroundColor,
    ideal_period: '#57a86f',
    delayed_reproduction: '#e57373',
    services: Colors.services,

    // Gráficos animal dia
    rumination: '#b9f6ca',
    panting: '#CA3278',
    activity: '#ff8a65',
    leisure: '#5081af',
    total_localized: '#90caf9',

    // Bem-estar geral
    meals: '#59b852',
    thi: '#e57373',

    // Protocols
    conceptions: '#59B834',
    heats: Colors.services,

    /* Rates */
    service_rate: '#6CE5E8',
    conception_rate: '#41B8D5',
    pregnancy_rate: '#2D8ABA',
    expected_rate: Palette.orange.self[500],

    /* Perdas Gestacionais */
    negative_pregnancies: Palette.red.peach[200],
    gestational_loss_rate: Palette.red.peach[400],
    accumulated_percentage: Palette.black.self[100],
    abortions: Palette.red.peach[400],
    total: Palette.black.self[100],
    losses: Palette.red.peach[400],
  },

  plotlines: {
    /* REPRODUÇÃO */
    'reproduction.abortion.any': '#ff5354',
    'reproduction.birth.algorithm': '#ce93d8',
    'reproduction.birth.user': '#ce93d8',
    'reproduction.dry.any': '#739abf',
    'reproduction.embryo_transfer.normal': '#83cc8c',
    'reproduction.embryo_transfer.ftet': '#83cc8c',
    'reproduction.empty.any': '#90aea4',
    'reproduction.gynecologic_exam_apt.any': '#8bc34a',
    'reproduction.gynecologic_exam_inapt.any': '#fbc02d',
    'reproduction.heat.algorithm': '#80deea',
    'reproduction.heifer.user': '#d4479e',
    'reproduction.heifer.algorithm': '#d4479e',
    'reproduction.induced_lactation.any': '#41a781',
    'reproduction.inseminated.normal': '#9ccc65',
    'reproduction.inconclusive_pregnancy.any': '#b47171',
    'reproduction.natural_breeding.normal': '#ffab91',
    'reproduction.negative_pregnancy.any': '#b8abbf',
    'reproduction.pregnant.any': '#ce93d8',
    'reproduction.pregnant_pre_delivery.any': '#ba4383',
    'reproduction.visual_heat.any': '#80deea',
    'reproduction.weaning.user': '#00b8d4',
    'reproduction.apt_after_delivery.any': '#8bc34a',
    'reproduction.inapt_after_delivery.any': '#fbc02d',

    /* PROTOCOLOS */
    'reproduction.implant_insertion.ftai': '#ff8a80',
    'reproduction.implant_removal.ftai': '#ff8a80',
    'reproduction.inseminated.ftai': '#ff7043',

    'reproduction.implant_insertion.ftet': '#ff8a80',
    'reproduction.implant_removal.ftet': '#ff8a80',
    'reproduction.inseminated.ftet': '#ff7043',

    'reproduction.implant_insertion.pg': '#ff8a80',
    'reproduction.implant_removal.pg': '#ff8a80',
    'reproduction.inseminated.pg': '#ff7043',

    'reproduction.protocol.heat_observation_end': '#5F06A4',
    'reproduction.protocol.heat_observation_start': '#5F06A4',
    'reproduction.protocol.sync_start': '#5F06A4',
    'reproduction.protocol.animal_removed': '#5F06A4',
    'reproduction.protocol.implant_removal': '#5F06A4',
    'reproduction.protocol.implant_insertion': '#5F06A4',

    /* SAÚDE */
    'health.condition.normal': Colors.health.normal.color,
    'health.condition.challenge': Colors.health.challenge.color,
    'health.condition.observation': Colors.health.observation.color,
    'health.condition.critical': Colors.health.critical.color,
    'health.condition_confirmation.normal': Colors.health.normal.color,
    'health.condition_confirmation.challenge': Colors.health.challenge.color,
    'health.condition_confirmation.observation':
      Colors.health.observation.color,
    'health.condition_confirmation.critical': Colors.health.critical.color,

    /* MOVIMENTAÇÃO */
    'discard.departure.any': '#ff8a65',
    'discard.death.any': '#ff8a65',
    'arrival.arrival.any': '#9ACBB1',
  },

  limits: {
    objective: limit_basic_color,
    limit_validator_1: limit_basic_color,
    limit: limit_basic_color,
    limit_1: limit_basic_color,
    meals: limit_basic_color,
    limit_2: Palette.red.light[500],
  },

  plotBands: {
    normal: Colors.dtr.normal,
    moderated: Colors.dtr.moderated,
    intense: Colors.dtr.intense,
  },
  export: {
    stroke: Palette.green.self[200],
    fill: Palette.green.self[200],
  },
  feedback: {
    success: Colors.feedback.success.color,
    warning: Colors.feedback.warning.color,
    info: Colors.feedback.info.color,
    danger: Colors.feedback.danger.color,
    neutral: Colors.feedback.neutral.color,
    error: Colors.feedback.error.color,
  },
} as const
