import { WarningIcon } from '@chipinside/frontend'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

import Colors from '#/styles/Old/Colors'
import isTrue from '#/utils/isTrue'

const WarningBadge = ({ value, tooltip }) => {
  if (value === null) return ''
  let iconColor = isTrue(value) ? Colors.collarMinorError : Colors.borderGray

  return (
    <div>
      {tooltip ? (
        <Tooltip title={tooltip} aria-label={tooltip}>
          <span>
            <WarningIcon style={{ color: iconColor }} />
          </span>
        </Tooltip>
      ) : (
        <WarningIcon style={{ color: iconColor }} />
      )}
    </div>
  )
}

export default WarningBadge
