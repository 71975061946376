import { fetcher } from '@chipinside/fetcher'
import {
  BooleanInput,
  DateRange,
  InputSelectSync,
  LoadingIconButton,
  RefreshIcon,
  SeedIcon,
} from '@chipinside/frontend'
import { ResponseBooleanFilter } from '@chipinside/frontend/dist/Inputs/Boolean/types'
import { ResponseDateRangeFilter } from '@chipinside/frontend/dist/Inputs/DateRange/types'
import {
  InputSelectOption,
  InputSelectValue,
  ResponseSelectFilter,
} from '@chipinside/frontend/dist/Inputs/Select/types'
import {
  ElementBlock,
  PageBuilderElementBase,
} from '@chipinside/pagebuilder/dist/types'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isEmpty, omit, uniqueId } from 'lodash'
import { useState } from 'react'

import { isLocal } from '#/utils/processEnvironment'

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
})

const DevTools = ({ request }) => {
  const [isLoading, setIsLoading] = useState(false)

  if (!isLocal) return null

  const { controller, params, mutate } = request || {}

  return (
    <>
      <LoadingIconButton
        title="Refresh"
        size="small"
        loading={isLoading}
        onClick={() => {
          setIsLoading(true)
          mutate().then(() => setIsLoading(false))
        }}
        icon={<RefreshIcon fontSize={32} />}
      />
      <LoadingIconButton
        size="small"
        title="Seed"
        loading={isLoading}
        onClick={() => {
          setIsLoading(true)
          mutate(fetcher({ controller, params: { ...params, seed_data: 1 } }), {
            revalidate: false,
          }).then(() => setIsLoading(false))
        }}
        icon={<SeedIcon fontSize={32} />}
      />
    </>
  )
}

export type PageBuilderFiltersProps = Pick<ElementBlock, 'filters'> &
  PageBuilderElementBase

export function PageBuilderFilters({
  filters,
  request,
  onChangeFilters,
  filterProps = {},
}: PageBuilderFiltersProps) {
  if (isEmpty(filters)) return null

  const { children } = filterProps

  const formattedFilters = {
    select: filters.filter(({ type }) => type === 'select'),
    boolean: filters.filter(({ type }) => type === 'boolean'),
    dateRange: filters.filter(({ type }) => type === 'date-range'),
  }

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '8px 16px',
        flexWrap: 'wrap',
        justifyContent: { xs: 'center', md: 'space-between' },
      }}
    >
      <StyledBox>
        {formattedFilters.select.map(item => {
          const filter = item as ResponseSelectFilter

          return (
            <InputSelectSync
              {...filter}
              key={filter.name}
              multi={filter.isMulti}
              label={filter.title}
              limitTags={2}
              value={filter.current}
              onChange={(options, name) => {
                if (isEmpty(options)) {
                  return onChangeFilters(prevState => omit(prevState, name))
                }

                if (filter.isMulti) {
                  const value = (options as InputSelectOption[])?.map(
                    ({ value }) => value as InputSelectValue,
                  )
                  return onChangeFilters(prevState => ({
                    ...prevState,
                    [name]: value,
                  }))
                }

                return onChangeFilters(prevState => {
                  return {
                    ...prevState,
                    [name]: (options as InputSelectOption).value,
                  }
                })
              }}
            />
          )
        })}
        {formattedFilters.boolean.map(item => {
          const filter = item as ResponseBooleanFilter

          return (
            <BooleanInput
              {...filter}
              key={uniqueId(filter.name)}
              label={filter.title}
              value={filter.current}
              onChange={(value, name) => {
                onChangeFilters?.(prevState => ({
                  ...prevState,
                  [name]: value,
                }))
              }}
            />
          )
        })}
        {children}
      </StyledBox>
      <StyledBox>
        <DevTools request={request} />
        {formattedFilters.dateRange.map(item => {
          const filter = item as ResponseDateRangeFilter

          return (
            <DateRange
              {...filter}
              key={filter.name}
              defaultValues={filter.current}
              title={filter.title}
              onChange={value => {
                onChangeFilters(prevState => ({
                  ...prevState,
                  [filter.name]: value,
                }))
              }}
            />
          )
        })}
      </StyledBox>
    </Box>
  )
}
