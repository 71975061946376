import { createFetcherInstance } from '@chipinside/fetcher'
import { Translate } from '@chipinside/frontend'
import CircularProgress from '@mui/material/CircularProgress'
import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Toaster } from 'sonner'

import Consent from '#/components/Consent'
import EnvTag from '#/components/EnvTag'
import ErrorBoundary from '#/components/ErrorBoundary'
import Flex from '#/components/Flex'
import PageLoader from '#/components/PageLoader'
import en from '#/lang/en.json'
import es from '#/lang/es.json'
import pt from '#/lang/pt.json'
import { Providers } from '#/store/providers/Providers'
import { axiosInstance } from '#/store/services/AxiosInstance'
import initializeFirebase from '#/store/services/Firebase'
import { isDev } from '#/utils'
import initializeSentry from '#/utils/initSentry'
import tryCache from '#/utils/tryCache'

import Routes from './routes'

/* Initialize translate content */
Translate.setContent({ en, pt, es })

/* Initialize fetcher instance */
createFetcherInstance({
  instance: axiosInstance,
})

/* A function that removes expired items from the browser's local storage. */
tryCache.clearExpired()

/* Initializing the Firebase SDK. */
initializeFirebase()

/* Initializing the Sentry SDK. */
initializeSentry()

export default function App() {
  return (
    <Providers>
      <ErrorBoundary>
        <PageLoader />
        <Router>
          <Suspense
            fallback={
              <Flex>
                <CircularProgress />
              </Flex>
            }
          >
            <Routes />
          </Suspense>
        </Router>
        {isDev && <EnvTag />}
        <Consent />
        <Toaster richColors position="bottom-center" />
      </ErrorBoundary>
    </Providers>
  )
}
