import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

const Wrapper = styled('div')({
  marginTop: 15,
})

export const PortalTop = ({ children }) => {
  const [target, setTarget] = useState(() =>
    document.getElementById('app-content-top-middle'),
  )

  useEffect(() => {
    // Função para verificar se o target existe
    const checkTarget = () => document.getElementById('app-content-top-middle')

    // Se o target já existe, apenas atualiza o estado
    if (target) return

    // Cria um MutationObserver para detectar alterações no DOM
    const observer = new MutationObserver(() => {
      const foundTarget = checkTarget()
      if (foundTarget) {
        setTarget(foundTarget)
        observer.disconnect() // Para o observer, pois já achamos o target
      }
    })

    // Observa mudanças no body (ou outro container relevante)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [target]) // Se o target for perdido, o efeito roda novamente

  if (!target) return null

  return createPortal(<Wrapper>{children}</Wrapper>, target)
}
