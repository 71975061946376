import MenuIcon from '@mui/icons-material/Menu'
import { Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { isEqual } from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import GlobalSearch from '#/components/GlobalSearch'
import HelpPages from '#/components/HelpPages'
import MegaMenu from '#/components/MegaMenu'
import Notifications from '#/components/Notification'
import ReleaseStatus from '#/components/ReleaseNotes'
import FarmStatus from '#/components/reportStatus/FarmStatus'
import UserMenu from '#/components/UserMenu'
import { getCurrentFarm } from '#/store/ducks/farm'
import { usePermissions } from '#/store/hooks/permissions'
import useAppContext from '#/store/hooks/useAppContext'
import useWidth from '#/store/hooks/useWidth'

const drawerWidth = 240

const Wrapper = styled(AppBar)(({ theme }) => ({
  zIndex: 5,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const Bar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    height: '64px',
  },
  [theme.breakpoints.up('xs')]: {
    paddingLeft: 0,
    minHeight: '48px',
  },
}))

const FarmWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: 10,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const MobileMenu = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

export default function Header({ setMobileOpen, setDesktopOpen }) {
  const width = useWidth()
  const context = useAppContext()
  const {
    slug: currentFarmSlug,
    code: currentFarmCode,
    name: currentFarmName,
  } = useSelector(getCurrentFarm, isEqual)

  const handleDrawerToggle = () => {
    if (['md', 'sm', 'xs'].includes(width)) {
      setMobileOpen(prevState => !prevState)
    } else if (['lg', 'xl'].includes(width)) {
      setDesktopOpen(prevState => !prevState)
    }
  }

  const { permissions } = usePermissions({
    system: ['list_company_job'],
    farm: ['view_farm_connectivity'],
    company: ['list_company_job'],
  })

  const FarmInfo = () => {
    if (currentFarmCode) {
      return (
        <FarmWrapper>
          {width === 'xs'
            ? currentFarmCode
            : [currentFarmCode, currentFarmName].join(' - ')}
        </FarmWrapper>
      )
    }
  }

  const FarmMenuRightItems = useMemo(() => {
    const items = []
    switch (context) {
      case 'farm':
        items.push(
          <React.Fragment key="farm-right-menu-itens">
            {/*FARM NAME*/}
            <FarmInfo />
            {/*HELP*/}
            <HelpPages />
            {/*FARM STATUS*/}
            {permissions.farm.viewFarmConnectivity && (
              <FarmStatus slug={currentFarmSlug} farm={currentFarmName} />
            )}
            {/* NOTIFICATIONS */}
            <Notifications context="farm" key="farm-jobs" />
            {/* RELEASE NOTES MODAL */}
            <ReleaseStatus />
          </React.Fragment>,
        )
        break

      case 'company':
        if (permissions.company.listCompanyJob) {
          items.push(<Notifications context="company" key="company-jobs" />)
        }
        break

      default:
        if (permissions.system.listCompanyJob) {
          items.push(<Notifications context="system" key="system-jobs" />)
        }
        break
    }

    return items
  }, [permissions, context, width, currentFarmSlug])

  const FarmMenuLeftItems = useMemo(() => {
    if (context === 'farm') {
      return (
        <React.Fragment key="farm-left-menu-itens">
          {/* MEGA MENU */}
          <MegaMenu />
          {/*GLOBAL SEARCH*/}
          <GlobalSearch />
        </React.Fragment>
      )
    }
  }, [context])

  return (
    <Wrapper position="sticky">
      <Bar>
        <Stack direction="row" spacing={1}>
          {/* MOBILE MENU */}
          <MobileMenu color="inherit" onClick={handleDrawerToggle} size="large">
            <MenuIcon />
          </MobileMenu>
          {FarmMenuLeftItems}
        </Stack>
        <Stack direction="row" spacing={1}>
          {FarmMenuRightItems}
          {/* USER MENU */}
          <UserMenu />
        </Stack>
      </Bar>
    </Wrapper>
  )
}
