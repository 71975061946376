import { Translate } from '@chipinside/frontend'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { enUS, esES, ptBR } from '@mui/x-date-pickers/locales'
import { isEqual } from 'lodash'
import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

import { getUserInfo } from '#/store/ducks/auth'


// Função para obter o texto de localização apropriado com base no idioma
function getLocaleText(language: string | undefined) {
  switch (language) {
    case 'en':
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText

    case 'es':
      return esES.components.MuiLocalizationProvider.defaultProps.localeText

    default:
      return {
        ...ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
        // Adiciona tradução que falta no MUI
        fieldClearLabel: Translate({ messageKey: 'clear' }),
      }
  }
}

export function LocalizationProviderConfig({ children }: PropsWithChildren) {
  // Obtém o idioma do localStorage, ou usa 'pt' como padrão
  const user = useSelector(getUserInfo, isEqual)

  const language = Translate.getShortLanguage(user?.locale ?? 'pt_BR')

  // Define o locale para o dayjs com base no idioma
  const locale = language === 'pt' ? 'pt-br' : language

  // Obtém o texto de localização apropriado, utilizado no datepicker
  const localeText = getLocaleText(language)

  return (
    // Configura o LocalizationProvider com o adaptador Dayjs e o texto de localização apropriado
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={locale}
      localeText={localeText}
    >
      {children}
    </LocalizationProvider>
  )
}
