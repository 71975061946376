import { CssBaseline } from '@mui/material'
import {
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles'
import React from 'react'

import defaultTheme from '#/styles/Themes'

import '#/index.css'

type Props = {
  children: React.ReactNode
}

export function ThemeProvider({ children }: Props) {
  const theme = React.useMemo(() => defaultTheme(), [])

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
