import { fetcher } from '@chipinside/fetcher'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { endpoint } from '#/store/services/endpoints'

const initialState = {
  newVersionAvailable: false,
  version: {
    api: null,
    error: '',
  },
}

export const loadApiVersion = createAsyncThunk(
  'app/loadApiVersion',
  async (_, thunkAPI) => {
    try {
      const { data } = await fetcher({
        controller: endpoint.auth.deploy_version(),
      })

      return { ...data }
    } catch (err) {
      return thunkAPI.rejectWithValue({ message: err.message })
    }
  },
)

// app reducer
const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(loadApiVersion.fulfilled, (state, action) => {
        const { major, minor, patch, prerelease } = action.payload ?? {}
        state.version.api = `${major}.${minor}.${patch}${
          prerelease && `-${prerelease}`
        }`
      })
      .addCase(loadApiVersion.rejected, (state, action) => {
        state.version.error = action.payload?.message
      })
  },
})

export const getCurrentAppVersion = state => state?.app?.version?.app
export const getCurrentApiVersion = state =>
  state?.app?.version?.api ?? state?.app?.version?.error

export default appSlice.reducer
