import { Translate } from '@chipinside/frontend'
import { Divider } from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { endpoint } from '#/store/services/endpoints'
import { subscribe } from '#/utils/pubsub'
import tryCache from '#/utils/tryCache'

import FormatAlert from '../Manipulate/FormatAlert'

export default function AnimalSystemTab(props) {
  const history = useHistory()
  const {
    farm,
    headerData,
    setIsOpen,
    type,
    setShowSnack,
    setNewMessage,
    timestamp,
  } = props

  useEffect(() => {
    const firebase = subscribe('firebase-alert-arrived', props => {
      const { alertable_slug, message } = props
      if (alertable_slug === farm) {
        //Limpa cache do cabeçalho
        const types = ['header', 'animal', 'system']

        types.map(type => {
          tryCache.clear(`notification-list-${farm}-${type}`)
        })
        //Refaz a request e atualiza os valores no cabeçalho
        headerData()

        if (message) {
          setNewMessage(message)
          setShowSnack(true)
        }
      }
    })
    return () => {
      firebase.unsubscribe()
    }
  }, [farm])

  const getEndpoint = type => {
    const params = '?filters[0][name]=alert_type&filters[0][value]='
    return `${endpoint.notifications.alerts({ farm })}${params}${type}`
  }

  const GetType = type => ({
    key: type,
    endpoint: getEndpoint(type),
    timestamp,
    content: (
      <>
        <Divider />
        <FormatAlert {...props} />
      </>
    ),
    footer: [
      {
        text: Translate({ messageKey: 'view_all_notifications' }),
        onClick: () => {
          setIsOpen(false)
          history.push(`/farm/${farm}/modules/notifications`)
        },
      },
    ],
  })

  return GetType(type)
}
