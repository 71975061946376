import { Translate } from '@chipinside/frontend'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React, { useEffect, useMemo, useState } from 'react'

import logo from '#/assets/logo.png'

const drawerWidth = 240

const Img = styled('img')({
  width: 'auto',
  height: 22,
})

const ImgWrapper = styled('div')({
  backgroundColor: '#fff',
  borderRadius: 8,
  display: 'flex',
  height: 40,
  width: 40,
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledAlert = styled(Alert)(({ theme }) => ({
  'backgroundColor': '#3b3b3b',
  'color': '#fff',
  'flexDirection': 'row !important',
  'borderRadius': 0,
  'height': '64px',
  [theme.breakpoints.down('sm')]: {
    height: '48px',
  },
  '& > .MuiAlert-message ': {
    padding: 0,
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const MessageWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
})

export default function AppDownloadBanner() {
  const [open, setOpen] = useState(() => {
    const savedState = localStorage.getItem('AppDownloadBannerOpen')
    return savedState !== null ? JSON.parse(savedState) : true
  })

  useEffect(() => {
    localStorage.setItem('AppDownloadBannerOpen', JSON.stringify(open))
  }, [open])

  const link = useMemo(() => {
    if (/Android/i.test(navigator.userAgent)) {
      return 'https://play.google.com/store/apps/details?id=com.chipinside.cowmed'
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return 'https://apps.apple.com/br/app/cowmed/id1393126598'
    }
  }, [])

  return (
    link &&
    open && (
      <StyledAlert
        icon={
          <IconButton
            aria-label="close"
            color="white"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        }
        action={
          <Button
            href={link}
            variant="contained"
            color="primary"
            size="small"
            aria-label="download-app"
          >
            {Translate({ messageKey: 'download' })}
          </Button>
        }
      >
        <MessageWrapper>
          <ImgWrapper>
            <Img src={logo} />
          </ImgWrapper>
          <Box display="flex" flexDirection="column">
            <strong>{Translate({ messageKey: 'software_name' })}</strong>
            <small>{Translate({ messageKey: 'download_app' })}</small>
          </Box>
        </MessageWrapper>
      </StyledAlert>
    )
  )
}
