import { InputSelectSync, Translate } from '@chipinside/frontend'
import FormControl from '@mui/material/FormControl'
import { isObject } from 'lodash'
import React from 'react'

const SelectFilters = props => {
  const {
    onChangeFilter = () => {},
    containerClass = '',
    containerStyle = {},
    disabled,
  } = props

  return props?.filters?.map((filter, index) => {
    const placeholder =
      filter.placeHolder ??
      Translate({
        messageKey: 'select_one_item_female',
        params: { item: Translate({ messageKey: 'option', lower: true }) },
      })

    return (
      <FormControl
        key={index}
        className={containerClass}
        style={{ minWidth: 200, marginRight: 10, ...containerStyle }}
      >
        <InputSelectSync
          multi={filter.isMulti}
          max={filter.max}
          name={filter.name}
          value={filter.current}
          options={filter.options}
          label={filter.title}
          placeholder={placeholder}
          onChange={value => {
            if (!value) {
              return onChangeFilter(filter.name, value)
            }

            if (filter.isMulti) {
              return onChangeFilter(
                filter.name,
                value.map(val => val.value),
              )
            }

            if (isObject(value)) {
              return onChangeFilter(filter.name, value.value)
            }

            return onChangeFilter(filter.name, value)
          }}
          disabled={disabled}
          limitTags={filter.limitTags}
        />
      </FormControl>
    )
  })
}

export default SelectFilters
