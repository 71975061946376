import { isEqual } from 'lodash'
import React from 'react'

import CollarBadge from '#/components/badges/CollarBadge'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { getLink } from '#/utils/getLink'

const TypeCTech = ({ value, ...rest }) => {
  if (!value) return <span>-</span>
  let link = rest.href
  let currentFarmSlug = null

  switch (rest.linkTo) {
    case 'c-tech-map':
      currentFarmSlug =
        getCurrentFarmSlug(window.globalStore.getState(), isEqual) ??
        rest.farmSlug
      link = getLink({ type: 'c-tech-map', farm: currentFarmSlug, id: value })
      break

    case 'c-tech-inventory':
      link = getLink({ type: 'c-tech-status', id: value })
      break

    default: //nothing to moo my friend
  }

  return (
    <CollarBadge value={value} link={link} status={rest.status} {...rest} />
  )
}
export default TypeCTech
