import isNil from 'lodash/isNil'
import React from 'react'

import { minutesToHours } from '#/utils'

const TypeMinutesToHours = ({ value, ...rest }) => {
  if (isNil(value)) return <span>-</span>

  return (
    <div className={rest.classes?.overMe}>{`${value}/${minutesToHours(
      value,
    )}`}</div>
  )
}
export default TypeMinutesToHours
