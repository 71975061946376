import { useFetch } from '@chipinside/fetcher'
import { Translate } from '@chipinside/frontend'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isEqual, toLower } from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Loader from '#/components/Loader'
import { getCurrentFarmName } from '#/store/ducks/farm'

const ModalHeader = styled(Box)({
  position: 'absolute',
  right: '10px',
  top: '10px',
})

const ModalContent = styled(Paper)({
  display: 'flex',
  textAlign: 'center',
  gap: '20px',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  padding: '50px 32px',
})

// Função para gerar o nome de arquivo para download
export function normalizeFileName(filename, currentFarmName) {
  // Constrói o nome base para o arquivo de download
  let baseName = `cowmed_${currentFarmName ?? ''}_${filename ?? ''}`

  // Converte para minúsculas e substitui caracteres indesejados
  const normalizeName = toLower(baseName)
    .replace(/[-_]/g, '_') // Substitui - e _ por _
    .replace(/__+/g, '_') // Remove múltiplos _ consecutivos
    .replace(/\s+/g, '_') // Remove espaços em branco
    .replace(/\./g, '') // Remove pontos

  return normalizeName
}

const ExportFile = props => {
  const {
    exportType,
    modalOpen,
    onCloseModal = () => {},
    controller,
    filename,
    params = {},
  } = props
  const currentFarmName = useSelector(getCurrentFarmName, isEqual)

  const { data, isLoading } = useFetch({
    controller,
    params: { ...params, format: exportType },
    axiosOptions: { responseType: 'blob' },
  })

  useEffect(() => {
    // Quando a requisição termina e a modal está aberta
    if (!isLoading && modalOpen && data) {
      // Cria um URL para o blob de dados obtidos
      const url = window.URL.createObjectURL(data)
      // Cria um elemento de link
      const link = document.createElement('a')
      // Define o URL para o link
      link.href = url
      // Define o nome de arquivo para download
      link.download = normalizeFileName(filename, currentFarmName)
      // Adiciona o link ao corpo do documento
      document.body.appendChild(link)
      // Simula um clique no link para iniciar o download
      link.click()
      // Remove o link após o download ser iniciado com sucesso
      link.parentNode.removeChild(link)
      onCloseModal()
    }
  }, [isLoading, data])

  return (
    <Modal open={modalOpen} onClose={onCloseModal}>
      <ModalContent style={{ borderRadius: 16 }}>
        <ModalHeader>
          <IconButton aria-label="Close" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <div>{Translate({ messageKey: 'exporting' })}</div>
        <Loader status="started" />
      </ModalContent>
    </Modal>
  )
}

export default ExportFile
