import { Translate, WarningIcon } from '@chipinside/frontend'
import React from 'react'

import Colors from '#/styles/Old/Colors'

export const ExpiredTimeline = ({ diff, styles }) => {
  const messageKey = diff > 1 ? 'recommended_hour_plural' : 'recommended_hour'

  return (
    <div style={styles.expired_container}>
      <WarningIcon fontSize={styles.expired_icon} color={Colors.warning} />
      <small style={styles.expired_text}>
        {Translate({ messageKey, params: { item: diff } })}
      </small>
    </div>
  )
}
