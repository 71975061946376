import { Translate } from '@chipinside/frontend'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'

import { mapStyle } from '#/components/FormControl/Inputs/PinMap/map'
import {
  getCcomMarkerIcon,
  getCowComfortMarkerChipIcon,
  getCowComfortMarkerIcon,
  getFarmMarkerIcon,
  getPointWithCowComfortOutdatedTwentyFourHoursIcon,
  getPointWithoutCowComfortIcon,
} from '#/components/Map/MarkerIcons'
import Colors from '#/styles/Old/Colors'
import InterpolateColors from '#/utils/interpolateColors'

import MapWrapper from './Wrapper'

const withoutInformationString = Translate({
  messageKey: 'without_information',
})

function MapContainer(props) {
  const {
    latitude,
    longitude,
    zoom = 3,
    mapTypeControl = false,
    ccomMarkers,
    ccomMarkersClustererEnabled,
    farmMarkers,
    farmMarkersVisibleZoom = 0,
    cenvMarkers,
    findMyCow,
    style,
  } = props

  // set necessary ref for map initialization
  const ref = useRef()
  const [map, setMap] = useState()

  // popup box to show markers info on click
  const [infoWindow] = useState(
    new window.google.maps.InfoWindow({ content: '' }),
  )
  const [listAllPositions, setListAllPositions] = useState([])

  // set default map options
  const options = useMemo(
    () => ({
      center: new window.google.maps.LatLng(latitude, longitude),
      zoom,
      tilt: 0,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      mapTypeControl: false,
      streetViewControl: false,
      keyboardShortcuts: false,
      rotateControl: false,
      styles: mapStyle,
    }),
    [latitude, longitude, mapTypeControl, zoom],
  )

  // creates map instance
  useEffect(() => {
    if (ref.current && !map && options) {
      setMap(new window.google.maps.Map(ref.current, options))
    }
  }, [ref, map, options])

  // Preconfigure all Find My Cow circles and memoize it
  const findMyCowCircleList = useMemo(() => {
    // If findMyCow is not available, return null
    if (!findMyCow) return null

    // Destructure necessary properties from findMyCow object
    const { last_point, positions, c_com_range } = findMyCow

    // Find minValue and maxValue using positions array
    const { minValue, maxValue } = positions.reduce(
      (result, obj) => {
        // Find minValue
        if (obj.count < result.minValue.count) {
          result.minValue = obj
        }

        // Find maxValue
        if (obj.count > result.maxValue.count) {
          result.maxValue = obj
        }

        return result
      },
      { minValue: positions[0], maxValue: positions[0] },
    )

    // Create an array of circles based on positions
    const circles = positions.map(({ lat, lng, count }) => {
      return new window.google.maps.Circle({
        strokeWeight: 0,
        fillColor: InterpolateColors({
          // Calculate the interpolated color based on the count value
          value: count - minValue.count,
          maxValue: maxValue.count - minValue.count,
        }),
        fillOpacity: 0.6,
        map,
        center: { lat, lng },
        radius: c_com_range ?? 50,
        clickable: false,
      })
    })

    // Create a marker for the last position
    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(last_point),
      clickable: false,
    })

    // Return an array containing circles and the marker
    return [...circles, marker]
  }, [findMyCow])

  // pre setup all farm markers and memo it
  const farmMarkersList = useMemo(
    () =>
      farmMarkers
        ? farmMarkers.map(m => {
            setListAllPositions(prevState => [
              ...prevState,
              {
                latitude: m.latitude,
                longitude: m.longitude,
              },
            ])

            return new window.google.maps.Marker({
              renderContent: m.renderContent,
              icon: getFarmMarkerIcon(window.google),
              position: new window.google.maps.LatLng(m.latitude, m.longitude),
            })
          })
        : null,
    [farmMarkers],
  )

  // pre setup all ccom markers and memo it
  const ccomMarkersList = useMemo(
    () =>
      ccomMarkers
        ? ccomMarkers.map(m => {
            setListAllPositions(prevState => [
              ...prevState,
              {
                latitude: m.latitude,
                longitude: m.longitude,
              },
            ])

            return new window.google.maps.Marker({
              renderContent: m.renderContent,
              icon: getCcomMarkerIcon(m.ccomParams, window.google),
              ccomMapStatus: m.ccomParams.status_for_map,
              position: new window.google.maps.LatLng(m.latitude, m.longitude),
            })
          })
        : null,
    [ccomMarkers],
  )

  // pre setup all cenv markers and memo it
  const cenvMarkersList = useMemo(() => {
    if (!cenvMarkers) return null

    return cenvMarkers.map((m, index) => {
      const options = {
        outdated: getPointWithCowComfortOutdatedTwentyFourHoursIcon(
          window.google,
        ),
        empty: getPointWithoutCowComfortIcon(window.google),
        normal: getCowComfortMarkerIcon(window.google, m),
        chip: getCowComfortMarkerChipIcon(window.google, m),
      }

      setListAllPositions(prevState => [
        ...prevState,
        {
          latitude: m.latitude,
          longitude: m.longitude,
        },
      ])

      return new window.google.maps.Marker({
        zIndex: index,
        renderContent: m.renderContent,
        position: new window.google.maps.LatLng(m.latitude, m.longitude),
        ...options[m.type],
      })
    })
  }, [cenvMarkers])

  useEffect(() => {
    if (!isEmpty(listAllPositions) && map) {
      const bounds = new window.google.maps.LatLngBounds()
      listAllPositions.map(m =>
        bounds.extend({
          lat: Number(m.latitude),
          lng: Number(m.longitude),
        }),
      )
      map.fitBounds(bounds)
    }
  }, [listAllPositions, map])

  // setup cenv markers
  useEffect(() => {
    if (map && infoWindow && cenvMarkersList?.length) {
      cenvMarkersList.forEach(marker => {
        marker.setMap(map)
        // Adiciona um listener de clique para exibir a janela de informações quando o marcador é clicado
        marker.addListener('click', () => {
          // Define o conteúdo da janela de informações como o HTML renderizado do conteúdo do marcador, ou uma string padrão se não estiver disponível
          infoWindow.setContent(
            renderToString(marker.renderContent) ?? withoutInformationString,
          )
          // Abre a janela de informações no mapa, associada ao marcador clicado
          infoWindow.open(map, marker)
        })

        // Salva o valor atual do zIndex do marcador
        const zIndex = marker.get('zIndex')

        // Adiciona um listener de mouseover para aumentar temporariamente o zIndex quando o mouse está sobre o marcador
        marker.addListener('mouseover', () => {
          marker.setOptions({ zIndex: 1000 })
        })

        // Adiciona um listener de mouseout para restaurar o zIndex ao valor original quando o mouse sai do marcador
        marker.addListener('mouseout', () => {
          marker.setOptions({ zIndex })
        })
      })
      return () => cenvMarkersList.forEach(marker => marker.setMap(null))
    }
  }, [cenvMarkersList, map, infoWindow])

  // adds map close info window event
  useEffect(() => {
    if (map && infoWindow) {
      map.addListener('click', () => infoWindow.close())
      map.addListener('zoom_changed', () => infoWindow.close())
    }
  }, [map, infoWindow])

  // setup farm markers
  useEffect(() => {
    if (map && infoWindow && farmMarkersList?.length) {
      // checks if it is necessary to set a zoom event
      if (farmMarkersVisibleZoom) {
        const updateMarkersVisiblility = () => {
          if (map.getZoom() >= farmMarkersVisibleZoom) {
            farmMarkersList.forEach(marker => marker.setVisible(true))
          } else {
            farmMarkersList.forEach(marker => marker.setVisible(false))
          }
        }
        updateMarkersVisiblility()
        map.addListener('zoom_changed', updateMarkersVisiblility)
      }
      farmMarkersList.forEach(marker => {
        marker.setMap(map)
        marker.addListener('click', () => {
          infoWindow.setContent(
            marker.renderContent
              ? marker.renderContent(infoWindow)
              : withoutInformationString,
          )
          infoWindow.open(map, marker)
        })
      })

      return () => farmMarkersList.forEach(marker => marker.setMap(null))
    }
  }, [farmMarkersList, map, infoWindow, farmMarkersVisibleZoom])

  // setup ccom markers
  useEffect(() => {
    if (map && infoWindow && ccomMarkersList?.length) {
      ccomMarkersList.forEach(marker => {
        // checks if marker goes to map or clusterer
        if (!ccomMarkersClustererEnabled) {
          marker.setMap(map)
        }
        marker.addListener('click', () => {
          infoWindow.setContent(
            marker.renderContent
              ? marker.renderContent(infoWindow)
              : withoutInformationString,
          )
          infoWindow.open(map, marker)
        })
      })

      if (ccomMarkersClustererEnabled) {
        const clusterer = new MarkerClusterer({
          markers: ccomMarkersList,
          map,
          renderer: {
            render: ({ count, position, markers }) => {
              // change color if this cluster has offline ccoms
              const color = markers.some(m => m.ccomMapStatus === 'offline')
                ? '#ec7676'
                : '#7cbc8f'

              // create svg url with fill color
              const svg = window.btoa(`
                <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                  <circle cx="120" cy="120" opacity=".9" r="70" />
                  <circle cx="120" cy="120" opacity=".6" r="90" />
                  <circle cx="120" cy="120" opacity=".3" r="110" />
                  <circle cx="120" cy="120" opacity=".1" r="130" />
                </svg>
              `)

              // create marker using svg icon
              return new window.google.maps.Marker({
                position,
                icon: {
                  url: `data:image/svg+xml;base64,${svg}`,
                  scaledSize: new window.google.maps.Size(45, 45),
                },
                label: {
                  text: String(count),
                  color: 'rgba(255,255,255,0.9)',
                  fontSize: '12px',
                },
                // adjust zIndex to be above other markers
                zIndex: 1000 + count,
              })
            },
          },
        })
        // close info-window to prevent glitch on cluster zoom
        window.google.maps.event.addListener(clusterer, 'click', () =>
          infoWindow.close(),
        )
        // clear markers if effect runs again
        return () => clusterer.clearMarkers()
      }

      return () => ccomMarkersList.forEach(marker => marker.setMap(null))
    }
  }, [ccomMarkersList, map, infoWindow, ccomMarkersClustererEnabled])

  // setup findmycow circles
  useEffect(() => {
    if (map && findMyCowCircleList?.length) {
      findMyCowCircleList.forEach(circle => {
        circle.setMap(map)
      })

      return () => findMyCowCircleList.forEach(circle => circle.setMap(null))
    }
  }, [findMyCowCircleList, map])

  return (
    <div style={{ height: '100%', width: '100%', ...style }}>
      {latitude && longitude ? (
        <div ref={ref} style={{ height: '100%', width: '100%' }} />
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: Colors.lighterGray,
            borderRadius: 2,
            color: Colors.lightgrey,
          }}
        >
          <NotListedLocationIcon fontSize="large" />
          <Typography variant="h4">
            {Translate({ messageKey: 'no_location_registered' })}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default function Map(props) {
  return (
    <MapWrapper>
      <MapContainer {...props} />
    </MapWrapper>
  )
}
