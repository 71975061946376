import { MegaMenuIcon, SearchIcon, Translate } from '@chipinside/frontend'
import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import { Box, Button, IconButton, InputBase } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import useToggle from '#/store/hooks/toggle'
import Fonts from '#/styles/Fonts'

import menuB2C from './menuB2C'

const StyledInputSearch = styled(InputBase)({
  input: {
    color: '#fff',
  },
})

const StyledButton = styled(Button)(({ theme }) => ({
  'maxHeight': 32,
  'margin': 'auto 0 auto 8px !important',
  'color': '#808080',
  'borderColor': '#808080',
  'lineHeight': 'normal',
  'textTransform': 'capitalize',
  'fontSize': 14,
  'fontWeight': 'normal',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  [theme.breakpoints.down('sm')]: {
    'minWidth': 'unset',
    'padding': 6,
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .button-text': {
      display: 'none',
    },
  },
}))

const SearchWrapper = styled(Box)({
  border: '1px solid #f8f8f8',
  borderRadius: 50,
  display: 'flex',
  alignItems: 'center',
  minWidth: 300,
  maxWidth: '100%',
})

const useStyles = makeStyles(theme => ({
  mmCloseIcon: {
    'color': '#bababa',
    'position': 'absolute',
    'right': 10,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  mmContainer: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: '#dfdfdf',
    backgroundColor: 'rgba(59, 59, 59, 0.95)',
    transition: 'opacity 200ms',
    zIndex: 3,
    margin: '0 !important',
  },
  mmHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  mmTopBar: {
    width: '100%',
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3b3b3b',
  },
  mmSearchIcon: {
    paddingLeft: 12,
    paddingRight: 12,
    color: '#bababa',
  },
  mmSearchInput: {
    'padding': theme.spacing(1),
    'paddingBottom': theme.spacing(0.75),
    'border': 0,
    'borderRadius': theme.spacing(0.5),
    'backgroundColor': 'transparent',
    'color': '#ccc',
    'fontSize': Fonts.fontSize.M,
    'outline': 'none',
    'transition': 'all 200ms',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  mmGridContainer: {
    maxHeight: 'calc(100% - 50px)',
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  mmGrid: {
    height: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    fontSize: Fonts.fontSize.M,
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'auto auto',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  mmGridCell: {
    padding: theme.spacing(2),
  },
  mmCategoryTitle: {
    position: 'relative',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    fontWeight: Fonts.fontWeight.bold,
  },
  mmCategoryIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
  },
  mmItemsList: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  mmItem: {
    'padding': theme.spacing(0.5),
    'paddingLeft': theme.spacing(1),
    'paddingRight': theme.spacing(1),
    'border': '1px solid transparent',
    'borderRadius': theme.spacing(0.5),
    'boxSizing': 'border-box',
    'cursor': 'pointer',
    'textAlign': 'left',
    'color': '#bababa',
    'backgroundColor': 'transparent',
    'fontSize': Fonts.fontSize.M,
    'outline': 'none',
    '&:hover,&:focus': {
      borderColor: '#888',
      color: '#eee',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}))

const MegaMenu = () => {
  const classes = useStyles()
  const history = useHistory()
  const searchInput = useRef(null)
  const [isOpen, toggleIsOpen] = useToggle(false)
  const [searchTherm, setSearchTherm] = useState('')

  //sets menu context items
  const menuItems = menuB2C()

  const handleChange = useCallback(event => {
    setSearchTherm(event.target.value)
  }, [])

  const handleToggle = useCallback(
    value => {
      const isValueNotBool = typeof value !== 'boolean'
      if (value === true || (isValueNotBool && isOpen === false)) {
        searchInput.current?.focus()
      } else if (value === false || (isValueNotBool && isOpen === true)) {
        searchInput.current?.blur()
        setSearchTherm('')
      }
      toggleIsOpen(value)
    },
    [toggleIsOpen, isOpen],
  )

  const setKeyEventListener = useCallback(() => {
    //SET_TOGGLE_IS_OPEN
    document.onkeyup = event => {
      if (event.altKey && event.code === 'KeyS') {
        handleToggle()
      } else if (event.code === 'Escape') {
        handleToggle(false)
      }
    }
  }, [handleToggle])

  // set keybind on mount
  useEffect(() => {
    setKeyEventListener()
  }, [setKeyEventListener])

  // unset keybind on unmount
  useEffect(() => {
    return () => {
      //UNSET_TOGGLE_IS_OPEN
      document.onkeyup = null
    }
  }, [])

  const handleGoTo = useCallback(
    link => {
      history.push(link)
      handleToggle(false)
    },
    [history, handleToggle],
  )

  return (
    <>
      <StyledButton
        variant="outlined"
        onClick={handleToggle}
        size="small"
        color="secondary"
        startIcon={<MegaMenuIcon />}
      >
        <span className="button-text">
          {Translate({ messageKey: 'shortcuts' })}
        </span>
      </StyledButton>

      <div
        className={[classes.mmContainer, !isOpen ? classes.mmHidden : ''].join(
          ' ',
        )}
      >
        <div className={classes.mmTopBar}>
          <SearchWrapper>
            <Box
              sx={{
                padding: '5px',
                marginLeft: '5px',
                marginRight: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SearchIcon color="#999" />
            </Box>
            <StyledInputSearch
              value={searchTherm}
              onChange={handleChange}
              placeholder={Translate({ messageKey: 'search_in_the_menu' })}
            />
          </SearchWrapper>
          <IconButton
            className={classes.mmCloseIcon}
            onClick={handleToggle}
            tabIndex={-1}
            size="large"
          >
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </div>
        <div className={classes.mmGridContainer}>
          <div className={classes.mmGrid}>
            {menuItems.map(category => {
              const filteredItems = category.items.filter(
                i =>
                  i.enabled &&
                  i.label.toLowerCase().indexOf(searchTherm.toLowerCase()) >= 0,
              )

              return filteredItems.length > 0 ? (
                <div
                  className={classes.mmGridCell}
                  key={`mmi-${category.key}-title`}
                >
                  <div className={classes.mmCategoryTitle}>
                    {category.title}
                    <div className={classes.mmCategoryIcon}>
                      {category.icon}
                    </div>
                  </div>
                  <div className={classes.mmItemsList}>
                    {filteredItems.map((item, idx) => (
                      <button
                        key={`mmi-${category.key}-item-${idx}`}
                        className={classes.mmItem}
                        onClick={() => handleGoTo(item.link)}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div>
                </div>
              ) : null
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default MegaMenu
