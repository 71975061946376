import { Moment, Translate } from '@chipinside/frontend'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'

export function manipulateFilters(filters, columns, customTitles = []) {
  if (!filters || !columns) {
    return []
  }

  return filters
    .filter(filter => filter.visible)
    .map(filter => {
      let response = { ...filter }

      const columnsRef = columns.map(column => ({
        ...column,
        ref: first(column.ref.split('.')),
      }))

      const column = columnsRef.find(col => col.ref === filter.name)

      if (!isEmpty(column)) {
        response = {
          ...response,
          enabled: column.enabled,
          title: column.title,
        }
      }

      function getCustomTitle(arr, key) {
        let customTitle = []
        if (Array.isArray(arr) && arr.length) {
          customTitle = arr.filter(item => first(Object.keys(item)) === key)
          return customTitle.length ? first(customTitle)[key] : null
        }
        return null
      }

      switch (filter.name) {
        case 'timestamp': {
          // change select option dates
          if (!isEmpty(filter.options)) {
            response.options.forEach(date => {
              date.label = Moment.view(date.label)
            })
          }
          response = {
            ...response,
            enabled: true,
            title:
              getCustomTitle(customTitles, 'timestamp') ||
              Translate({ messageKey: 'datetime' }),
          }
          break
        }

        case 'deleted_at_filter': {
          // change select option dates
          if (!isEmpty(filter.options)) {
            response.options.forEach(date => {
              date.label = Moment.view(date.label)
            })
          }
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'discard_date' }),
          }
          break
        }

        case 'health_status': {
          response = {
            ...response,
            enabled: true,
            open: false,
            title: Translate({ messageKey: 'health_status' }),
            placeHolder: Translate({
              messageKey: 'select_one_item_male',
              params: {
                item: Translate({ messageKey: 'health_status', lower: true }),
              },
            }),
          }
          break
        }

        case 'del': {
          response = {
            ...response,
            enabled: true,
            open: false,
            title: Translate({ messageKey: 'DEL' }),
            placeHolder: Translate({
              messageKey: 'select_one_item_male',
              params: { item: Translate({ messageKey: 'DEL' }) },
            }),
          }
          break
        }

        case 'only-completed': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'event_group' }),
          }
          break
        }

        case 'only-with-diagnostic': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'events_with_diagnostic' }),
          }
          break
        }

        case 'only-with-treatment': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'events_with_treatment' }),
          }
          break
        }

        case 'farm-filter': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'farm' }),
            placeHolder: Translate({
              messageKey: 'select_one_item_female',
              params: { item: Translate({ messageKey: 'farm', lower: true }) },
            }),
          }
          break
        }

        case 'range':
        case 'date-filter': {
          response = {
            ...response,
            enabled: true,
            open: false,
            title: Translate({ messageKey: 'custom_range' }),
          }
          break
        }

        case 'type': {
          response = {
            ...response,
            enabled: true,
            open: false,
            title: Translate({ messageKey: 'event_type' }),
          }
          break
        }

        case 'collar-status-filter': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'collar_status' }),
            placeHolder: Translate({
              messageKey: 'select_one_item_male',
              params: {
                item: Translate({ messageKey: 'status', lower: true }),
              },
            }),
          }
          break
        }

        case 'interval-filter': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'custom_range' }),
            placeHolder: Translate({
              messageKey: 'select_one_item_female',
              params: { item: Translate({ messageKey: 'status_date' }) },
            }),
          }
          break
        }

        case 'error-code-filter': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'error_plural' }),
            placeHolder: Translate({
              messageKey: 'select_one_item_male',
              params: { item: Translate({ messageKey: 'error', lower: true }) },
            }),
          }
          break
        }

        case 'batch-filter': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'batch' }),
            placeHolder: Translate({
              messageKey: 'select_one_item_male',
              params: { item: Translate({ messageKey: 'batch', lower: true }) },
            }),
          }
          break
        }

        case 'batch': {
          response = {
            ...response,
            enabled: true,
            title: Translate({ messageKey: 'batch_plural' }),
          }
          break
        }

        case 'week': {
          response = {
            ...response,
            enabled: true,
            open: false,
            title: Translate({ messageKey: 'observation_period' }),
          }
          break
        }

        case 'cycle': {
          response = {
            ...response,
            enabled: true,
            open: false,
            title: Translate({ messageKey: 'cycle_plural' }),
          }
          break
        }

        default: {
          response = {
            ...response,
            enabled: true,
            open: false,
            title:
              response.title ||
              filter.title ||
              Translate({ messageKey: 'filter' }),
            placeHolder: response.placeholder,
          }
        }
      }

      if (response.title === undefined) {
        response = {
          ...response,
          enabled: true,
          title: Translate({ messageKey: 'filter' }),
        }
      }

      return response
    })
}
