import { fmt, Grid, Moment, Translate } from '@chipinside/frontend'
import Popover from '@mui/material/Popover'
import Snackbar from '@mui/material/Snackbar'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { StaticDatePicker } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'

import { useAsyncState } from '#/store/hooks/asyncState'

import Button from '../Button'
import TextField from '../TextInput'

const StyledGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
})
const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    flex: 1,
    fontSize: 16,
    padding: theme.spacing(2, 3, 0, 3),
  },
  actions: {
    padding: theme.spacing(2, 3, 2, 3),
  },
}))

const DateRangeDouble = ({
  index,
  name,
  label,
  placeholder,
  disabled,
  views = ['year', 'month', 'day'],
  containerClass,
  customStyle,
  defaultValues,
  onChange,
  showButtons = true,
  minDate,
  ...rest
}) => {
  const classes = useStyles()
  const [defaultFrom, defaultTo] = defaultValues

  const inputFormats = Translate({ messageKey: 'date_format_long' })
  const [begin, setBegin] = useAsyncState(
    defaultFrom && Moment(defaultFrom).isValid() ? Moment(defaultFrom) : null,
  )
  const [end, setEnd] = useAsyncState(
    defaultTo && Moment(defaultTo).isValid() ? Moment(defaultTo) : null,
  )
  useEffect(() => {
    if (defaultFrom && defaultTo) {
      setBegin(Moment(defaultFrom))
      setEnd(Moment(defaultTo))

      const input = showDatesOnInput(defaultFrom, defaultTo)
      setInputVal(input)
    }
  }, [defaultFrom, defaultTo])

  const [inputVal, setInputVal] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [showSnack, setShowSnack] = useState(false)
  const handleSnackClose = (_, reason) => {
    if (reason === 'clickaway') return
    setShowSnack(false)
  }

  React.useEffect(() => {
    const input = showDatesOnInput(begin, end)
    setInputVal(input)
  }, [])

  const customFilters = [
    {
      key: 'last-30-days',
      label: Translate({ messageKey: 'datepicker_previous_month' }),
      values: {
        from: Moment().subtract(30, 'days').startOf('day').format(fmt.api),
        to: Moment().endOf('day').format(fmt.api),
      },
    },
    {
      key: 'last-7-days',
      label: Translate({
        messageKey: 'datepicker_last_days',
        params: { days: 7 },
      }),
      values: {
        from: Moment().subtract(7, 'days').startOf('day').format(fmt.api),
        to: Moment().endOf('day').format(fmt.api),
      },
    },
    {
      key: 'last-2-days',
      label: Translate({
        messageKey: 'datepicker_last_days',
        params: { days: 2 },
      }),
      values: {
        from: Moment().subtract(2, 'days').startOf('day').format(fmt.api),
        to: Moment().endOf('day').format(fmt.api),
      },
    },
  ]

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  async function handlePaste(event) {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')

    const regex =
      inputFormats[0] === 'D'
        ? /^(0[1-9]|[12][0-9]|3[0-1])[/](0[1-9]|1[0-2])[/](\d{4})$/ //DD/MM/YYYY
        : /^(0[1-9]|1[0-2])[/](0[1-9]|[12][0-9]|3[0-1])[/](\d{4})$/ //MM/DD/YYYY
    const [strFrom, strTo] = pastedData.split(' - ')

    if (strFrom && strTo && strFrom.match(regex) && strTo.match(regex)) {
      const iFrom = Moment(strFrom)
      const iTo = Moment(strTo)

      if (iFrom.isValid() && iTo.isValid()) {
        const FormattedFrom = iFrom.format('L')
        const FormattedTo = iTo.format('L')

        const isBefore = Moment(iFrom).isBefore(Moment(iTo))
        const b = await setBegin(isBefore ? FormattedFrom : FormattedTo)
        const e = await setEnd(isBefore ? FormattedTo : FormattedFrom)
        setInputVal(
          `${isBefore ? strFrom : strTo} - ${isBefore ? strTo : strFrom}`,
        )
        if (onChange) onChange([b, e])
        return
      }
    }
    //in case of pasting an invalid date show de snackbar
    setShowSnack(true)
  }

  const handleChangeDate = (date, type) => {
    if (type === 'begin') {
      if (date.isBefore(end)) {
        setBegin(date)
      } else {
        setBegin(end)
        setEnd(date)
      }
    } else if (type === 'end') {
      if (date.isAfter(begin)) {
        setEnd(date)
      } else {
        setEnd(begin)
        setBegin(date)
      }
    }
  }

  const handleCustomFilterChange = ({ from, to }) => {
    setBegin(from)
    setEnd(to)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClear = () => {
    setBegin(defaultFrom)
    setEnd(defaultTo)
  }

  const handleApply = () => {
    if (onChange) {
      onChange([begin, end])
    }
    const input = showDatesOnInput(begin, end)
    setInputVal(input)
    handleClose()
  }

  const showDatesOnInput = (begin, end) => {
    if (begin && end) {
      return `${Moment(begin).format(inputFormats)} - ${Moment(end).format(
        inputFormats,
      )}`
    }
    return undefined
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover' : undefined

  return (
    <React.Fragment>
      <TextField
        key={`${name}-${index}-date-range`}
        className={containerClass}
        style={customStyle}
        containerStyle={{ width: '100%', minWidth: 200 }}
        name={`${name}-date-range`}
        label={label ?? Translate({ messageKey: 'interval' })}
        placeHolder={placeholder}
        disabled={disabled}
        onClick={handleClick}
        onPaste={handlePaste}
        value={inputVal}
        {...rest}
      />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid key="calendar-container" container direction="row">
          <Grid key="start-date">
            <StaticDatePicker
              label={Translate({ messageKey: 'start_date' })}
              displayStaticWrapperAs="desktop"
              value={Moment(begin)}
              minDate={Moment(minDate ?? '2000-01-01')}
              disableFuture
              views={views}
              onChange={date => handleChangeDate(date, 'begin')}
              toolbarFormat={inputFormats}
              slotProps={{ textField: { placeholder: null } }}
              showToolbar
            />
          </Grid>
          <Grid key="end-date">
            <StaticDatePicker
              label={Translate({ messageKey: 'end_date' })}
              displayStaticWrapperAs="desktop"
              value={Moment(end)}
              minDate={Moment(minDate ?? '2000-01-01')}
              disableFuture
              views={views}
              onChange={date => handleChangeDate(date, 'end')}
              toolbarFormat={inputFormats}
              slotProps={{ textField: { placeholder: null } }}
              showToolbar
            />
          </Grid>
        </Grid>
        <Grid key="buttons-container" container justifyContent="space-evenly">
          {views.includes('day') &&
            showButtons &&
            customFilters.map(item => (
              <StyledGrid key={item.key}>
                <Button
                  btnClick={() => handleCustomFilterChange(item.values)}
                  btnProps={{ variant: 'outlined' }}
                >
                  {item.label}
                </Button>
              </StyledGrid>
            ))}
        </Grid>
        <Grid
          key="actions-container"
          container
          justifyContent="flex-end"
          className={classes.actions}
        >
          <StyledGrid>
            <Button
              btnClick={handleClear}
              btnProps={{ variant: 'text', color: 'secondary' }}
            >
              {Translate({ messageKey: 'default_values' })}
            </Button>
          </StyledGrid>
          <StyledGrid>
            <Button asCreate btnClick={handleApply}>
              {Translate({ messageKey: 'apply' })}
            </Button>
          </StyledGrid>
        </Grid>
      </Popover>
      <Snackbar
        open={showSnack}
        autoHideDuration={5000}
        onClose={handleSnackClose}
        message={Translate({ messageKey: 'invalid_date_format' })}
      />
    </React.Fragment>
  )
}

export default DateRangeDouble
