import { useFetch, useMakeFetch } from '@chipinside/fetcher'
import { fmt, Moment } from '@chipinside/frontend'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { getUserInfo } from '#/store/ducks/auth'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { endpoint } from '#/store/services/endpoints'

import VicContext from './vic-context'

const VicProvider = props => {
  const chatScrollRef = useRef(null)
  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const currentUser = useSelector(getUserInfo, isEqual)
  const REGISTER_EVENT = 'event/register'

  // set dialog (modal) actions with initial state
  const dialogInitialState = useMemo(
    () => ({
      type: null,
      dialogOpened: false,
      dialogTitle: null,
      dialogSubTitle: null,
      dialogContent: null,
      dialogOnClose: () => {},
    }),
    [],
  )

  // chat box states
  const [isOpen, setIsOpen] = useState(false)
  const [msgList, setMsgList] = useState([])
  const [msgFromSocket, setMsgFromSocket] = useState(null)
  const [before, setBefore] = useState(null)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [noMoreMsgsLeft, setNoMoreMsgsLeft] = useState(false)
  const [actionsDialog, setActionsDialog] = useState(dialogInitialState)
  const [controller, setController] = useState(null)

  // vic messages request
  const [_, msgMakeRequest] = useMakeFetch()

  useEffect(() => {
    if (currentFarmSlug) {
      setController(endpoint.farm.vic.messages({ farm: currentFarmSlug }))
    }
  }, [currentFarmSlug])

  const { data, mutate, isLoading, isValidating } = useFetch({
    controller,
    params: before ? { before } : {},
  })

  /* A function that scrolls down the chat box to the last message */
  const scrollToBottom = () => {
    if (chatScrollRef?.current?.scrollHeight) {
      chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight
    }
  }

  /* A function that is used to mark a message as visualized. */
  const markMessagesVisualized = useCallback(
    msgId =>
      msgMakeRequest({
        controller: endpoint.farm.vic.msg_read({
          farm: currentFarmSlug,
          msg: msgId,
        }),
        method: 'put',
      }),
    [currentFarmSlug, msgMakeRequest],
  )

  /* A function that is used to close the chat. */
  const handleCloseChat = useCallback(() => {
    setIsOpen(false)
    if (msgList.length && !msgList?.[0]?.visualized_at && msgList?.[0]?.id) {
      const nowTimestamp = Moment().format(fmt.api)
      markMessagesVisualized(msgList[0].id)
      mutate(
        setMsgList(
          msgList.map(msg =>
            !msg.visualized_at ? { ...msg, visualized_at: nowTimestamp } : msg,
          ),
        ),
      )
    }
  }, [markMessagesVisualized, msgList, mutate])

  /* A function that is used to remove a message. */
  const handleRemoveMessage = useCallback(
    async id => {
      if (!isLoading) {
        await msgMakeRequest({
          controller: endpoint.farm.vic.msg({ farm: currentFarmSlug, msg: id }),
          method: 'delete',
        })
        mutate(
          setMsgList(
            msgList.map(msg =>
              msg.id === id ? { ...msg, deleted_at: true } : msg,
            ),
          ),
        )
      }
    },
    [currentFarmSlug, isLoading, msgList, msgMakeRequest, mutate],
  )

  /* A function that is used to undo the removal of a message. */
  const undoMessageRemoval = useCallback(
    async id => {
      if (!isLoading) {
        await msgMakeRequest({
          controller: endpoint.farm.vic.msg({ farm: currentFarmSlug, msg: id }),
          method: 'put',
        })
        mutate(
          setMsgList(
            msgList.map(msg =>
              msg.id === id ? { ...msg, deleted_at: null } : msg,
            ),
          ),
        )
      }
    },
    [currentFarmSlug, msgList, isLoading, msgMakeRequest, mutate],
  )

  const vicContextData = useMemo(
    () => ({
      chatScrollRef,
      currentFarmSlug,
      currentUser,
      isLoading,
      isValidating,
      data,
      dialogInitialState,
      actionsDialog,
      REGISTER_EVENT,
      msgList,
      setMsgList,
      noMoreMsgsLeft,
      setNoMoreMsgsLeft,
      before,
      setBefore,
      isOpen,
      setIsOpen,
      msgFromSocket,
      setMsgFromSocket,
      isFetchingMore,
      scrollToBottom,
      setIsFetchingMore,
      handleCloseChat,
      setActionsDialog,
      handleRemoveMessage,
      undoMessageRemoval,
      markMessagesVisualized,
    }),
    [
      chatScrollRef,
      currentFarmSlug,
      currentUser,
      isLoading,
      isValidating,
      data,
      dialogInitialState,
      actionsDialog,
      REGISTER_EVENT,
      msgList,
      setMsgList,
      noMoreMsgsLeft,
      setNoMoreMsgsLeft,
      before,
      setBefore,
      isOpen,
      setIsOpen,
      msgFromSocket,
      setMsgFromSocket,
      isFetchingMore,
      scrollToBottom,
      setIsFetchingMore,
      handleCloseChat,
      setActionsDialog,
      handleRemoveMessage,
      undoMessageRemoval,
      markMessagesVisualized,
    ],
  )

  return (
    <VicContext.Provider value={vicContextData}>
      {props.children}
    </VicContext.Provider>
  )
}

export default VicProvider
