import {
  ExportIcon,
  LoadingIconButton,
  PrintIcon,
  RefreshIcon,
  Translate,
} from '@chipinside/frontend'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import Button from '#/components/FormControl/Inputs/Button/index'
import { isDev } from '#/utils/processEnvironment'

import renderPopulateIcon from './populateCommand'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    zIndex: 0,
    alignItems: 'center',
    marginRight: '0 !important',
    justifyContent: 'flex-end',
  },
}))

const ButtonStyled = styled(IconButton)({
  padding: '4px',
  margin: '5px',
})

const TopRight = ({
  disabled,
  buttonCreate = {},
  exportAs,
  aditionalButton,
  refreshDatagrid,
  controller,
}) => {
  const classes = useStyles()

  const renderAdditionalButton = () => {
    return aditionalButton?.content ?? null
  }
  const renderButtonCreate = () => {
    if (!isEmpty(buttonCreate)) {
      const { enabled, btnTitle, btnClick, onClickParams, dropdownItems } =
        buttonCreate

      if (enabled) {
        return (
          <Button
            asCreate
            btnClick={btnClick}
            dropdownItems={dropdownItems}
            onClickParams={onClickParams}
            btnProps={{
              variant: 'extended',
              color: 'primary',
              size: 'medium',
              disabled,
            }}
          >
            {btnTitle}
          </Button>
        )
      }
    }
    return null
  }

  const renderExportButton = () => {
    const options = [
      {
        title: 'Excel (.xls)',
        enabled: true,
        itemClick: () => exportAs('xlsx'),
      },
      {
        title: 'PDF (.pdf)',
        enabled: true,
        itemClick: () => exportAs('pdf'),
      },
    ]

    if (!find(options, { enabled: true })) {
      return null
    }

    return (
      <Button asIcon noDropdownIcon dropdownItems={options}>
        <ExportIcon fontSize="28px" />
      </Button>
    )
  }

  const renderPrintButton = () => {
    return (
      <Tooltip title={Translate({ messageKey: 'print' })} arrow>
        <div>
          <ButtonStyled
            size="small"
            onClick={() => exportAs('pdf', true)}
            disabled={disabled}
          >
            <PrintIcon fontSize={32} />
          </ButtonStyled>
        </div>
      </Tooltip>
    )
  }

  const renderRefreshIcon = () => {
    if (isDev) {
      return (
        <LoadingIconButton
          title={Translate({ messageKey: 'refresh_datagrid' })}
          size="small"
          onClick={() => refreshDatagrid({})}
          disabled={disabled}
          icon={<RefreshIcon fontSize={32} />}
        />
      )
    }
    return null
  }

  return (
    <div className={classes.container}>
      {aditionalButton?.before && renderAdditionalButton()}
      {renderPopulateIcon(controller, () => refreshDatagrid({}))}
      {renderRefreshIcon()}
      {renderPrintButton()}
      {renderExportButton()}
      {renderButtonCreate()}
      {aditionalButton?.after && renderAdditionalButton()}
    </div>
  )
}

export default TopRight
