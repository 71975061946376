import { Translate } from '@chipinside/frontend'
import { Box, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { isEqual, isNumber } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import NewBadge from '#/components/badges/NewBadge'
import AvatarCell from '#/components/FormatCell/Types/AvatarLink/Manipulate/AvatarCell'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import Colors from '#/styles/Colors'
import Fonts from '#/styles/Fonts'
import { getLink } from '#/utils/getLink'
import stringLimit from '#/utils/stringLimit'

const Title = styled('span')(({ discarded }) => ({
  fontSize: Fonts.fontSize.S,
  color: Colors.primary,
  fontWeight: 700,
  ...(discarded && {
    textDecoration: 'line-through',
    color: Colors.animal.discard.color,
  }),
}))

const Subtitle = styled('small')({
  maxWidth: 120,
  display: 'block',
  color: Colors.muted,
  fontSize: Fonts.fontSize.SS,
})

const AvatarWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Container = styled(Stack)(({ collar }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  ...(collar && {
    '&:hover h6': {
      color: Colors.primary,
    },
  }),
}))

/**
 * @param {string} title main
 * @param {string} subtitle subtitle
 * @param {string} linkTo type link 'animal', 'batch'
 * @param {string} slug used to create link
 * @param {string} tab used to create link
 * @param {string} collar object with code e ststus
 * @returns It returns a link to an animal or batch, with an avatar, title, and subtitle
 */
const TypeAvatarLink = props => {
  const {
    title,
    subtitle,
    linkTo = 'animal', // animal ou batch
    limit = 30,
    collar,
    discarded,
    timestamp,
  } = props

  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)

  if (!title) return <span>-</span>

  const tooltip = (
    <React.Fragment>
      {Translate({ messageKey: ['access_item', linkTo], lower: [false, true] })}
      {collar && isNumber(collar.code) && !discarded && (
        <>
          <br />
          <span>
            {Translate({
              messageKey: ['attached_collar', collar.code],
              translate: [true, false],
              separator: ': ',
            })}
          </span>
        </>
      )}
    </React.Fragment>
  )

  return (
    <Tooltip title={tooltip}>
      <Link to={getLink({ ...props, type: linkTo, farm: currentFarmSlug })}>
        <Container collar={String(collar)}>
          <AvatarWrapper>
            <AvatarCell discarded={discarded} icon={linkTo} collar={collar} />
            <NewBadge timestamp={timestamp} />
          </AvatarWrapper>
          <Box>
            <Title discarded={discarded}>{title}</Title>
            <Subtitle>{stringLimit(subtitle, limit)}</Subtitle>
          </Box>
        </Container>
      </Link>
    </Tooltip>
  )
}
export default TypeAvatarLink
