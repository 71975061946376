import { Translate } from '@chipinside/frontend'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Checkbox from '@mui/material/Checkbox'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import React, { Component } from 'react'

import Fonts from '#/styles/Fonts'
import Palette from '#/styles/Palette'

import DatagridCell from '../DatagridCell'
import ModalMore from '../ModalMore'
import RowAction from '../RowAction'
import classes from './DatagridBodyContainer.module.css'

const styles = {
  row: {
    table_striped_odd: {
      backgroundColor: '#fff',
    },
    table_striped_even: {
      backgroundColor: Palette.gray.light[100],
    },
    table_striped_even_darken: {
      backgroundColor: Palette.gray.self[200],
    },
    table_striped_odd_darken: {},
  },

  moreStyle: {
    fontSize: Fonts.fontSize.L,
    color: Palette.gray.self[700],
  },
  // define a padding-left value for extra space in the first column
  firstColumn: {
    paddingLeft: '12px',
  },
}

class DatagridBodyContainer extends Component {
  static getDerivedStateFromProps(props, state) {
    const { list = [] } = props

    const controls = state.controls || {}
    list.forEach((row, index) => {
      let uniqId = row.slug || row.code || row.serial

      controls[`${index}-${uniqId}`] = controls[`${index}-${uniqId}`] || {}
      controls[index] = controls[index] || {}
      controls[index] = {
        modalOpen: controls[index].modalOpen || false,
      }
      controls[`${index}-${uniqId}`] = {
        detailsOpen: controls[`${index}-${uniqId}`].detailsOpen || false,
      }
    })

    return {
      ...state,
      controls,
    }
  }

  constructor(props) {
    super(props)

    this.handlerMore = this.handlerMore.bind(this)
    this.handlerDetails = this.handlerDetails.bind(this)

    this.state = {
      controls: {},
    }
  }

  handlerMore(rowIndex) {
    this.setState(prevState => ({
      ...prevState,
      controls: {
        ...prevState.controls,
        [rowIndex]: {
          ...prevState.controls[rowIndex],
          modalOpen: !prevState.controls[rowIndex].modalOpen,
        },
      },
    }))
  }

  handlerDetails(rowIndex, row) {
    let uniqId = row.slug || row.code || row.serial
    this.setState(prevState => ({
      ...prevState,
      controls: {
        ...prevState.controls,
        [`${rowIndex}-${uniqId}`]: {
          ...prevState.controls[`${rowIndex}-${uniqId}`],
          detailsOpen: !prevState.controls[`${rowIndex}-${uniqId}`].detailsOpen,
        },
      },
    }))
  }

  onRowDetail({ row, rowIndex }) {
    return this.props.onDetailsOpened({ row, rowIndex })
  }

  render() {
    const {
      list = [],
      enabledColumns = [],
      visibleColumns = [],
      invisibleColumns = [],
      bodyIsStriped = true,
      actionsEnabled = [],
      withDetails,
      bulkActions,
      onSelectRow,
      cellClass = '',
      cellStyle = {},
      customRowStyle = () => ({}),
    } = this.props

    const columns = []
    /* heads */
    list.forEach((row, rowIndex) => {
      const rowEnabledColumns = new Map()
      enabledColumns.forEach(head =>
        head.ref ? rowEnabledColumns.set(head, row[head.ref]) : null,
      )

      const customStyle = customRowStyle(row, rowIndex) || {}

      let bodyStriped =
        rowIndex % 2 === 0
          ? styles.row.table_striped_even
          : styles.row.table_striped_odd

      /* bulkActions */
      if (!!bulkActions && !isEmpty(bulkActions)) {
        columns.push(
          <DatagridCell
            key={`bulk-actions-${rowIndex}`}
            cellClass={[classes.cell, classes.detailButton, cellClass].join(
              ' ',
            )}
            cellStyle={{ ...bodyStriped, ...customStyle, ...cellStyle }}
            component={
              <Checkbox
                checked={bulkActions.selectedRows.includes(row)}
                disabled={
                  bulkActions.max_bulk_items ===
                    bulkActions.selectedRows.length &&
                  !bulkActions.selectedRows.includes(row)
                }
                indeterminate={
                  bulkActions.max_bulk_items ===
                    bulkActions.selectedRows.length &&
                  !bulkActions.selectedRows.includes(row)
                }
                onChange={event => onSelectRow(event, row)}
                inputProps={{
                  'aria-label': 'select row',
                  'name': row[get(bulkActions, 'id', 'slug')],
                }}
                color="primary"
              />
            }
          />,
        )
      }

      /* details */
      if (withDetails) {
        let uniqId = row.slug || row.code || row.serial
        columns.push(
          <DatagridCell
            key={`detail-${rowIndex}`}
            cellClass={[classes.cell, classes.detailButton, cellClass].join(
              ' ',
            )}
            cellStyle={{ ...bodyStriped, ...customStyle, ...cellStyle }}
            component={
              <RowAction
                iconDefault={
                  this.state.controls[`${rowIndex}-${uniqId}`].detailsOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                onAction={() => this.handlerDetails(rowIndex, row)}
              />
            }
          />,
        )
      }

      columns.push(
        ...visibleColumns.map((head, headIndex) => {
          const params = isFunction(head.renderCell)
            ? head.renderCell(row[head.ref], row, head)
            : {}
          if (head.bodyStyle) {
            params.style = { ...head.bodyStyle, ...params.style }
          }
          if (head.bold) {
            params.style = { fontWeight: 'bold', ...params.style }
          }
          if (head.switchBackgroundTo === 'darken' && bodyIsStriped) {
            bodyStriped =
              rowIndex % 2 === 0
                ? styles.row.table_striped_even_darken
                : styles.row.table_striped_odd_darken
          }
          // apply the extra left space on the first column cells
          const firstColumn = headIndex === 0 ? styles.firstColumn : {}

          return (
            <DatagridCell
              key={`${rowIndex}-${headIndex}`}
              cellClass={[classes.cell, this.props.cellClass].join(' ')}
              cellStyle={{
                ...bodyStriped,
                ...cellStyle,
                ...params.style,
                ...firstColumn,
                ...customStyle,
              }}
              component={params.value}
              title={
                row[head.ref] !== undefined &&
                row[head.ref] !== null &&
                params.value !== null
                  ? `${row[head.ref]}`
                  : null
              }
              withoutData={params.withoutData || this.props.withoutData}
            />
          )
        }),
      )

      if (!isEmpty(actionsEnabled)) {
        columns.push(
          <DatagridCell
            key={`actions-${rowIndex}`}
            cellClass={[classes.cell, classes.actionsCell, cellClass].join(' ')}
            cellStyle={{ ...bodyStriped, ...customStyle, ...cellStyle }}
            component={
              <div className={classes.actions}>
                {actionsEnabled.map((action, indexAction) => {
                  let response = null
                  if (action.condition(row)) {
                    const dropDownActions = get(action, 'dropDownList', [])

                    let dropdownItems = []
                    if (dropDownActions.length)
                      dropdownItems = dropDownActions.map(item => {
                        let cond = true
                        if (
                          item.condition &&
                          typeof item.condition === 'function'
                        ) {
                          cond = item.condition(row)
                        }
                        return {
                          ...item,
                          enabled: item.enabled && cond,
                          itemClick: () => action.onAction(row, item.actionKey),
                        }
                      })

                    let list = []
                    if (action.list) {
                      list = action.list?.map(item => ({
                        ...item,
                        title: isFunction(item.title)
                          ? item.title(row)
                          : item.title,
                        enabled:
                          item.enabled &&
                          (isFunction(item.condition)
                            ? item.condition(row)
                            : true),
                        icon: isFunction(item.icon)
                          ? item.icon(row)
                          : item.icon,
                        border: isFunction(item.border)
                          ? item.border(row)
                          : item.border,
                        onClick: isFunction(item.onClick)
                          ? () => item.onClick(row, item.actionKey)
                          : null,
                      }))
                    }

                    response = (
                      <RowAction
                        key={indexAction}
                        type={action.type}
                        onAction={
                          dropdownItems.length
                            ? () => {}
                            : () =>
                                isFunction(action.onAction)
                                  ? action.onAction(row, action.type)
                                  : null
                        }
                        list={list}
                        dropDownList={dropdownItems}
                      />
                    )
                  }
                  return response
                })}
              </div>
            }
          />,
        )
      }

      /* more */
      if (!isEmpty(invisibleColumns)) {
        columns.push(
          <DatagridCell
            key={`more-${rowIndex}`}
            cellClass={[classes.cell, classes.moreButton, cellClass].join(' ')}
            cellStyle={{
              ...bodyStriped,
              ...customStyle,
              ...styles.moreStyle,
              ...cellStyle,
            }}
            component={
              <RowAction
                iconDefault={<MoreHorizIcon />}
                labelDefault={Translate({ messageKey: 'more_details' })}
                onAction={() => this.handlerMore(rowIndex)}
              />
            }
          />,
        )

        columns.push(
          <ModalMore
            key={`modal-more-${rowIndex}`}
            row={row}
            rowEnabledColumns={rowEnabledColumns}
            open={this.state.controls[rowIndex].modalOpen}
            onClose={() => this.handlerMore(rowIndex)}
            withoutData={this.props.withoutData}
          />,
        )
      }

      if (withDetails) {
        let uniqId = row.slug || row.code || row.serial
        columns.push(
          <div
            key={`card-details-more-${rowIndex}`}
            style={{
              display: this.state.controls[`${rowIndex}-${uniqId}`].detailsOpen
                ? 'block'
                : 'none',
              gridColumnStart: 1,
              gridColumnEnd: `span ${
                rowEnabledColumns.size +
                1 +
                Number(!isEmpty(actionsEnabled)) -
                (invisibleColumns.length ? invisibleColumns.length - 1 : 0)
              }`,
              margin: '8px',
            }}
          >
            {this.state.controls[`${rowIndex}-${uniqId}`].detailsOpen
              ? this.onRowDetail({ row, rowIndex })
              : null}
          </div>,
        )
      }
    })

    return columns
  }
}

export default DatagridBodyContainer
