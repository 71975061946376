import { Moment } from '@chipinside/frontend'
import React from 'react'

import { ExpiredTimeline } from './ExpiredTimeline'
import { HourScale } from './HourScale'
import { RecomendationScale } from './RecomendationScale'
import { getSizedStyles } from './styles'

const TypeAiTimeline = props => {
  if (!props.value) return <span>-</span>

  const { conventional, sexed, to, expires } = props.value
  const { size } = props
  const styles = getSizedStyles(size)
  const isExpired = Moment().isAfter(to)
  let currentHour = new Date().getHours()
  let currentDate = new Date()

  return !isExpired ? (
    <div style={styles.timeline_container}>
      <div style={styles.legend_row}>
        <div style={styles.legend}>C</div>
        <div style={styles.legend}>S</div>
      </div>
      <div>
        <RecomendationScale data={conventional} styles={styles} />
        <RecomendationScale data={sexed} styles={styles} />
        <HourScale
          hours={conventional}
          currentHour={currentHour}
          size={size}
          styles={styles}
        />
      </div>
    </div>
  ) : (
    <ExpiredTimeline
      diff={Moment.diff(currentDate, expires, 'h')}
      styles={styles}
    />
  )
}

export default TypeAiTimeline
