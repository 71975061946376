/**
 * Utilizado nas funções dos plotlines
 */
import { useFetch } from '@chipinside/fetcher'
import { Translate } from '@chipinside/frontend'
import { mdiCloseCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import makeStyles from '@mui/styles/makeStyles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'

import ExportFile from '#/components/ExportFile'
import { GraphLoader } from '#/components/Loader/ContentLoader/ChartLoader'
import { HighchartsTheme } from '#/components/NewCharts/highchartsTheme'
import { HighchartsTranslate } from '#/components/NewCharts/translates'
import { FallbackMessage, FallbackTitle } from '#/pages/Fallback/Styles'
import Palette from '#/styles/Old/Palette'
import { isDev } from '#/utils'

import ManipulateCharts from './ManipulateCharts'
import exportableFormatter from './ManipulateCharts/exportable'

import 'highcharts/modules/exporting'

const useStyles = makeStyles(() => ({
  GraphLoader: {
    height: 400,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 10,
    minHeight: '50px',
  },
  title: {
    fontWeight: 'bold',
    color: Palette.grey.darken[3],
    lineHeight: '1.4em',
    fontSize: '1.3em',
    margin: 'auto 0',
    display: 'flex',
    gap: '8px',
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'center',
    color: 'rgb(158, 158, 158)',
    paddingBottom: 10,
  },
  quicktip: {
    fontSize: 14,
    lineHeight: '21px',
  },
}))

/**
 * Componente refatorado do highcharts, para versão 2 dos dados do backend
 * @param {string} name nome para chave do chart
 * @param {endpoint} controller endpoint dos dados
 * @param {object} params parametros adicionais para SWR
 * @param {object} range range de data para SWR
 * @param {string} xAxisFormat formato do label do eixoX Ex.: DD/MM
 * @param {string} title titulo do gráfico
 * @param {string} subtitle subtitulo do gráfico
 * @param {object} extraLegends objeto com dados (nome, cor, events.)
 * @param {object} plotBandsColors dados adicionais pro Yaxis (THI)
 * @param {endpoint} plotLines endpoint dos plotlines
 * @returns Renderiza o highcharts
 */

function NewCharts(props) {
  const {
    name,
    title,
    subtitle,
    controller,
    params = {},
    range = {},
    xAxisFormat = 'DD/MM',
    extraLegends,
    plotBandsColors,
    plotLines,
    filterPlotlines,
    colorIndex,
    enableLimitColors,
    overwrite,
    readData,
    tooltipIgnore,
    quicktip,
    theme,
  } = props
  const classes = useStyles()
  const { cache } = useSWRConfig()
  const [chartData, setChartData] = useState()
  const [chartStatus, setChartStatus] = useState()
  const [exportModal, setExportModal] = useState({ type: null, open: false })
  const [exportController, setExportController] = useState(null)

  useEffect(() => {
    Highcharts.AST.allowedAttributes.push(
      'viewBox',
      'onmouseover',
      'onmouseout',
    )
    Highcharts.setOptions(HighchartsTranslate)
    Highcharts.theme = HighchartsTheme(theme)
    Highcharts.setOptions(Highcharts.theme)
  }, [theme])

  /*
   * Get chart data
   */
  const { data, isLoading, isValidating, mutate } = useFetch({
    controller,
    params: { ...range, ...params },
    headers: { 'x-smartfarm-graph-compat': 2 },
    swrOptions: {
      onSuccess: (_, key) => onSuccessRequest(key, controller),
    },
  })

  /**
   * Get plotlines data
   * Verificação de chave adicional no plotline
   */
  const { data: plotData, status: plotStatus } = useFetch({
    controller: plotLines?.endpoint ? plotLines.endpoint : plotLines,
    params: range,
    swrOptions: {
      onSuccess: (_, key) =>
        onSuccessRequest(
          key,
          plotLines?.endpoint ? plotLines.endpoint : plotLines,
        ),
    },
  })

  /**
   * Função responsável por remover cache do SWR
   * necessário pois estava sendo renderizado dados incorretos
   * @param {string} key
   * @param {string} controller
   */
  const onSuccessRequest = (key, controller) => {
    // 1. Obtém todas as chaves do cache como um array
    const cachedKeys = Array.from(cache.keys())

    // 2. Filtra as chaves com base em duas condições:
    //    a. A chave contém a string fornecida pelo parâmetro 'controller'
    //    b. A chave não é igual à chave fornecida pelo parâmetro 'key'
    const filteredKeys = cachedKeys?.filter(
      cachedKey => cachedKey.includes(controller) && key !== cachedKey,
    )

    // 3. Se houver chaves filtradas, itera sobre elas e deleta cada uma do cache
    filteredKeys?.forEach(cachedKey => cache.delete(cachedKey))
  }

  /*
   * Executado quando clicado
   * define o controller e abre o modal, assim executa o SWR
   */
  const startExport = () => {
    setExportController(controller)
    setExportModal({ type: '.xlsx', open: true })
  }

  const resetExport = () => {
    setExportModal({ type: null, open: false })
    setExportController(null)
  }

  const renderExport = () => {
    const { open, type } = exportModal
    return (
      <ExportFile
        controller={exportController}
        params={{ ...range, ...params }}
        filename={name}
        exportType={type}
        onCloseModal={resetExport}
        modalOpen={open}
      />
    )
  }

  /**
   * Retorna o Status total das requests
   */
  function getStatus() {
    const statusPlotlines = () => plotData && typeof plotData !== 'undefined'
    const statusChart = () => !isLoading && typeof data !== 'undefined'
    return plotLines ? statusPlotlines() && statusChart() : statusChart()
  }
  /*
   * Manipulate chart
   * Realiza alteração nos dados recebidos do backend
   */
  useEffect(() => {
    if (getStatus() && !checkBackendBlobError()) {
      let formattedData

      formattedData = ManipulateCharts({
        data,
        xAxisFormat,
        extraLegends,
        plotBandsColors,
        colorIndex,
        enableLimitColors,
        overwrite,
        tooltipIgnore,
        ...(plotLines
          ? {
              plotLines: {
                plotData: Object.fromEntries(
                  Object.entries(plotData).filter(([key]) =>
                    filterPlotlines ? !filterPlotlines.includes(key) : key,
                  ),
                ),
                range,
                plotTypes: plotLines.type,
              },
            }
          : {}),
      })

      const refresh = isDev
        ? {
            customButton: {
              text: 'Refresh',
              onclick: () => mutate(),
            },
          }
        : {}

      if (formattedData) {
        formattedData = {
          ...formattedData,
          ...exportableFormatter(props.exportable, startExport, refresh),
        }
        setChartData(formattedData)
        setChartStatus('done')
      }
    }
  }, [data, isLoading, plotData, isValidating, plotStatus])

  /**
   * Função para evitar quebra de tela quando da problema no backend quando backend retorna blob
   */
  const checkBackendBlobError = () => data?.type === 'text/html'

  /*
   * Funções internas para status do chart
   * Retorna dados pro render
   */
  function renderChart(chartStatus, status) {
    /*
     * Retorno do gráfico, após terminar toda manipulação de dados
     */
    if (
      (chartStatus === 'done' && getStatus() && !checkBackendBlobError()) ||
      readData
    )
      return (
        <>
          <HighchartsReact options={chartData} highcharts={Highcharts} />
          {props.exportable && exportModal.open ? renderExport() : null}
        </>
      )
    /*
     * Retorno do aviso de falha ao carregar o gráfico
     */
    if (status === 'failed' || checkBackendBlobError()) {
      return (
        <div key="chart-failed" className={classes.GraphLoader}>
          <Icon path={mdiCloseCircleOutline} color="red" size={4} />
          <FallbackTitle>
            {Translate({ messageKey: '500_fallback_title' })}
          </FallbackTitle>
          <FallbackMessage>
            {Translate({ messageKey: 'error_500' })}
          </FallbackMessage>
        </div>
      )
    }
    /*
     * Retorno do Loader exibido quando o gráfico não está pronto ou não falhou
     */
    return (
      <div key="chart-loader" className={classes.GraphLoader}>
        <GraphLoader label={Translate({ messageKey: 'loading_chart_data' })} />
      </div>
    )
  }

  /*
   * Funções internas para header do chart
   * Retorna Header pro render
   */
  function renderHeader(title, subtitle, quicktip) {
    if (!title) return null
    return (
      <>
        <div className={classes.header}>
          {title ? (
            <span className={classes.title}>
              {title}
              <div className={classes.quicktip}>{quicktip}</div>
            </span>
          ) : null}
        </div>
        <div className={classes.subtitle}>{subtitle}</div>
      </>
    )
  }

  if (readData && !controller) {
    return (
      <HighchartsReact
        options={readData}
        highcharts={Highcharts}
        key={controller + JSON.stringify(range)}
      />
    )
  }

  /*
   * Render
   */
  return (
    <div key={`chart-container-${controller}`}>
      {renderHeader(title, subtitle, quicktip)}
      {renderChart(chartStatus, isLoading)}
    </div>
  )
}

export default NewCharts
