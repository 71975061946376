import FormControlLabel from '@mui/material/FormControlLabel'
import SwitchMaterial from '@mui/material/Switch'
import React from 'react'

import classes from './Switch.module.css'

const Checkbox = props => {
  const {
    checked = false,
    value = '',
    label = '',
    onChange = () => {},
    disabled = false,
    name,
  } = props

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <SwitchMaterial
            checked={checked}
            onChange={e => {
              onChange(name, e.target.checked, e.target.value)
            }}
            value={value}
            color="primary"
            disabled={disabled}
          />
        }
        label={label}
        classes={{
          label: classes.label,
        }}
      />
    </div>
  )
}

export default Checkbox
