import { PageBuilderText } from '@chipinside/pagebuilder'
import { useTheme } from '@emotion/react'
import { styled } from '@mui/material/styles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { debounce, merge, uniqueId } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import NewCharts from '#/components/NewCharts'
import { HighchartsTheme } from '#/components/NewCharts/highchartsTheme'
import { HighchartsTranslate } from '#/components/NewCharts/translates'
import { PageBuilderColors } from '#/components/PageBuilder/Components/Charts/PageBuilderColors'
import { BarOptions } from '#/components/PageBuilder/Components/Charts/Types/Bar'
import { CircleOptions } from '#/components/PageBuilder/Components/Charts/Types/Circle'
import { GaugeOptions } from '#/components/PageBuilder/Components/Charts/Types/Gauge'
import { VariablePieOptions } from '#/components/PageBuilder/Components/Charts/Types/VariablePie'

import 'highcharts/highcharts-more'
import 'highcharts/modules/solid-gauge'
import 'highcharts/modules/variable-pie'

Highcharts.setOptions(HighchartsTranslate)
Highcharts.theme = HighchartsTheme()
Highcharts.setOptions(Highcharts.theme)

const Content = styled(`div`)({
  padding: '0',
})

const StyledLink = styled(Link)({
  borderRadius: '8px',
  display: 'block',
})

function addSeriesColor(options) {
  const series = options.series.map(serie => {
    const data = serie.data.map(value => ({
      ...value,
      color: PageBuilderColors[value.id],
    }))

    return { ...serie, data }
  })

  return { ...options, series }
}

function useForceUpdate() {
  const [_, setValue] = useState(0)
  return () => setValue(prevState => prevState + 1)
}

const Wrapper = ({ link, children }) => {
  if (link) {
    return <StyledLink to={link}>{children}</StyledLink>
  }
  return children
}

export function PageBuilderCharts(props) {
  const {
    options: { type, ...receivedOptions },
    label,
    id,
    link,
  } = props

  const theme = useTheme()

  const ref = useRef({})
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    Highcharts.setOptions(HighchartsTranslate)
    Highcharts.theme = HighchartsTheme(theme.palette.mode)
    Highcharts.setOptions(Highcharts.theme)
  }, [theme])

  useEffect(() => {
    window.addEventListener('resize', debounce(forceUpdate, 500))
    return () => {
      window.removeEventListener('resize', forceUpdate)
    }
  }, [])

  if (type === 'chart') {
    return <NewCharts {...props} xAxisFormat="MMM/YYYY" />
  }

  const graphOptions = {
    gauge: GaugeOptions,
    bar: BarOptions,
    circle: CircleOptions,
    variablepie: VariablePieOptions,
  }

  if (receivedOptions) {
    merge(receivedOptions, graphOptions[type])

    return (
      <Wrapper link={link}>
        <Content id={id}>
          <HighchartsReact
            ref={ref}
            key={uniqueId(id)}
            options={addSeriesColor(receivedOptions)}
            highcharts={Highcharts}
          />
          {label && (
            <PageBuilderText
              message={label}
              position="center"
              sx={{
                color: '#666',
                whiteSpace: 'nowrap',
                marginTop: '10px',
              }}
            />
          )}
        </Content>
      </Wrapper>
    )
  }
}
