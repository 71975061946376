import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import Colors from '#/styles/Old/Colors'

const useStyles = makeStyles(() => ({
  container: {
    'display': 'inline-flex',
    'position': 'relative',
    'alignItems': 'center',
    '&:hover .qtip-dialog': {
      opacity: 1,
      pointerEvents: 'auto',
      zIndex: 1,
    },
    '&:hover .qtip-icon': {
      color: Colors.help,
      zIndex: 1,
    },
  },
  icon: {
    color: Colors.textSecondary,
    transition: 'color 150ms',
  },
  dialog: {
    minHeight: '34px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 24px',
    backgroundColor: '#fff',
    position: 'absolute',
    boxSizing: 'border-box',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 150ms',
  },
  topLeft: { top: '-6px', left: '-6px', paddingLeft: '38px' },
  topRight: { top: '-6px', right: '-6px', paddingRight: '38px' },
  bottomLeft: { bottom: '-6px', left: '-6px', paddingLeft: '38px' },
  bottomRight: { bottom: '-6px', right: '-6px', paddingRight: '38px' },
}))

const QuickInfo = ({
  title,
  children,
  topRight,
  bottomLeft,
  bottomRight,
  width = 350,
}) => {
  const classes = useStyles()
  let anchor = 'topLeft'

  if (topRight || bottomLeft || bottomRight) {
    if (topRight) anchor = 'topRight'
    if (bottomLeft) anchor = 'bottomLeft'
    if (bottomRight) anchor = 'bottomRight'
  }

  return (
    <div className={classes.container}>
      <Paper
        style={{ width }}
        className={['qtip-dialog', classes.dialog, classes[anchor]].join(' ')}
      >
        <span
          style={{
            marginBottom: '8px',
            color: Colors.primary,
            fontWeight: 'bold',
          }}
        >
          {title}
        </span>
        {children}
      </Paper>
      <InfoOutlinedIcon className={['qtip-icon', classes.icon].join(' ')} />
    </div>
  )
}

export default QuickInfo
