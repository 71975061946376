import { fetcher } from '@chipinside/fetcher'
import { fmt, Moment, Translate } from '@chipinside/frontend'
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined'
import { Button, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { endpoint } from '#/store/services/endpoints'

export default function ViewAll({
  timestamp,
  refresh,
  filters = [],
  enabled,
  style,
}) {
  const farmSlug = useSelector(getCurrentFarmSlug, isEqual)

  const handleMarkAllAsRead = () => {
    fetcher({
      params: {
        filters,
        timestamp: Moment(timestamp).format(fmt.api),
      },
      controller: endpoint.farm.alert.view_all({ farm: farmSlug }),
      method: 'put',
    }).then(() => refresh())
  }

  return enabled ? (
    <Button
      style={style}
      variant="outlined"
      color="secondary"
      size="small"
      startIcon={<DraftsOutlinedIcon />}
      onClick={handleMarkAllAsRead}
    >
      <Typography>{Translate({ messageKey: 'check_all_as_read' })}</Typography>
    </Button>
  ) : null
}
