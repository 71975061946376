import { CreateFields, getGroupDefaultValues } from '@chipinside/formbuilder'
import { Grid, Translate } from '@chipinside/frontend'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFieldArray } from 'react-hook-form'

const StyledButton = styled(Button)({
  'backgroundColor': 'color-mix(in srgb, currentColor 5%, transparent)',
  'padding': '0 15px',
  '&:hover': {
    backgroundColor: 'color-mix(in srgb, currentColor 15%, transparent)',
  },
})

function Footer(props) {
  const {
    count,
    min_entries,
    max_entries,
    line_button_add_text,
    line_button_remove_text,
    onAdd,
    onRemove,
  } = props
  // Remove botões quando min e max são iguais (não pode editar o numero de linhas)
  if (min_entries === max_entries) return null

  return (
    <Box mt={2} gap={1} display="flex">
      <StyledButton
        size="small"
        onClick={onAdd}
        disabled={count === max_entries}
      >
        {line_button_add_text ||
          Translate({
            messageKey: 'add_item',
            params: { item: Translate({ messageKey: 'row' }) },
          })}
      </StyledButton>
      <StyledButton
        size="small"
        color="error"
        onClick={() => onRemove(count - 1)}
        disabled={count === min_entries}
        style={{ display: 'flex', gap: 5 }}
      >
        <DeleteOutlineIcon />
        {line_button_remove_text || Translate({ messageKey: 'remove_row' })}
      </StyledButton>
    </Box>
  )
}

export function FormBuilderGroup(props) {
  const { fields, name, label, form, line_separator } = props

  const {
    fields: arrayFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name,
  })

  const handleAdd = () => append(getGroupDefaultValues(fields, 1) ?? {})
  const handleRemove = index => remove(index)

  return (
    <Box>
      {label && (
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
      )}

      <Stack rowGap={2} divider={line_separator ? <Divider flexItem /> : null}>
        {arrayFields.map((item, i) => (
          <Grid
            container
            spacing={2}
            key={item.id}
            sx={{
              'borderLeft': 3,
              'pl': 3,
              'py': 2,
              'borderColor': '#c1c1c1',
              '&:empty': {
                display: 'none',
              },
              // Ajuste de css para quando tem botão de remover linha
              ...(arrayFields.length > props.min_entries && { pr: 5 }),
            }}
          >
            <CreateFields
              fields={fields.map(f => ({
                ...f,
                name: `${name}[${i}].${f.name}`,
                label: f.label?.replace('{row}', i + 1),
              }))}
              setValues={props.setValues}
              form={props.form}
              formEvents={props.formevents}
            />
            {arrayFields.length > props.min_entries ? (
              <Box
                size={1}
                style={{
                  position: 'absolute',
                  right: -5,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <Tooltip title={Translate({ messageKey: 'remove_row' })}>
                  <IconButton
                    size="small"
                    aria-label="delete"
                    color="error"
                    onClick={() => handleRemove(i)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : null}
          </Grid>
        ))}
      </Stack>
      <Footer
        {...props}
        onAdd={handleAdd}
        onRemove={handleRemove}
        count={arrayFields.length}
      />
    </Box>
  )
}
