import { Moment, Translate } from '@chipinside/frontend'
import { mdiWeatherWindy } from '@mdi/js'
import Icon from '@mdi/react'
import { Box, Tooltip } from '@mui/material'
import React from 'react'

import Colors from '#/styles/Colors'

const style = {
  root: {
    display: 'inline-flex',
  },
  text: {
    color: Colors.animal.breathing.backgroundColor,
  },
  icon: {
    color: Colors.animal.breathing.backgroundColor,
    height: 20,
  },
}

export default function TypeBreathingIndicator({ value, with_text }) {
  if (!value) return null

  const { last_event, text } = value

  let updated = null
  if (last_event) {
    updated = (
      <div style={style.updated_at}>
        {Translate({
          messageKey: ['updated_at', Moment.view(last_event)],
          translate: [true, false],
        })}
      </div>
    )
  }

  const tooltipTitle = (
    <>
      <span style={style.text}>
        {Translate({ messageKey: 'breathing_status_changed' })}
      </span>
      {updated}
    </>
  )

  return (
    <Tooltip title={tooltipTitle} arrow>
      <Box style={{ display: 'flex', gap: 3 }}>
        {with_text && <span style={style.text}>{text}</span>}
        <Icon path={mdiWeatherWindy} style={style.icon} />
      </Box>
    </Tooltip>
  )
}
