import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const TypeCComCoverage = ({ value }) => {
  if (!value) return '-'

  let formattedValue = value
  let style = Colors.feedback.warning

  if (value === '0 %') {
    formattedValue = 'Offline'
    style = Colors.feedback.error
  }
  if (value === '100 %') {
    formattedValue = 'Online'
    style = Colors.feedback.success
  }

  return <CowmedChip value={formattedValue} customStyle={style} />
}

export default TypeCComCoverage
