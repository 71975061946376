import { Translate } from '@chipinside/frontend'

import Colors from '#/styles/Old/Colors'

function legendClick(item, type) {
  item.chart.container.querySelectorAll(`.plot-line-${type}`).forEach(i => {
    const action = item.visible ? 'add' : 'remove'
    if (i.nodeName === 'DIV' && i.parentNode.nodeName === 'SPAN') {
      i.parentNode.classList[action]('hidden-plot')
    } else {
      i.classList[action]('hidden-plot')
    }
  })
}

export default function PlotLegend(plotTypes, plotLinesKeys) {
  const seriesArray = []
  /* Add plot lines events in legend*/
  if (plotTypes && Array.isArray(plotTypes)) {
    plotTypes.forEach(item => {
      switch (item) {
        case 'animal_events':
          seriesArray.push({
            name: Translate({ messageKey: 'handling_events' }),
            type: 'area',
            states: { hover: { enabled: false } },
            color: Colors.handling_general,
            events: {
              legendItemClick() {
                legendClick(this, 'handling')
              },
            },
          })
          seriesArray.push({
            name: Translate({
              messageKey: 'event_of_plural',
              params: {
                item: Translate({ messageKey: 'health', lower: true }),
              },
            }),
            type: 'area',
            states: { hover: { enabled: false } },
            color: Colors.health_observation_alert,
            events: {
              legendItemClick() {
                legendClick(this, 'health')
              },
            },
          })
          seriesArray.push({
            name: Translate({ messageKey: 'reproduction_event_plural' }),
            type: 'area',
            color: Colors.reproduction_heat,
            events: {
              legendItemClick() {
                legendClick(this, 'reproduction')
              },
            },
          })
          break

        case 'diets':
          seriesArray.push({
            name:
              plotLinesKeys && plotLinesKeys[item]
                ? Translate({ messageKey: 'diet_handling' })
                : Translate({ messageKey: 'diet_label' }),
            type: 'area',
            color: Colors.diet,
            events: {
              legendItemClick() {
                legendClick(this, 'diet')
              },
            },
          })
          break

        case 'events':
          seriesArray.push({
            name: Translate({ messageKey: 'handling_events' }),
            type: 'area',
            states: { hover: { enabled: false } },
            color: Colors.handling_general,
            events: {
              legendItemClick() {
                legendClick(this, 'batch-handling')
              },
            },
          })
          break

        case 'milk_qualities':
          seriesArray.push({
            name: Translate({ messageKey: 'milk_quality' }),
            type: 'area',
            color: Colors.milk_quality,
            events: {
              legendItemClick() {
                legendClick(this, 'milk-quality')
              },
            },
          })
          break

        default: //nothing to moo
      }
    })
  }

  return seriesArray
}
