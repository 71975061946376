import { Moment } from '@chipinside/frontend'
import { styled } from '@mui/material/styles'
import React from 'react'

import Colors from '#/styles/Colors'

const Wrapper = styled('div')(({ bold, level }) => ({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  fontWeight: bold ? 700 : 400,
  color: Colors.feedback[level]?.color,
}))

const TypeDate = ({ value, customStyle, level, bold }) => {
  if (!value) return <span>-</span>
  return (
    <Wrapper style={customStyle} bold={bold} level={level}>
      {Moment(value).format('L')}
    </Wrapper>
  )
}

export default TypeDate
