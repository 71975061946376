import { ChartBuilder } from '@chipinside/chartbuilder'
import { ChartBuilderProps } from '@chipinside/chartbuilder/dist/types'
import { PageBuilderProviderValue } from '@chipinside/pagebuilder'
import {
  ElementDatagrid,
  ElementGroup,
} from '@chipinside/pagebuilder/dist/types'

import { PageBuilderActions } from '#/components/PageBuilder/Components/Actions'
import { PageBuilderCard } from '#/components/PageBuilder/Components/Card'
import { PageBuilderCharts } from '#/components/PageBuilder/Components/Charts'
import { PageBuilderDatagrid } from '#/components/PageBuilder/Components/Datagrid'
import { PageBuilderFilters } from '#/components/PageBuilder/Components/Filters'
import { PageBuilderTabs } from '#/components/PageBuilder/Components/Tabs'

/* prettier-ignore */
const PageBuilderComponents: PageBuilderProviderValue['components'] = {
  'tab':                  (props)                  => <PageBuilderTabs       {...props} />,
  'card':                 (props)                  => <PageBuilderCard       {...props} />,
  'datagrid':             (props)                  => <PageBuilderDatagrid   {...props as ElementDatagrid} />,
  'dashboard-chart':      (props)                  => <PageBuilderCharts     {...props} />,
  'chart':                (props)                  => <ChartBuilder          {...props as ChartBuilderProps} />,
  'filters':              (props)                  => <PageBuilderFilters    {...props} />,
  'actions':              (props)                  => <PageBuilderActions    {...props as ElementGroup} />
}

export { PageBuilderComponents }
