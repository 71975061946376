const styles = {
  labelContainer: {
    position: 'relative',
    cursor: 'help',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  icon: {
    fontSize: '2em',
    borderRadius: 25,
    background: '#fff',
  },
  name: {
    padding: '2px 8px',
    borderRadius: 25,
    fontSize: '1em',
    color: '#fff',
  },
}

export default styles
