import { Moment } from '@chipinside/frontend'
import { styled } from '@mui/material/styles'
import React from 'react'

import { monitoringVerifyRecentData } from '#/components/reportStatus/MonitoringStatus'
import Colors from '#/styles/Colors'
import Fonts from '#/styles/Fonts'

const StyledVariation = styled('div')(({ color }) => ({
  display: 'flex',
  fontSize: '14px',
  fontWeight: Fonts.fontWeight.medium,
  color,
}))

const getValue = (value, reverse = false) => {
  if (typeof value !== 'string') return null

  const valueNumber = +value

  if (isNaN(valueNumber)) return null

  return reverse ? -valueNumber : valueNumber
}

const getColor = (value, reverse) => {
  const formattedValue = getValue(value, reverse)

  if (formattedValue < 0) {
    return Colors.variation.deteriorate.color
  }
  if (formattedValue > 0) {
    return Colors.variation.improve.color
  }

  return null
}

const TypeVariation = ({ value, timestamp, reverse }) => {
  if (!value) return <span>-</span>

  let color = getColor(value, reverse)

  if (timestamp) {
    let lastRead = Moment.diff('now', timestamp, 'h')
    color = lastRead < 48 ? getColor(value, reverse) : null

    return (
      <StyledVariation color={color}>
        {monitoringVerifyRecentData({
          value,
          timestamp,
          withMinutesToHours: false,
        })}
      </StyledVariation>
    )
  }
  return <StyledVariation color={color}>{value}</StyledVariation>
}

export default TypeVariation
