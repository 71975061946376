/**
 * Função de manipulação do eixo Y
 *
 * @param yAxis Recebe eixo Y
 * @return Retorna eixoY sem linhas de fundo e com tickInterval
 */
export default function yAxisFormatter(yAxis, plotBandsColors) {
  return yAxis.map(y => ({
    ...y,
    gridLineWidth: 0,
    startOnTick: false,
    endOnTick: false,
    plotBands:
      'plotBands' in y && plotBandsColors
        ? plotBandsColor(y.plotBands, plotBandsColors)
        : null,
  }))
}

/*
 * Função adiciona cor a plotbands
 */
function plotBandsColor(y, plotBandsColors) {
  let plots = []
  for (let [key, value] of Object.entries(y)) {
    if (plotBandsColors && plotBandsColors[key]) {
      plots.push({ ...value, ...plotBandsColors[key], zIndex: 0 })
    } else {
      plots.push(value)
    }
  }
  return plots
}
