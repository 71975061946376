import { Search } from '@chipinside/frontend'
import { withStyles } from '@mui/styles'
import { debounce } from 'lodash'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import React, { PureComponent } from 'react'

import BooleanFilters from '#/components/datagrid/filters/BooleanFilters'
import DateRangePickerWrapper from '#/components/datagrid/filters/DateRangeFilters'
import SelectFilters from '#/components/datagrid/filters/SelectFilters'
import SwitchFilters from '#/components/datagrid/filters/SwitchFilters'

import styles from './styles'

class DatagridFiltersContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.onChangeFilterSelect = this.onChangeFilterSelect.bind(this)
    this.onChangeFilterBoolean = this.onChangeFilterBoolean.bind(this)
    this.onChangeFilterSwitch = this.onChangeFilterSwitch.bind(this)
    this.onChangeFilterDate = this.onChangeFilterDate.bind(this)
    this.onChangeFilter = this.onChangeFilter.bind(this)
    this.onClickFilterDate = this.onClickFilterDate.bind(this)

    this.state = {
      selectFilters: [],
      booleanFilters: [],
      switchFilters: [],
      dateFilters: [],
      searchValue: props.searchValue,
    }
  }

  componentDidMount() {
    this.prepareFilters()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.prepareFilters()
    }
  }

  prepareFilters() {
    const { filters = [] } = this.props

    const enabledFilters = filters.filter(
      filter => !!filter.enabled && !!filter.visible,
    )

    const selectFilters = Array.isArray(enabledFilters)
      ? enabledFilters.filter(filter => filter.type === 'select')
      : []

    const booleanFilters = Array.isArray(enabledFilters)
      ? enabledFilters.filter(filter => filter.type === 'boolean')
      : []

    const switchFilters = Array.isArray(enabledFilters)
      ? enabledFilters.filter(filter => filter.type === 'switch')
      : []

    const dateFilters = Array.isArray(enabledFilters)
      ? enabledFilters.filter(filter => filter.type === 'date-range')
      : []

    this.setState({
      selectFilters,
      booleanFilters,
      switchFilters,
      dateFilters,
    })
  }

  onChangeFilterSelect(ref, value) {
    const { selectFilters } = this.state
    const filterChanged = selectFilters.find(i => i.name === ref)

    const selectedValue =
      isPlainObject(value) && has(value, 'value') ? value.value : value

    this.setState(
      {
        selectFilters: selectFilters.map(filter => {
          const filterAux = { ...filter }
          if (
            !isEmpty(filterChanged) &&
            filterChanged.name === filterAux.name
          ) {
            filterAux.current = selectedValue
          }
          return filterAux
        }),
      },
      () => {
        this.onChangeFilter()
      },
    )
  }

  onChangeFilterBoolean(ref, value) {
    const { booleanFilters } = this.state
    const filterChanged = booleanFilters.find(i => i.name === ref)

    const newValues = booleanFilters.map(filter => {
      const filterAux = { ...filter }
      if (!isEmpty(filterChanged) && filterChanged.name === filterAux.name) {
        filterAux.current = value
      }
      return filterAux
    })

    this.setState(
      {
        booleanFilters: newValues,
      },
      () => {
        this.onChangeFilter()
      },
    )
  }

  onChangeFilterSwitch(ref, value) {
    const { switchFilters } = this.state
    const { multiOrderChanged = () => {} } = this.props
    const filterChanged = switchFilters.find(i => i.name === ref)

    this.setState(
      {
        switchFilters: switchFilters.map(filter => {
          const filterAux = { ...filter }
          if (
            !isEmpty(filterChanged) &&
            filterChanged.name === filterAux.name
          ) {
            filterAux.current = value
          }
          return filterAux
        }),
      },
      () => {
        if (ref === 'multiOrder') {
          multiOrderChanged(
            this.state.switchFilters.find(i => i.name === ref).current,
          )
        }
      },
    )
  }

  onChangeFilterDate(ref, value) {
    const { dateFilters } = this.state
    const filterChanged = dateFilters.find(i => i.name === ref)

    this.setState(
      {
        dateFilters: dateFilters.map(filter => {
          const filterAux = { ...filter }
          if (
            !isEmpty(filterChanged) &&
            filterChanged.name === filterAux.name
          ) {
            const from = get(value, 'from', undefined)
            const to = get(value, 'to', undefined)

            if (from && to) {
              filterAux.current = {}
              filterAux.current.from = from
              filterAux.current.to = to
            } else {
              filterAux.current = null
            }
          }
          return filterAux
        }),
      },
      () => {
        this.onChangeFilter()
      },
    )
  }

  onClickFilterDate(ref) {
    const { dateFilters } = this.state
    const filterChanged = dateFilters.find(i => i.name === ref)
    this.setState({
      dateFilters: dateFilters.map(filter => {
        const filterAux = { ...filter }
        if (!isEmpty(filterChanged) && filterChanged.name === filterAux.name)
          filterAux.open = !filterAux.open
        return filterAux
      }),
    })
  }

  onChangeFilter() {
    const { selectFilters, booleanFilters, dateFilters } = this.state
    const { filtersChanged = () => {} } = this.props
    const filtersAsParams = [...selectFilters, ...booleanFilters]
      .filter(({ current }) => current)
      .map(({ name, current }) => ({ name, value: current }))

    const dateRangeFilters = [...dateFilters]
      .filter(
        filter =>
          !isNil(filter.current) &&
          !isNil(filter.current.from) &&
          !isNil(filter.current.to),
      )
      .map(filter => ({ name: filter.name, value: filter.current }))

    filtersChanged([...filtersAsParams, ...dateRangeFilters])
  }

  render() {
    const {
      selectFilters,
      booleanFilters,
      switchFilters,
      dateFilters,
      searchValue,
    } = this.state

    const {
      isSearchable,
      onSearchChange,
      containerClass = '',
      containerStyle = {},
      disabled,
      classes,
    } = this.props

    const handleOnChange = val => {
      this.setState(
        { searchValue: val },
        debounce(() => onSearchChange(val), 500),
      )
    }

    return (
      <div
        className={[classes.container, containerClass].join(' ')}
        style={{ ...containerStyle }}
      >
        <div className={classes.containerFilters}>
          {isSearchable ? (
            <Search
              value={searchValue}
              onChange={handleOnChange}
              placeholder=""
            />
          ) : null}
          <DateRangePickerWrapper
            filters={dateFilters}
            onChangeFilter={this.onChangeFilterDate}
            onClick={this.onClickFilterDate}
            disabled={disabled}
          />
          <SelectFilters
            filters={selectFilters}
            onChangeFilter={this.onChangeFilterSelect}
            disabled={disabled}
          />
          <BooleanFilters
            filters={booleanFilters}
            onChangeFilter={this.onChangeFilterBoolean}
            disabled={disabled}
          />
          <SwitchFilters
            filters={switchFilters}
            onChangeFilter={this.onChangeFilterSwitch}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(DatagridFiltersContainer)
