import { Translate } from '@chipinside/frontend'
import sortBy from 'lodash/sortBy'
import sortedUniq from 'lodash/sortedUniq'
import React, { Component } from 'react'

import Button from '#/components/FormControl/Inputs/Button'

import Pagination from '../Pagination'

class DatagridFooterContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,

      rowsPerPage: props.pagination.rowsPerPage,
      rowsPerPageOptions: [15, 30, 60, 100, 200],
      total: 0,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      pagination: {
        firstPage,
        lastPage,
        currentPage,
        rowsPerPage,
        total,
        from,
        to,
      },
    } = props

    let { rowsPerPageOptions } = state

    rowsPerPageOptions = sortedUniq(
      sortBy([...rowsPerPageOptions, rowsPerPage].filter(i => !!i)),
    )

    return {
      firstPage,
      lastPage,
      currentPage,
      rowsPerPage,
      rowsPerPageOptions,
      total,
      from,
      to,
    }
  }

  render() {
    const {
      pageChanged,
      withInfinitePagination,
      rowsPerPageChanged,
      pagination: { rowsPerPage },
      isLoading,
    } = this.props

    const { firstPage, lastPage, currentPage, rowsPerPageOptions, total, to } =
      this.state

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          borderTop: '1px solid #fafafa',
        }}
      >
        {withInfinitePagination ? (
          <React.Fragment>
            {currentPage < lastPage ? (
              <Button
                btnClick={() => {
                  if (currentPage < lastPage) pageChanged(currentPage + 1)
                }}
                btnProps={{
                  disabled: currentPage >= lastPage || isLoading,
                  color: 'primary',
                }}
              >
                {isLoading
                  ? Translate({ messageKey: 'loading_chart_data' })
                  : Translate({ messageKey: 'load_more' })}
              </Button>
            ) : null}
            {!isLoading && (
              <div style={{ padding: 10 }}>
                {Translate({
                  messageKey: 'datagrid_records_info',
                  params: {
                    from: total > 0 ? firstPage + 1 : 0,
                    to,
                    total,
                  },
                })}
              </div>
            )}
          </React.Fragment>
        ) : (
          <Pagination
            firstPage={firstPage}
            lastPage={lastPage}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            total={total}
            onChangePage={pageChanged}
            onChangeRowsPerPage={rowsPerPageChanged}
          />
        )}
      </div>
    )
  }
}

export default DatagridFooterContainer
