export const CircleOptions = {
  title: null,
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    type: 'solidgauge',
    height: '70%',
    spacing: 0,
    margin: 0,
  },
  pane: {
    startAngle: 0,
    endAngle: 360,
    background: [
      {
        backgroundColor: '#eee',
        outerRadius: '100%',
        innerRadius: '60%',
        borderWidth: 0,
      },
    ],
  },
  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: [],
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: true,
        borderWidth: 0,
        align: 'center',
        y: -10,
        formatter() {
          let { chart } = this.series
          let fontSize = Math.min(40, chart.plotWidth / 8)
          return `<span style="font-size:${fontSize}px; color: ${this.point.color}; font-weight: bold">${this.y}%</span>`
        },
      },
    },
  },
}
