import { isNil } from 'lodash'
import React from 'react'

import Palette from '#/styles/Old/Palette'

const style = {
  currentValue: {
    marginRight: 8,
  },
  positiveVariation: {
    color: Palette.green.accent[4],
  },
  negativeVariation: {
    color: Palette.red.accent[4],
  },
}

const TypeMonthlyVariation = ({ value, variation, invertedColors }) => {
  if (isNil(value)) return <span>-</span>
  let variationStyle = {}

  if (variation > 0) {
    variationStyle = invertedColors
      ? style.negativeVariation
      : style.positiveVariation
    variation = `+${variation}`
  }

  if (variation < 0) {
    variationStyle = invertedColors
      ? style.positiveVariation
      : style.negativeVariation
  }

  return (
    <>
      <span style={style.currentValue}>{Number(value)}</span>
      {variation !== 0 && <span style={variationStyle}>{variation}</span>}
    </>
  )
}
export default TypeMonthlyVariation
