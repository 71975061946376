import { fetcher } from '@chipinside/fetcher'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import {
  getCurrentAnimalSlug,
  getCurrentFarmSlug,
  setCurrentAnimal,
} from '#/store/ducks/farm'
import { endpoint } from '#/store/services/endpoints'

const defaultState = {}
const errorDefault = {}

export const loadAnimalStatus = createAsyncThunk(
  'animal/loadAnimalStatus',
  async (payload, thunkAPI) => {
    const { controller, name, params, updateCurrentAnimal } = payload

    if (!isEmpty(controller)) {
      try {
        const { data } = await fetcher({ controller, params })

        // update current animal after done animal status
        if (updateCurrentAnimal) {
          const farmSlug = getCurrentFarmSlug(
            window.globalStore.getState(),
            isEqual,
          )
          const animalSlug = getCurrentAnimalSlug(
            window.globalStore.getState(),
            isEqual,
          )

          const animalController = endpoint.farm.animal.self({
            farm: farmSlug,
            animal: animalSlug,
          })

          const { data } = await fetcher({ controller: animalController })
          thunkAPI.dispatch(setCurrentAnimal(data))
        }

        return { name, data }
      } catch (e) {
        return thunkAPI.rejectWithValue({ name, message: e.message })
      }
    }

    return thunkAPI.rejectWithValue({
      name,
      message: 'Animal Status not found',
    })
  },
)

export const slice = createSlice({
  name: 'animal',
  initialState: defaultState,
  reducers: {
    resetAnimalStatus(state, action) {
      return omit(state, action.payload?.name)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAnimalStatus.pending, (state, action) => {
        state[action?.meta?.arg?.name] = {
          status: 'started',
          error: errorDefault,
          data: state?.[action.meta.arg.name]?.data ?? {},
        }
      })

      .addCase(loadAnimalStatus.fulfilled, (state, action) => {
        state[action.payload?.name] = {
          status: 'done',
          error: errorDefault,
          data: action.payload?.data,
        }
      })

      .addCase(loadAnimalStatus.rejected, (state, action) => {
        state[action.payload?.name] = {
          status: 'failed',
          error: action.payload || {},
          data: state?.[action.payload?.name]?.data,
        }
      })
  },
})

export const {
  actions: { resetAnimalStatus },
} = slice

export default slice.reducer
