import { mdiRefresh } from '@mdi/js'
import Icon from '@mdi/react'
import { IconButton } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

const StyledRefresh = withStyles(() => ({
  root: {
    padding: 6,
    marginLeft: 'auto',
  },
}))(IconButton)

export const Refresh = ({ value, setForceRefresh, handleTabSwitch }) => (
  <StyledRefresh
    onClick={() => {
      setForceRefresh(true)
      handleTabSwitch(null, value)
    }}
  >
    <Icon path={mdiRefresh} size={1} />
  </StyledRefresh>
)
