import { Moment, Translate } from '@chipinside/frontend'
import Paper from '@mui/material/Paper'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import OutputFields from '#/components/OutputFields'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Old/Colors'
import plotToHtml from '#/utils/plotToHtml'

import classes from './PlotLines.module.css'
import styles from './styles'
import { basePlot, getPlotIconHtml, labelTextHandler } from './utils'

const renderHandlingPlot = ({
  slug,
  name,
  title,
  subtitle,
  infos,
  color,
  icon,
}) => {
  return (
    <div className={classes.headerContainer} key={slug}>
      <div style={styles.labelContainer}>
        <span style={styles.icon} dangerouslySetInnerHTML={{ __html: icon }} />
        <span style={{ ...styles.name, backgroundColor: color }}>{name}</span>
      </div>
      <Paper key={`${slug}-popover`} className={classes.popover} elevation={2}>
        <div style={{ fontSize: Fonts.fontSize.M, color }}>{title}</div>
        <div style={{ fontSize: Fonts.fontSize.S, margin: '0.35em 0' }}>
          {subtitle}
        </div>
        <OutputFields direction="row" fields={infos} />
      </Paper>
    </div>
  )
}

export default function dietPlotlines(events, range, plotType) {
  const plots = []

  //joins samples by date   to create single plot
  if (Array.isArray(events) && events.length) {
    events.forEach(diet => {
      const {
        name: dietName,
        slug: dietSlug,
        batch_diet_pivot: { assignment, removal, handling, assignment_user },
      } = diet

      const rangeFrom = Moment(range.from)
      const rangeTo = Moment(range.to)
      const initialDietDate = Moment(assignment)

      switch (plotType) {
        case 'diets':
          {
            const dietTimeSlug = initialDietDate.format('MM-DD-YYYY-HH')

            const plotText = plotToHtml(
              renderHandlingPlot({
                slug: `diet-${dietSlug}-${dietTimeSlug}`,
                name: Translate({ messageKey: 'diet_initials' }),
                title: Translate({ messageKey: 'diet_label' }),
                subtitle: initialDietDate.format('L LT'),
                infos: [
                  {
                    label: Translate({ messageKey: 'diet_label' }),
                    value: dietName,
                  },
                  {
                    label: Translate({ messageKey: 'user' }),
                    value: get(assignment_user, 'name', '-'),
                  },
                ],
                color: Colors.diet,
                icon: getPlotIconHtml('diet'),
              }),
            )

            const plotId = `plot-line-id-${dietSlug}-${dietTimeSlug}`
            const plotClassName = 'plot-line-diet'

            plots.push({
              ...basePlot,
              className: `${plotId} transition-opacity-300ms ${plotClassName}`,
              value: initialDietDate.valueOf(),
              color: Colors.diet,
              label: {
                ...basePlot.label,
                text: labelTextHandler({
                  id: plotId,
                  className: plotClassName,
                  plotTextComponent: plotText,
                }),
              },
            })
          }
          break

        case 'diet-handling':
          if (!isEmpty(handling)) {
            const endingDietDate = Moment(
              removal ? Math.min(Moment(removal), rangeTo) : rangeTo,
            )
            const currentDate = Moment(Math.max(initialDietDate, rangeFrom))
            const dietHandling = handling.filter(d => d.time) // remove handling without data

            let start = new Date(currentDate.valueOf())

            for (
              let d = start;
              Moment.diff(d, endingDietDate, 'h') <= 0;
              d.setDate(d.getDate() + 1)
            ) {
              dietHandling.forEach((hdl, hdlIdx) => {
                const { time, observation: hdlObservation, type } = hdl

                const hdlTime = Moment.diff(
                  Moment.pure(time, 'HH:mm'),
                  Moment().startOf('d'),
                  'm',
                )

                let handlingTime = {
                  current: Moment(d).add(hdlTime, 'm'),
                  initial: Moment(assignment),
                  end: endingDietDate,
                }
                if (
                  !Moment(handlingTime.current).isBetween(
                    handlingTime.initial,
                    handlingTime.end,
                    null,
                    '[]',
                  )
                )
                  return null

                const handlingTimeSlug = currentDate.format('MM-DD-YYYY-HH')

                const plotText = plotToHtml(
                  renderHandlingPlot({
                    slug: `diet-handling-${hdlIdx}-${handlingTimeSlug}`,
                    /* Get the first character of the translated message. */
                    name: Translate({ messageKey: type }).charAt(0),
                    title: Translate({ messageKey: 'diet_handling' }),
                    subtitle: handlingTime.current.format('L LT'),
                    infos: [
                      {
                        label: Translate({ messageKey: 'diet_label' }),
                        value: dietName,
                      },
                      {
                        label: Translate({ messageKey: 'type' }),
                        // type: feed, pushing
                        value: Translate({ messageKey: type }),
                      },
                      {
                        label: Translate({ messageKey: 'user' }),
                        value: get(assignment_user, 'name', '-'),
                      },
                      {
                        label: Translate({ messageKey: 'PS' }),
                        value: hdlObservation,
                      },
                    ],
                    color: Colors.diet_handling,
                    icon: getPlotIconHtml('diet_handling'),
                  }),
                )
                const plotId = `plot-line-id-${dietSlug}-${handlingTimeSlug}`
                const plotClassName = 'plot-line-diet'

                plots.push({
                  ...basePlot,
                  className: `${plotId} transition-opacity-300ms ${plotClassName}`,
                  value: handlingTime.current.valueOf(),
                  color: Colors.diet_handling,
                  label: {
                    ...basePlot.label,
                    text: labelTextHandler({
                      id: plotId,
                      className: plotClassName,
                      plotTextComponent: plotText,
                    }),
                  },
                })
              })
            }
          }
          break
        default:
        //nothing to muuu
      }
    })
    return plots
  }
  return []
}
