import makeStyles from '@mui/styles/makeStyles'

import Palette from '#/styles/Old/Palette'

export default makeStyles(theme => ({
  Card: {
    margin: 15,
    padding: '15px 20px',
    display: 'inline-flex',
    borderRadius: 5,
    boxShadow: `0px 2px 1px -1px ${Palette.transparent[1]}, 0px 1px 1px 0px ${Palette.transparent[1]}, 0px 1px 3px 0px ${Palette.transparent[1]}`,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  List: {
    textTransform: 'uppercase',
    fontSize: '0.875em',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        paddingRight: 20,
        marginRight: 20,
        borderRight: `1px solid ${Palette.grey.lighten[3]}`,
      },
    },
    [theme.breakpoints.down('md')]: {
      '&:not(:last-child)': {
        paddingBottom: 20,
        marginBottom: 15,
        borderBottom: `1px solid ${Palette.grey.lighten[3]}`,
      },
    },
  },
  ListTitle: {
    fontWeight: 500,
    display: 'block',
    margin: 0,
    paddingBottom: 15,
    color: Palette.grey.darken[3],
  },
  ListSection: {
    fontWeight: 500,
    display: 'block',
    margin: 0,
    color: Palette.grey.darken[3],
  },
  ListItem: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'paddingLeft': 0,
    'paddingRight': 0,
    'minWidth': 180,
    '& > p': {
      margin: 0,
    },
  },
  ItemTitle: {
    color: Palette.grey.self,
  },
  ItemValue: {
    fontWeight: 500,
    paddingLeft: 50,
  },
}))
