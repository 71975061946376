import {
  AttentionIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  Translate,
} from '@chipinside/frontend'
import { Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { inRange, isEmpty, isObject } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'

import Button from '#/components/FormControl/Inputs/Button'
import SimpleDialog from '#/components/Modal'
import { saveEvent } from '#/store/ducks/event'
import Palette from '#/styles/Palette'

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const HeaderContent = styled(Wrapper)(({ color }) => ({
  color,
  marginBottom: 16,
  paddingTop: 20,
}))

const TitleStyled = styled(Typography)({
  fontSize: '32px',
  fontWeight: '500',
})

const Message = styled(Typography)({
  color: Palette.gray.dark[900],
  textAlign: 'center',
  fontSize: '16px',
  marginBottom: 16,
})

const RenderDetails = ({ details }) => {
  if (!isEmpty(details)) {
    let formattedDetails = details

    if (!Array.isArray(details)) {
      let values = Object.values(details)
      if (isObject(values) && !Array.isArray(values)) {
        formattedDetails = []
      } else {
        formattedDetails = Object.values(details).flat()
      }
    }

    return (
      <Stack spacing={1}>
        {formattedDetails.map((item, idx) => (
          <Typography key={`detail-${idx}`} component="div" variant="body1">
            {item}
          </Typography>
        ))}
      </Stack>
    )
  }

  return null
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
const getHeaderCustomization = code => {
  let icon = <AttentionIcon fontSize={70} />
  let text = Translate({ messageKey: 'response_error' })
  let color = Palette.gray.self['500']

  // Informational responses
  if (inRange(code, 100, 199)) {
    icon = <InfoIcon fontSize={70} />
    text = Translate({ messageKey: 'response_info' })
    color = Palette.blue.self['400']
  }

  // Successful responses
  if (inRange(code, 200, 299)) {
    icon = <SuccessIcon fontSize={70} />
    text = Translate({ messageKey: 'response_success' })
    color = Palette.green.self['500']
  }

  // Client error responses
  if (inRange(code, 400, 499)) {
    icon = <AttentionIcon fontSize={70} />
    text = Translate({ messageKey: 'response_warning' })
    color = Palette.orange.self['500']
  }

  // Server error responses
  if (inRange(code, 500, 599)) {
    icon = <ErrorIcon fontSize={70} />
    text = Translate({ messageKey: 'response_error' })
    color = Palette.red.light['500']
  }

  return { icon, text, color }
}

const RenderHeader = ({ code }) => {
  const { icon, text, color } = getHeaderCustomization(code)
  return (
    <HeaderContent color={color}>
      {icon}
      <TitleStyled>{text}</TitleStyled>
    </HeaderContent>
  )
}

function ResponseModal(props) {
  const {
    message,
    type,
    code,
    details,
    open,
    action,
    status,
    fullWidth = true,
    closable = true,
    maxWidth = 'sm',
    keepMounted = true,
    withBottomCloseButton = true,
    ...rest
  } = props

  const dispatch = useDispatch()

  if (!open) return null
  let renderAction = null

  if (!isEmpty(action)) {
    renderAction = action

    if (status === 'failed') {
      // just for HooksInputs made with FormBuilder
      const handleConflict = () => {
        if (action.handleAction.retryParams) {
          dispatch(
            saveEvent({
              ...action.handleAction.retryParams,
            }),
          )
        }
      }
      renderAction = (
        <Button asCreate btnClick={handleConflict}>
          {action.label}
        </Button>
      )
    }
  }

  const element = (
    <Wrapper>
      {rest?.renderCustomResponse || (
        <>
          <RenderHeader type={type} code={code} />
          <Message>{message}</Message>
          <RenderDetails details={details} />
        </>
      )}
    </Wrapper>
  )

  return (
    <SimpleDialog
      {...rest}
      maxWidth={maxWidth}
      keepMounted={keepMounted}
      withBottomCloseButton={withBottomCloseButton}
      closable={closable}
      fullWidth={fullWidth}
      open={open}
      element={element}
      action={renderAction}
      autoClose={status === 'done'}
    />
  )
}

export default React.memo(ResponseModal)
