import { ChartBuilderProvider as ChartBuilderProviderPackage } from '@chipinside/chartbuilder'
import { Moment, Translate } from '@chipinside/frontend'
import { isEqual } from 'lodash'
import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { ChartBuilderColors } from '#/store/providers/Chartbuilder/colors'

export function ChartBuilderProvider({ children }: PropsWithChildren) {
  const farmSlug = useSelector(getCurrentFarmSlug, isEqual)

  /* prettier-ignore */
  const formatters = {
    plotLinesSubmenuText:                        item => Translate({ messageKey: item }),
    exportedFileName:                            item => `cowmed_${farmSlug}_${item}`.replaceAll('-', '_'),
    formatPlotLinesGroupTimestamp:               item => Moment(item.timestamp).startOf('h').valueOf(),
    plotLinesDate:                               timestamp => Moment(timestamp).format(Translate({ messageKey: 'date_format_long_timestamped' })),
    plotLineRegisteredDateLabel:                 () => Translate({ messageKey: 'registered_at_date' }),
    plotLineCreatedText:                         () => Translate({ messageKey: 'created_by' }),
  }

  return (
    <ChartBuilderProviderPackage
      colors={ChartBuilderColors}
      formatters={formatters}
    >
      {children}
    </ChartBuilderProviderPackage>
  )
}
