import { useFetch } from '@chipinside/fetcher'
import { Translate } from '@chipinside/frontend'
import InfoIcon from '@mui/icons-material/Info'
import CollarIcon from '@mui/icons-material/Memory'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'

import { DatasheetContent } from '#/components/Datasheets/Animal/content'
import Colors from '#/styles/Old/Colors'

const styles = theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  collarIcon: {
    position: 'absolute',
    right: '-8px',
    top: '-14px',
    transform: 'scale(-1, 1)',
  },
  fabProgress: {
    color: Colors.primary,
    position: 'absolute',
    top: 12,
    left: 12,
    zIndex: 1,
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  icon: {
    color: '#797979',
  },
  iconSuccess: {
    color: Colors.primary,
  },
  iconError: {
    color: Colors.critical,
  },
})

const AnimalStatus = ({ route = '', event = {}, classes }) => {
  const [open, setOpen] = useState(false)
  const { data, error, isLoading } = useFetch({
    controller: open && route ? route : null,
  })

  let iconClass
  if (!isLoading) {
    iconClass = classes.iconSuccess
  } else if (error) {
    iconClass = classes.icon
  } else {
    iconClass = classes.iconError
  }

  return (
    <>
      <div className={classes.wrapper}>
        <IconButton
          aria-label="info"
          onClick={() => setOpen(true)}
          size="large"
        >
          <div className={classes.wrapper}>
            <CollarIcon className={classes.collarIcon} />
            <InfoIcon
              className={iconClass}
              style={{ position: 'absolute', top: '-8px' }}
            />
          </div>
        </IconButton>
        {isLoading && (
          <CircularProgress size={25} className={classes.fabProgress} />
        )}
      </div>
      {open && !isLoading ? (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="xs"
          PaperProps={{ style: { backgroundColor: Colors.darkgrey } }}
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            <div className={classes.title}>
              {[
                Translate({ messageKey: 'datasheet' }),
                event['animal.earring'],
                event['animal.name'],
              ].join(' - ')}
              <Divider />
            </div>
          </DialogTitle>
          {!isEmpty(data) ? (
            <DatasheetContent AnimalData={data} Loading={isLoading} />
          ) : null}
        </Dialog>
      ) : null}
    </>
  )
}

export default withStyles(styles)(AnimalStatus)
