import { isEqual } from 'lodash'
import React from 'react'

import AnimalStatus from '#/components/Datasheets/AnimalStatus'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { endpoint } from '#/store/services/endpoints'

const TypeAnimalStatus = ({ value }) => {
  if (!value) return <span>-</span>

  let controller = endpoint.farm.animal.status({
    farm: getCurrentFarmSlug(window.globalStore.getState(), isEqual),
    animal: value['animal.slug'],
  })
  return <AnimalStatus route={controller} event={value} />
}

export default TypeAnimalStatus
