import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

import hasPermission from '#/utils/hasPermission'

const getPermissionsInContext = (state, permissionsContexts) => {
  const permissions = {}
  Object.keys(permissionsContexts).forEach(context => {
    permissions[context] = {}
    permissionsContexts[context].forEach(permission => {
      permissions[context][camelCase(permission)] = hasPermission(
        state,
        context,
        snakeCase(permission),
      )
    })
  })
  return { permissions }
}

export default getPermissionsInContext
