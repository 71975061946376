import { Translate } from '@chipinside/frontend'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import {
  FallbackMessage,
  FallbackTimer,
  FallbackTitle,
  FallbackWrapper,
} from '#/pages/Fallback/Styles'

const DEFAULT_COUNTDOWN_VALUE_IN_SECONDS = 30

export default function TooManyAttempts() {
  const history = useHistory()

  const [seconds, setSeconds] = useState(DEFAULT_COUNTDOWN_VALUE_IN_SECONDS)

  useEffect(() => {
    // Refresh da tela apenas se a tela está em focus e o timer chegou a zero
    if (seconds <= 0 && document.visibilityState === 'visible') {
      history.go(0)
    }

    let myInterval = setInterval(() => {
      setSeconds(previousValue => previousValue - 1)
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <FallbackWrapper code={429}>
      <FallbackTitle>
        {Translate({ messageKey: '429_fallback_title' })}
      </FallbackTitle>
      <FallbackMessage>
        {Translate({ messageKey: '429_fallback_message' })}
      </FallbackMessage>
      <FallbackTimer>
        {Translate({
          messageKey: 'redirect_timer',
          params: {
            time: seconds > 0 ? seconds : 0,
            unit: Translate({
              messageKey: seconds > 1 ? 'time_second_plural' : 'time_second',
              lower: true,
            }),
          },
        })}
      </FallbackTimer>
    </FallbackWrapper>
  )
}
