import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage/index'

import animalReducer from './ducks/animal'
import appReducer from './ducks/app'
import authReducer from './ducks/auth'
import batchReducer from './ducks/batch'
import companyReducer from './ducks/company'
import datagridReducer from './ducks/datagrid'
import dialogReducer from './ducks/dialog'
import errorReducer from './ducks/error'
import eventReducer from './ducks/event'
import farmReducer from './ducks/farm'
import formReducer from './ducks/form'
import thiReducer from './ducks/thi'

const DEBUG = import.meta.env.VITE_DEBUG === 'true'

const rootPersistConfig = {
  storage,
  key: 'root',
  stateReconciler: autoMergeLevel2,
  debug: DEBUG,
  whitelist: ['app', 'auth', 'farm'],
}

export const rootReducers = combineReducers({
  animal: animalReducer,
  app: appReducer,
  auth: authReducer,
  batch: batchReducer,
  company: companyReducer,
  datagrid: datagridReducer,
  dialog: dialogReducer,
  error: errorReducer,
  event: eventReducer,
  farm: farmReducer,
  form: formReducer,
  thi: thiReducer,
})

export default persistReducer(rootPersistConfig, rootReducers)
