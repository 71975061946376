import { PerfilIcon, Translate } from '@chipinside/frontend'
import { Button, ListItemButton } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getUserInfo, login } from '#/store/ducks/auth'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { usePermissions } from '#/store/hooks/permissions'
import Colors from '#/styles/Colors'
import Fonts from '#/styles/Fonts'
import OldColors from '#/styles/Old/Colors'

const StyledButton = styled(Button)({
  marginTop: 20,
  marginBottom: 10,
  padding: '5px 15px',
  textTransform: 'none',
  fontWeight: 400,
  minHeight: 'unset',
  color: 'black',
})

const usePopoverStyles = makeStyles(() => ({
  paper: {
    flexGrow: 1,
    minWidth: 330,
  },
}))

const useStyles = makeStyles(() => ({
  listFarms: {
    color: Colors.primary,
    fontWeight: Fonts.fontWeight.medium,
    fontSize: '1em',
  },
  logout: {
    color: Colors.danger,
    fontWeight: Fonts.fontWeight.medium,
    fontSize: '1em',
  },
  avatar: {
    color: '#fff',
    fontSize: Fonts.fontSize.XXL,
    backgroundColor: Colors.primary,
  },
  link: {
    '&:hover': {
      color: `${OldColors.textPrimary} !important`,
    },
  },
}))

export default function UserMenu() {
  const { name, email, has_company, farm_count } = useSelector(
    getUserInfo,
    isEqual,
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const popoverClasses = usePopoverStyles()
  const classes = useStyles()
  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const [disconnect, setDisconnect] = useState(false)
  const { permissions } = usePermissions({
    farm: ['view_config_farm'],
  })

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClickLink = link => {
    handleClose()
    history.push(link)
  }

  useEffect(() => {
    if (!name && !disconnect) {
      dispatch(login({ refreshUserInfo: true }))
    }
  }, [disconnect, dispatch, name])

  return name ? (
    <React.Fragment>
      <div>
        <IconButton onClick={handleClick} size="large">
          <PerfilIcon />
        </IconButton>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={popoverClasses}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 15,
          }}
        >
          <Avatar className={classes.avatar}>{name.charAt(0)}</Avatar>
          <Typography>{name}</Typography>
          <Typography color="textSecondary">{email}</Typography>
          <StyledButton
            color="secondary"
            variant="outlined"
            onClick={() => handleClickLink('/user/settings')}
          >
            {Translate({ messageKey: 'manage_account_and_password' })}
          </StyledButton>
        </div>
        <Divider />
        <List
          style={{
            overflowY: 'auto',
            maxHeight: '70vh',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <React.Fragment>
            <Link
              href="https://ajuda.cowmed.com.br"
              target="_blank"
              color="inherit"
              rel="noopener"
              underline="none"
              classes={{ underlineNone: classes.link }}
            >
              <ListItemButton>
                <ListItemText
                  primary={Translate({ messageKey: 'training_plural' })}
                />
              </ListItemButton>
            </Link>
            <Divider />
          </React.Fragment>
          {currentFarmSlug && permissions.farm.viewConfigFarm && (
            <React.Fragment>
              <ListItemButton
                onClick={() =>
                  handleClickLink(`/farm/${currentFarmSlug}/settings`)
                }
              >
                <ListItemText
                  primary={Translate({ messageKey: 'farm_settings' })}
                />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          )}
          {(has_company || farm_count > 0) && currentFarmSlug && (
            <React.Fragment>
              <ListItemButton onClick={() => handleClickLink('/')}>
                <ListItemText
                  primary={Translate({ messageKey: 'farm_list' })}
                  classes={{ primary: classes.listFarms }}
                />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          )}
          <React.Fragment>
            <ListItemButton
              onClick={() => {
                setDisconnect(true)
                handleClickLink('/logout')
              }}
            >
              <ListItemText
                primary={Translate({ messageKey: 'logout' })}
                classes={{ primary: classes.logout }}
              />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        </List>
      </Popover>
    </React.Fragment>
  ) : null
}
