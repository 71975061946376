import { styled } from '@mui/material/styles'
import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const HealthChip = styled(CowmedChip)(({ status }) => ({
  backgroundColor: `${Colors.health[status]?.backgroundColor} !important`,
  color: `${Colors.health[status]?.color} !important`,
}))

/**
 * TypeHealth component that renders a HealthBadge based on the `value` property.
 * @param {Object} props - The component's properties.
 * @param {Object} props.value - Object containing properties status (string) and text (string).
 * @param {Object} props.rest - Other properties that can be forwarded.
 * @returns {React.Element} - Returns HealthBadge or '-'.
 */
const TypeHealth = ({ value }) => {
  if (!value) return '-'

  return <HealthChip value={value.text} status={value.status} />
}

export default TypeHealth
