import { Box, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { withTour } from '@reactour/tour'
import { isSupported } from 'firebase/messaging'
import { isEqual } from 'lodash'
import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'

import RevalidateVersion from '#/components/cards/RevalidateVersion'
import Copyright from '#/components/Copyright'
import Flex from '#/components/Flex'
import FloatActions from '#/components/FloatActions'
import Layout from '#/components/Layout'
import {
  getFirebaseInfo,
  getFirebaseToken,
  getUserInfo,
  login,
} from '#/store/ducks/auth'
import { withSystem } from '#/store/hocs/withSystem'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Old/Colors'
import { CypressAlert } from '#/utils/cypressAlert'
import lazyRetry from '#/utils/lazyRetry'
import { isLocal } from '#/utils/processEnvironment'
import ws from '#/utils/websocket'

const Logout = React.lazy(() => lazyRetry(() => import('../pages/Auth/Logout')))

// Private Routes
const Access = React.lazy(() => lazyRetry(() => import('../pages/Access')))
const DatabaseRoutes = React.lazy(() =>
  lazyRetry(() => import('../pages/System/Database/routes')),
)
const CSRoutes = React.lazy(() =>
  lazyRetry(() => import('../pages/System/CS/routes')),
)
const DataViewRoutes = React.lazy(() =>
  lazyRetry(() => import('../pages/System/DataView/routes')),
)
const AccountsRoutes = React.lazy(() =>
  lazyRetry(() => import('../pages/System/Accounts/routes')),
)
const UserRoutes = React.lazy(() =>
  lazyRetry(() => import('../pages/User/routes')),
)
const FarmRoutes = React.lazy(() =>
  lazyRetry(() => import('../pages/Farm/routes')),
)
const SystemRoutes = React.lazy(() =>
  lazyRetry(() => import('../pages/System/routes')),
)

// Fallback Pages
const NotFound = React.lazy(() =>
  lazyRetry(() => import('../pages/Fallback/NotFound')),
)
const MaintenanceMode = React.lazy(() =>
  lazyRetry(() => import('../pages/Fallback/MaintenanceMode')),
)
const SuspendedAccountMode = React.lazy(() =>
  lazyRetry(() => import('../pages/Fallback/SuspendedAccountMode')),
)

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    padding: '0 15px',
    overflow: 'auto',
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 240,
    },
  },
  content: {
    flex: 1,
    width: '100%',
    margin: 'auto',
  },
  footer: {
    alignSelf: 'center',
    marginTop: 'auto',
    padding: 5,
    fontSize: Fonts.fontSize.SSS,
    color: Colors.chipGray,
  },
}))

const ROUTES = [
  {
    path: '/logout',
    name: 'logout-route',
    exact: true,
    component: Logout,
  },
  {
    path: '/',
    name: 'access-route',
    exact: true,
    protected: true,
    component: Access,
  },
  {
    path: '/accounts',
    name: 'accounts-routes',
    protected: true,
    component: AccountsRoutes,
  },
  {
    path: '/cs',
    name: 'cs-routes',
    protected: true,
    component: CSRoutes,
  },
  {
    path: '/database',
    name: 'database-routes',
    protected: true,
    component: DatabaseRoutes,
  },
  {
    path: '/data-view',
    name: 'data-view-routes',
    protected: true,
    component: DataViewRoutes,
  },
  {
    path: '/farm/:farmSlug',
    name: 'farm-route',
    protected: true,
    component: FarmRoutes,
  },
  {
    path: '/user',
    name: 'user-route',
    protected: true,
    component: UserRoutes,
  },
  {
    path: '/system',
    name: 'system-routes',
    protected: true,
    component: SystemRoutes,
  },
  {
    path: '/404',
    name: 'not-found',
    protected: true,
    component: NotFound,
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: MaintenanceMode,
  },
  {
    path: '/suspended-account',
    name: 'suspended-account',
    component: SuspendedAccountMode,
  },
]

/**
 * PrivateRoute Component
 *   A wrapper for <Route> that redirects to the login
 *   screen if you're not yet authenticated.
 *   Also, it uses withSystem HOC to revalidate user permissions
 *   and app/api versions.
 * @param {Object} component: <typeof ReactComponent>
 * @param {Object} rest: <typeof ReactComponentProps>
 * @returns {Page} Renders current page component
 */
export const PrivateRoute = withSystem(({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { wrapper, content, footer } = useStyles()
  const { firebaseToken, messagingToken } = useSelector(
    getFirebaseInfo,
    isEqual,
  )
  const { isSuspended } = useSelector(getUserInfo, isEqual)

  useEffect(() => {
    if (isSuspended) {
      history.push('/suspended-account')
    }
  }, [])

  /* Getting the firebase token from the backend and storing it in the redux store. */
  useEffect(() => {
    isSupported().then(hasFirebaseMessagingSupport => {
      if (
        !import.meta.env.VITE_FIREBASE_DISABLE &&
        hasFirebaseMessagingSupport &&
        !firebaseToken &&
        !messagingToken
      ) {
        dispatch(getFirebaseToken())
      }
    })
  }, [firebaseToken, messagingToken])

  // echo websocket connection
  useEffect(() => {
    if (!window.Echo?.socketId()) {
      ws.connect()
    }
  }, [])

  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Layout />
          <Suspense
            fallback={
              <Flex>
                <CircularProgress />
              </Flex>
            }
          >
            <Flex
              className={wrapper}
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box className={content} id="app-content">
                {isLocal && <CypressAlert />}
                <div id="app-content-top-middle" />
                <Component />
              </Box>
              <Box className={footer}>
                <Copyright />
              </Box>
            </Flex>
            <FloatActions />
          </Suspense>
          <RevalidateVersion />
        </>
      )}
    />
  )
})

export const mapRoutes = (routes, portalRef) =>
  routes.map(route => {
    // Authenticated Route
    if (route.protected) {
      return <PrivateRoute ref={portalRef} key={route.name} {...route} />
    }
    // Guest Route
    return <Route key={route.name} {...route} />
  })

const Routes = () => {
  //Get login infos
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    dispatch(login()).then(({ payload }) => {
      if (payload?.isSuspended) {
        history.push('/suspended-account')
      }
    })
  }, [])

  return (
    <Switch>
      {/* Fix Legacy login route */}
      <Route exact path="/login">
        {() =>
          window.location.replace(`${import.meta.env.VITE_AUTH_URL}/login`)
        }
      </Route>
      {/* Fix Legacy access route */}
      <Route exact path="/access">
        {() => window.location.replace('/')}
      </Route>
      {mapRoutes(ROUTES)}
      <Route component={NotFound} />
    </Switch>
  )
}

export default withTour(Routes)
