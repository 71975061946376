import { Box, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

const Wrapper = styled(Box)(({ columns }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gridColumnGap: 20,
  gridRowGap: 10,
}))

export default function ListLoader({ lines, columns, ...rest }) {
  return (
    <Wrapper columns={columns} {...rest}>
      {[...Array(columns).keys()].map(() =>
        [...Array(lines).keys()].map(b => (
          <Box key={b} mb={2}>
            <Skeleton height={35} />
            <Skeleton width="60%" />
            <Skeleton width="70%" />
            <Skeleton width="50%" />
            <Skeleton width="75%" />
          </Box>
        )),
      )}
    </Wrapper>
  )
}
