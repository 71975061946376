import { fetcher } from '@chipinside/fetcher'
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { IconButton, Tooltip } from '@mui/material'
import { isEmpty } from 'lodash'
import { useState } from 'react'

import ExportFile from '#/components/ExportFile'

const TypeAttachments = ({ value }) => {
  const [fileController, setFileController] = useState(null)
  const { filename, url } = value ?? {}

  if (isEmpty(value)) return '-'

  function handleGetFile(controller) {
    fetcher({ controller })
      .then(response => setFileController(response.data))
      .catch(error => console.error('e', error))
  }

  const renderExport = () => {
    return (
      <ExportFile
        controller={fileController}
        filename={filename}
        onCloseModal={() => setFileController(null)}
        modalOpen
      />
    )
  }

  return (
    <>
      <Tooltip title={filename} key={filename}>
        <IconButton onClick={() => handleGetFile(url)}>
          <FileIcon />
        </IconButton>
      </Tooltip>
      {fileController && renderExport()}
    </>
  )
}

export default TypeAttachments
