import { Moment, Translate } from '@chipinside/frontend'
import { styled } from '@mui/material/styles'
import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const Chip = styled(CowmedChip)({
  backgroundColor: Colors.tags.new.backgroundColor,
  color: Colors.tags.new.color,
  height: 18,
  fontSize: 12,
  position: 'relative',
  marginTop: -5,
  zIndex: 4,
})

const NewBadge = ({ timestamp = false, customStyle = {} }) => {
  if (timestamp && Moment.diff('now', timestamp, 'h') <= 24)
    return (
      <Chip
        value={Translate({ messageKey: 'new_item_male' })}
        customStyle={customStyle}
        width="unset"
        size="small"
      />
    )

  return null
}

export default NewBadge
