import { useFetch } from '@chipinside/fetcher'
import { HelpIcon, Translate } from '@chipinside/frontend'
import { IconButton } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { isEmpty, isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getHelpPages, getLoginInfo, loadHelpPages } from '#/store/ducks/auth'
import { endpoint } from '#/store/services/endpoints'
import Palette from '#/styles/Old/Palette'
import getTracking from '#/utils/getTracking'
import tryCache from '#/utils/tryCache'

import DrawerBox from '../DrawerBox'
import Iframe from './iframe'
import { mapping } from './mapping'

const style = {
  listTitle: {
    marginTop: 40,
  },
  description: {
    marginTop: 15,
    marginBottom: 15,
    whiteSpace: 'pre-line',
  },
  linksList: {
    listStyleType: 'none',
    paddingLeft: 0,
    color: Palette.blue.darken[3],
  },
  pageName: {
    color: Palette.orange.lighten[1],
    marginBottom: 0,
  },
}

export default function HelpPages() {
  const [isOpen, setIsOpen] = useState(false)
  const [controller, setController] = useState(null)
  const [pageName, setPageName] = useState(null)

  const { pathname } = useLocation()
  const helpPages = useSelector(getHelpPages, isEqual)
  const thisPageHasHelp = helpPages?.list?.includes(pageName)

  // SWR: retrieve data from the endpoint
  const { data, status } = useFetch({ controller })

  function openHelpDrawerHandler() {
    setController(endpoint.database.help_pages.help({ help: pageName }))
    setIsOpen(true)
  }
  function closeHelpDrawerHandler() {
    setIsOpen(false)
  }

  // validate page name on the mapping file
  useEffect(() => {
    setPageName(getTracking(pathname, mapping))
  }, [pathname])

  // create list of links for the "see also" section
  const linksList = data?.help?.links
    ?.filter(link => link.url)
    .map((link, key) => (
      <li key={`help-link${key}`}>
        <a href={link.url} target="_blank" rel="noopener noreferrer">
          {link.text}
        </a>
      </li>
    ))

  // create drawer content
  const drawerContent = (
    <>
      <div style={style.description}>{data?.help?.description}</div>
      {data?.help?.video_link && <Iframe url={data.help.video_link} />}
      {!isEmpty(linksList) && (
        <>
          <h3 style={style.listTitle}>
            {Translate({ messageKey: 'see_also' })}
          </h3>
          <ul style={style.linksList}>{linksList}</ul>
        </>
      )}
    </>
  )

  return thisPageHasHelp ? (
    <div>
      <IconButton onClick={openHelpDrawerHandler} size="large">
        <HelpIcon />
      </IconButton>
      <DrawerBox
        title={data?.name_text.split(' - ').reverse()[0]}
        isOpen={isOpen}
        onClose={closeHelpDrawerHandler}
        pageName={
          <h3 style={style.pageName}>
            {Translate({ messageKey: 'help_center' })}
          </h3>
        }
      >
        {data && status === 'done' ? (
          drawerContent
        ) : (
          <LinearProgress style={{ marginTop: '40px' }} />
        )}
      </DrawerBox>
    </div>
  ) : null
}

// check for updates on the help pages from time to time
export function HelpPageCheck() {
  const helpPages = useSelector(getHelpPages, isEqual)
  const LoginInfo = useSelector(getLoginInfo, isEqual)

  const dispatch = useDispatch()

  function executeCheck() {
    if (LoginInfo?.status === 'done' && tryCache.isExpired(helpPages.expires)) {
      dispatch(loadHelpPages())
    }
  }

  useEffect(() => {
    executeCheck()
    const interval = setInterval(() => executeCheck(), 1000 * 60 * 5)
    return () => clearInterval(interval)
  }, [])

  return null
}
