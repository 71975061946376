import React from 'react'

import WarningBadge from '#/components/badges/WarningBadge'

const TypeWarningBadge = ({ value, tooltip }) => {
  if (!value) return <span>-</span>
  return <WarningBadge value={value} tooltip={tooltip} />
}

export default TypeWarningBadge
