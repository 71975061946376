import Colors from '#/styles/Old/Colors'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: Colors.textSecondary,
  },
  containerFilters: {
    'display': 'flex',
    'flexDirection': 'row',
    'flexWrap': 'wrap',
    'alignItems': 'flex-end',
    'rowGap': 10,
    '& > div': {
      minWidth: '200px !important',
      marginRight: 10,
    },
  },
  [theme.breakpoints.down('md')]: {
    containerFilters: {
      '& > div': {
        flex: 1,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    containerFilters: {
      width: '100%',
      flexDirection: 'column',
    },
    container: {
      width: '100%',
    },
  },
})

export default styles
