import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { PagesList } from '#/components/AdvancedSearch/pages'
import { TopAlert } from '#/components/Alerts/Top'
import { PortalTop } from '#/utils/portal'

// Função que verifica se o pathname bate com o urlTemplate
const checkIfPathMatches = (pathname: string, url: string): boolean => {
  const regex = new RegExp('^' + url.replace(/\{(\w+)\}/g, '([^/]+)') + '$')
  return regex.test(pathname)
}

// Componente para exibir o alerta
export const CypressAlert = () => {
  const [title, setTitle] = useState('')
  const { pathname } = useLocation()

  const [mapped, setMapped] = useState(false)

  const pages = PagesList()

  useEffect(() => {
    setMapped(pages?.some(({ url }) => checkIfPathMatches(pathname, url)))
  }, [pathname, pages])

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const breadcrumb = document
        .querySelector('.MuiBreadcrumbs-root')
        ?.getAttribute('data-breadcrumb')

      if (breadcrumb) {
        setTitle(breadcrumb)
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  // Se a página já estiver mapeada, não exibe o alerta
  if (mapped) return null

  const handleCopy = () => {
    const text = JSON.stringify({
      title,
      description: '',
      type: '',
      url: pathname,
      permission: true,
      context: pathname.startsWith('/farm') ? 'farm' : 'system',
    })
    navigator.clipboard.writeText(text + ', \n')
  }

  return (
    <PortalTop>
      <TopAlert
        severity={'info'}
        variant="filled"
        title={'Página não cadastrada no Cypress'}
        content={[title, window?.location?.pathname]}
        onClick={handleCopy}
      />
    </PortalTop>
  )
}
