import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const TypeNPSCampaignStatus = ({ value }) => {
  if (!value) return '-'

  const { status, text } = value

  const color = {
    'awaiting': Colors.nps.awaiting,
    'finalized': Colors.nps.finalized,
    'on-going': Colors.nps.ongoing,
    'suspended': Colors.nps.suspended,
  }

  return <CowmedChip value={text} width={130} customStyle={color[status]} />
}

export default TypeNPSCampaignStatus
