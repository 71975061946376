import Palette from '#/styles/Old/Palette'

const calc = 50
export const GaugeOptions = {
  title: null,

  exporting: {
    enabled: false,
  },

  tooltip: {
    enabled: false,
  },

  chart: {
    zoomType: null,
    type: 'solidgauge',
    spacing: 0,
    margin: 0,
    events: {
      load() {
        // Obtém a largura do gráfico
        let { chartWidth } = this
        // Calcula a altura com base na largura do gráfico
        let chartHeight = chartWidth * (calc / 100)
        // Define a altura do gráfico
        this.setSize(chartWidth, chartHeight, false)
      },
    },
  },

  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: 0,
        borderWidth: 0,
        useHTML: true,
        formatter() {
          let { chart } = this.series
          let fontSize = Math.min(30, chart.plotWidth / 8)
          return `<div style="margin-top:15px;text-align:center;"><span style="font-size:${fontSize}px; color: #666;">${this.y}${this.point.suffix}</span></div>`
        },
      },
    },
  },

  pane: {
    center: ['50%', `100%`],
    size: `${230 - calc}%`,
    startAngle: -90,
    endAngle: 90,
    background: {
      backgroundColor: '#eee',
      borderWidth: 0,
      innerRadius: '60%',
      outerRadius: '100%',
      shape: 'arc',
    },
  },

  yAxis: {
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    labels: {
      enabled: false,
    },
    min: 0,
    max: 100,
    plotLines: [
      {
        width: 2,
        color: Palette.orange.darken[1],
        zIndex: 5,
      },
    ],
  },
}
