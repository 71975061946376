import React from 'react'

import { Square } from './Square'

export const RecomendationScale = props => {
  const { data, styles } = props
  return (
    <div style={styles.line_container}>
      {data.map((hour, index) => {
        return (
          <Square
            key={`key-${index}`}
            isRecomended={hour.recommended}
            styles={styles}
          />
        )
      })}
    </div>
  )
}
