import { Moment } from '@chipinside/frontend'
import Typography from '@mui/material/Typography'
import React from 'react'

import { getReleaseVersion, isDev } from '#/utils'

export default function Copyright() {
  const fullAppVersion = import.meta.env.VITE_VERSION
  const cleanAppVersion = isDev ? fullAppVersion : getReleaseVersion()
  const currentYear = Moment().year()

  return (
    <Typography style={{ fontSize: 10 }}>
      Powered by{' '}
      <a
        href="https://www.cowmed.com.br/"
        target="_blank"
        rel="noopener noreferrer"
      >
        CowMed
      </a>{' '}
      &copy; {currentYear} ·{' '}
      <span title={fullAppVersion}>{cleanAppVersion}</span>
    </Typography>
  )
}
