import { Box, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isEmpty, uniqueId } from 'lodash'
import React from 'react'

import CowmedChip from '#/components/Chip'

const Content = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 5,
  overflow: 'hidden',
  maxWidth: 350,
})
const Chip = styled(CowmedChip)({
  border: '1px solid #e6ebf1',
  backgroundColor: '#fff',
})

const TypeBatchesList = props => {
  let { value } = props

  if (isEmpty(value)) return '-'

  return (
    <Content>
      {value?.map((item, index) => (
        <Tooltip title={item} key={uniqueId(`batches_list-${index}`)}>
          <div>
            <Chip
              value={item}
              customStyle={{ width: 'unset', minWidth: 103, maxWidth: 200 }}
            />
          </div>
        </Tooltip>
      ))}
    </Content>
  )
}

export default TypeBatchesList
