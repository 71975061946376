import { fmt, Moment, Translate } from '@chipinside/frontend'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import React, { useEffect, useState } from 'react'

// Funções utilitárias para manipulação de datas
const DatePickerUtils = {
  // Formata a data inicial recebida
  getInitialDate(date) {
    if (date) {
      const formattedDate = Moment(date)
      return formattedDate?.isValid() ? formattedDate : null
    }
    return null
  },
  // Formata a data final antes de passar para o manipulador onChange
  getFinalDate(date, type) {
    const format = type === 'timestamp' ? fmt.api : fmt.date
    return date.format(format)
  },
}

const DateTimePickerInput = ({
  type = 'timestamp',
  onChange,
  style,
  error,
  ...rest
}) => {
  // Estado para gerenciar o valor atual do seletor de data
  const [value, setValue] = useState(
    DatePickerUtils.getInitialDate(rest.value || rest.defaultValue),
  )

  // Estado para gerenciar mensagens de validação e estado de erro
  const [validation, setValidation] = useState({
    error: Boolean(error),
    helperText: error,
  })

  // Effect para atualizar o estado de validação quando as props error ou helperText mudarem
  useEffect(
    () =>
      setValidation({
        error: Boolean(error),
        helperText: error,
      }),
    [error],
  )

  // Manipulador para mudança de data
  const handleDateChange = value => {
    // Função para tratar entrada de data inválida
    const handleInvalidDate = () => {
      setValidation({
        error: true,
        helperText: Translate({ messageKey: 'invalid_date_format' }),
      })
    }

    // Função para tratar entrada de data válida
    const handleValidDate = date => {
      setValidation(
        error
          ? {
              error: Boolean(error),
              helperText: error,
            }
          : { error: false, helperText: false },
      )
      setValue(date)
      if (onChange) {
        onChange(DatePickerUtils.getFinalDate(date, type))
      }
    }

    // Se o valor for nulo, redefinir o seletor de data
    if (!value) {
      setValue(null)
      if (onChange) onChange(null)
      return
    }

    // Analisar a data com base no tipo e validá-la
    const date = type === 'timestamp' ? Moment(value) : value
    if (!date.isValid()) {
      handleInvalidDate()
    } else {
      handleValidDate(date)
    }
  }

  // Propriedades para o componente DatePicker
  const datePickerProps = {
    label: rest.label,
    name: rest.name,
    disabled: rest.disabled,
    value,
    onChange: handleDateChange,
    onClose: rest?.onBlur,
    disableFuture: rest.disableFuture,
    minDate: rest.minDate ? Moment(rest.minDate) : null,
    maxDate: rest.maxDate ? Moment(rest.maxDate) : null,
    slotProps: {
      // Propriedades para o componente TextField
      textField: {
        required: rest.required,
        clearable: true,
        fullWidth: true,
        onBlur: rest?.onBlur,
        style,
        ...validation,
      },
    },
  }

  // Renderizar o componente apropriado (DatePicker ou DateTimePicker) com base no tipo
  return type === 'timestamp' ? (
    <DateTimePicker
      {...datePickerProps}
      ampm={false}
      views={['year', 'month', 'day', 'hours', 'minutes']}
    />
  ) : (
    <DatePicker {...datePickerProps} views={['year', 'month', 'day']} />
  )
}

export default DateTimePickerInput
