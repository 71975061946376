export const VariablePieOptions = {
  chart: {
    type: 'pie',
    events: {
      load() {
        // Obtém a largura do gráfico
        let { chartWidth } = this
        // Calcula a altura com base na largura do gráfico
        let chartHeight = Math.min(350, chartWidth * (70 / 100))
        // Define a altura do gráfico
        this.setSize(chartWidth, chartHeight, false)
      },
    },
  },
  title: {
    text: null,
  },
  exporting: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
      },
      showInLegend: false,
    },
  },
  tooltip: {
    headerFormat: '',
    pointFormat:
      '<span style="color:{point.color}">\u25CF</span> <b> {point.name}: {point.y}</b>',
  },
  series: [
    {
      minPointSize: 50,
      innerSize: '70%',
      zMin: 0,
    },
  ],
}
