import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import xorWith from 'lodash/xorWith'
import React, { Component } from 'react'
import { withContentRect } from 'react-measure'

import DatagridBodyContainer from '../DatagridBodyContainer/DatagridBodyContainer'
import classes from '../DatagridContainer/DatagridContainer.module.css'
import DatagridHeaderContainer from '../DatagridHeaderContainer/DatagridHeaderContainer'

const getMinPriorityVisibleColumns = width => {
  if (width >= 1550) {
    return 0
  }
  if (width < 1550 && width >= 1400) {
    // consoleWarn('1 -> datagrid < 1550 && >=1400')
    return 1
  }
  if (width < 1400 && width >= 1250) {
    // consoleWarn('2 -> datagrid < 1400 && >=1250')
    return 2
  }
  if (width < 1250 && width >= 1100) {
    // consoleWarn('3 -> datagrid < 1250 && >=1100')
    return 3
  }
  if (width < 1100 && width >= 950) {
    // consoleWarn('4 -> datagrid < 1100 && >=950')
    return 4
  }
  if (width < 950 && width >= 850) {
    // consoleWarn('5 -> datagrid < 950 && >=850')
    return 5
  }
  if (width < 850 && width >= 700) {
    // consoleWarn('6 -> datagrid < 850 && >=700')
    return 6
  }
  if (width < 700 && width >= 500) {
    // consoleWarn('7 -> datagrid < 700 && >=500')
    return 7
  }
  if (width < 500 && width >= 375) {
    // consoleWarn('8 -> datagrid < 500 && >=375')
    return 8
  }
  if (width < 375) {
    // consoleWarn('9 -> datagrid < 375')
    return 9
  }
}

const formatRowSpace = ({
  visibleColumns,
  invisibleColumns,
  withDetails,
  withActionsColumn = false,
  bulkActions,
}) => {
  const columnsWidth = visibleColumns.map(header => {
    let { min, max } = header.width || {}
    return `minmax(${min || 'min-content'}, ${max || 'auto'})`
  })

  const gridTemplateColumns = [
    bulkActions ? 'minmax(min-content, max-content)' : '',
    withDetails ? 'minmax(min-content, max-content)' : '',
    ...columnsWidth,
    withActionsColumn ? 'minmax(min-content, max-content)' : '',
    !isEmpty(invisibleColumns) ? 'minmax(min-content, max-content)' : '',
  ].join(' ')

  return { gridTemplateColumns }
}

class DatagridTableContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visibleColumns: [],
      invisibleColumns: [],
      rowSpace: null,
      width: null,
      minPriorityVisible: 0,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      enabledColumns,
      bulkActions,
      withDetails,
      actionsEnabled = [],
      contentRect,
    } = props

    let {
      visibleColumns,
      invisibleColumns,
      rowSpace,
      width,
      minPriorityVisible,
    } = state

    if (
      !isEmpty(contentRect.bounds) &&
      contentRect.bounds.width !== null &&
      contentRect.bounds.width !== width &&
      !isEmpty(enabledColumns)
    ) {
      width = contentRect.bounds.width
      minPriorityVisible = getMinPriorityVisibleColumns(width)
    }

    visibleColumns = filter(
      enabledColumns,
      head => head.priority >= minPriorityVisible,
    )
    invisibleColumns = xorWith(enabledColumns, visibleColumns, isEqual)
    rowSpace = formatRowSpace({
      visibleColumns,
      invisibleColumns,
      withDetails,
      withActionsColumn: !isEmpty(actionsEnabled),
      bulkActions: bulkActions && !isEmpty(bulkActions),
    })

    return {
      visibleColumns,
      invisibleColumns,
      rowSpace,
      width,
      minPriorityVisible,
    }
  }

  render() {
    const {
      enabledColumns,
      actionsEnabled,
      bulkActions,
      onSelectRow,
      onSelectAllRows,
      orderChanged = () => {},
      withDetails,
      onDetailsOpened,
      containerClass,
      containerStyle = {},
      headerCellClass,
      headerCellStyle = {},
      bodyCellClass,
      bodyCellStyle = {},
      bodyIsStriped = true,
      list = [],
      customRowStyle,
      measureRef,
    } = this.props

    const { visibleColumns, invisibleColumns, rowSpace } = this.state

    return (
      <div
        data-cy="datagrid-table-container"
        ref={measureRef}
        className="datagrid-root"
      >
        <div
          className={[classes.container, containerClass].join(' ')}
          style={{ ...rowSpace, ...containerStyle }}
        >
          <DatagridHeaderContainer
            columns={enabledColumns}
            visibleColumns={visibleColumns}
            invisibleColumns={invisibleColumns}
            withDetails={withDetails}
            bulkActions={bulkActions}
            onSelectAllRows={onSelectAllRows}
            rowsCount={Array.isArray(list) ? list.length : 0}
            cellClass={headerCellClass}
            cellStyle={headerCellStyle}
            orderChanged={orderChanged}
            actionsEnabled={actionsEnabled}
          />
          <DatagridBodyContainer
            list={list}
            withDetails={withDetails}
            bulkActions={bulkActions}
            onSelectRow={onSelectRow}
            onDetailsOpened={onDetailsOpened}
            visibleColumns={visibleColumns}
            invisibleColumns={invisibleColumns}
            enabledColumns={enabledColumns}
            cellClass={bodyCellClass}
            cellStyle={bodyCellStyle}
            bodyIsStriped={bodyIsStriped}
            actionsEnabled={actionsEnabled}
            customRowStyle={customRowStyle}
          />
        </div>
      </div>
    )
  }
}

export default withContentRect('bounds')(DatagridTableContainer)
