import React from 'react'

import Switch from '#/components/FormControl/Inputs/Switch'

const SwitchFilters = props => {
  const {
    onChangeFilter = () => {},
    containerClass = '',
    containerStyle = {},
  } = props

  return props?.filters?.map((filter, index) => {
    return (
      <div
        key={index}
        className={containerClass}
        style={{ margin: 'auto 10px', ...containerStyle }}
      >
        <Switch
          name={filter.name}
          checked={filter.current}
          label={filter.title}
          onChange={onChangeFilter}
          hint={filter.hint}
        />
      </div>
    )
  })
}

export default SwitchFilters
