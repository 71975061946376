import { styled } from '@mui/material/styles'
import { isEqual } from 'lodash'
import React, { Suspense, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { isBusinessEnvironment } from '#/utils'
import lazyRetry from '#/utils/lazyRetry'

import VicProvider from '../Vic/VicContextProvider'

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  bottom: 16,
  right: 16,
  gap: 10,
})

const VicChat = React.lazy(() =>
  lazyRetry(() => import('#/components/Vic/Chat')),
)

const QuickActions = React.lazy(() =>
  lazyRetry(() => import('#/components/QuickActions')),
)

export default function FloatActions() {
  const { pathname } = useLocation()

  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const isB2B = isBusinessEnvironment()

  const actions = useMemo(
    () => [
      {
        key: 'vic-chat',
        Component: VicChat,
        enabled: Boolean(currentFarmSlug),
      },
      {
        key: 'quick-actions',
        Component: QuickActions,
        enabled: Boolean(pathname.match(`^/farm/${currentFarmSlug}/?$`)),
      },
    ],
    [currentFarmSlug, pathname],
  )

  return !isB2B ? (
    <Wrapper>
      <Suspense>
        <VicProvider>
          {actions.map(
            ({ key, Component, enabled }) => enabled && <Component key={key} />,
          )}
        </VicProvider>
      </Suspense>
    </Wrapper>
  ) : null
}
