import { fetcher } from '@chipinside/fetcher'
import { Moment, Translate } from '@chipinside/frontend'
import {
  Box,
  Divider,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import AnimalDrawer from '#/components/DrawerBox/Animal'
import FormatCell from '#/components/FormatCell'
import JobDetail from '#/components/Jobs/JobDetail'
import SimpleDialog from '#/components/Modal'
import { endpoint } from '#/store/services/endpoints'
import Colors from '#/styles/Old/Colors'
import { publish } from '#/utils/pubsub'

import AlertIcon from './AlertIcon'

const style = {
  alert: {
    padding: '8px 20px',
    cursor: 'pointer',
  },
}

export default function FormatAlert({ data, status, farm, setIsOpen }) {
  const history = useHistory()
  const [openJob, setOpenJob] = useState(false)
  const [selectedJob, setSelectedJob] = useState({})
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerData, setDrawerData] = useState(null)
  const handleDetails = useCallback(
    props => {
      const { job, route, visualized_at, slug, alert_slug, drawer } = props

      if (drawer) {
        setDrawerData({
          controller: endpoint.farm.heat.heat_event_details({
            farm,
            event: slug,
          }),
          event: props,
        })
        setDrawerOpen(true)
      } else if (route) {
        if (alert_slug && !visualized_at) {
          fetcher({
            method: 'put',
            controller: endpoint.farm.alert.view({ farm, alert: alert_slug }),
          })
            .then(response => {
              if (response?.message_code === 'ok') {
                publish('alerts-marked-as-read', {
                  readAlertsList: [alert_slug],
                })
              }
            })
            .catch(error => console.error(error))
        }
        setIsOpen(false)
        history.push(route)
      } else {
        setOpenJob(prevVal => !prevVal)
        setSelectedJob(job)
      }
    },
    [farm, history, setIsOpen],
  )

  const isNewAlert = timestamp =>
    timestamp ? Moment.diff('now', timestamp, 'h') < 24 : false

  const alertText = alert => {
    return alert.title ? (
      <>
        <Box display="flex" alignItems="center" gap={1}>
          <strong>{alert.title}</strong>
          <FormatCell type="inapt" flag={alert['animal.inapt']} size="26" />
        </Box>
        <Box>{alert.body}</Box>
      </>
    ) : (
      alert.name_text
    )
  }

  const items = data?.map(alert => {
    const eventType = alert['event_type.code']
    const subject = alert['subject.slug']
    const date = alert.timestamp ?? alert.finished_at

    let route = null
    let drawer = false
    if (eventType?.match(/reproduction*/) && subject) {
      if (eventType?.match(/reproduction.heat/)) {
        drawer = true
      } else {
        route = `/farm/${farm}/modules/herd/animals/${subject}/reproduction`
      }
    } else if (eventType?.match(/health.condition*/) && subject) {
      route = `/farm/${farm}/modules/herd/animals/${subject}`
    } else if (eventType?.match(/rumination*/) && subject) {
      route = `/farm/${farm}/modules/herd/batch/${subject}/profile`
    } else if (eventType?.match(/(ccom|farm)*/)) {
      route = `/farm/${farm}`
    }

    return {
      ...alert,
      drawer,
      route,
      job: alert,
      key: alert.slug ?? alert.alert_slug,
      text: alertText(alert),
      subtext: date ? Moment(date).format('LLLL') : null,
      icon: (
        <AlertIcon
          event_type={eventType ?? alert.status}
          virtual_type={alert.virtual_type}
          health_status={alert.parameters?.health_status}
          dtr={alert.parameters?.reproductive_fitness_by_heat_stress}
        />
      ),
      /**SHOW YELLOW BACKGROUND */
      isANewAlert: !alert.visualized_at && isNewAlert(alert.timestamp),
    }
  })

  if (status !== 'done') {
    return <LinearProgress />
  }
  const renderAlert = item => {
    return item.isANewAlert ? (
      <span style={{ color: Colors.primary }}>
        {Moment(item.timestamp).fromNow()}
      </span>
    ) : (
      item.subtext
    )
  }

  return isEmpty(data) ? (
    <Typography color="textSecondary" component="p" style={{ padding: 20 }}>
      {Translate({ messageKey: 'without_information' })}
    </Typography>
  ) : (
    <>
      {items?.map(item => (
        <React.Fragment key={item.key}>
          <ListItem
            style={{
              ...style.alert,
              backgroundColor: item.isANewAlert ? Colors.alerts_new : null,
            }}
            onClick={() => handleDetails(item)}
          >
            <ListItemAvatar>{item.icon}</ListItemAvatar>
            <ListItemText
              primary={item.text}
              secondary={
                item.status === 'queued' ? (
                  <LinearProgress />
                ) : (
                  renderAlert(item)
                )
              }
            />
          </ListItem>
          <Divider light />
        </React.Fragment>
      ))}
      <SimpleDialog
        fullWidth
        title={selectedJob?.name_text}
        open={openJob}
        onClose={() => handleDetails({ job: selectedJob })}
        element={<JobDetail job={selectedJob} />}
      />
      <AnimalDrawer
        {...drawerData}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        afterSuccess={() => setIsOpen(false)}
      />
    </>
  )
}
