import { useFetch } from '@chipinside/fetcher'
import { Translate } from '@chipinside/frontend'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, Button, IconButton, Modal, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

import Loader from '#/components/Loader'

// Estilo do cabeçalho do modal
const ModalHeader = styled(Box)({
  position: 'absolute',
  right: '10px',
  top: '10px',
})

// Estilo do conteúdo do modal
const ModalContent = styled(Paper)({
  display: 'flex',
  textAlign: 'center',
  gap: '20px',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  padding: '50px 32px',
})

const MAX_RECORDS = 500

/**
 * Componente para exibir e imprimir um relatório em PDF.
 *
 * @param {Object} props - Propriedades do componente
 * @param {boolean} props.modalOpen - Se o modal está aberto
 * @param {Function} [props.onCloseModal] - Função para fechar o modal
 * @param {Object} props.controller - Controlador para a requisição de dados
 * @param {number} props.totalRecords - Número total de registros
 * @param {Object} [props.params] - Parâmetros adicionais para a requisição
 */
const DatagridPrint = props => {
  const {
    modalOpen,
    onCloseModal = () => {},
    controller,
    totalRecords,
    params = {},
  } = props

  const { data, error, isLoading } = useFetch({
    controller,
    params: { ...params, format: 'pdf' },
    axiosOptions: { responseType: 'blob' },
  })
  const [fileURL, setFileURL] = useState(null)
  const [iframe, setIframe] = useState(null)

  useEffect(() => {
    if (!isLoading && modalOpen && data) {
      // Cria um URL para o arquivo Blob recebido
      const newFileURL = window.URL.createObjectURL(data)
      setFileURL(newFileURL)

      // Remove qualquer iframe existente do documento
      if (iframe) {
        document.body.removeChild(iframe)
      }

      // Cria um novo iframe para exibir o PDF
      const newIframe = document.createElement('iframe')
      newIframe.id = 'printable'
      newIframe.style.display = 'none'
      newIframe.src = newFileURL
      document.body.appendChild(newIframe)
      setIframe(newIframe)
      handlePrint(newIframe)
    }
  }, [isLoading, modalOpen, data])

  /**
   * Aciona a impressão do conteúdo do iframe.
   *
   * @param {HTMLIFrameElement} iframe - O iframe que contém o PDF
   */
  const handlePrint = iframe => {
    if (iframe) {
      iframe.onload = () => {
        const iframeWindow = iframe.contentWindow
        if (iframeWindow) {
          iframeWindow.focus()
          iframeWindow.print()
        }
      }
      // Força o recarregamento do iframe para acionar a impressão
      // eslint-disable-next-line no-self-assign
      iframe.src = iframe.src
    }
  }

  /**
   * Fecha o modal e limpa os recursos associados.
   */
  const handleClose = () => {
    if (iframe) {
      document.body.removeChild(document.getElementById('printable'))
      setIframe(null)
    }
    if (fileURL) {
      window.URL.revokeObjectURL(fileURL)
      setFileURL(null)
    }
    onCloseModal()
  }

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <ModalContent>
        <ModalHeader>
          <IconButton aria-label="Close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <div>
          {Translate({
            messageKey: error ? 'failed' : 'printing',
          })}
        </div>
        <Loader status={isLoading} />
        {totalRecords > MAX_RECORDS ? (
          <Alert severity="warning" icon={false}>
            {Translate({
              messageKey: 'print_limit',
              params: { limit: MAX_RECORDS },
            })}
          </Alert>
        ) : null}
        <Button
          onClick={() => handlePrint(iframe)}
          disabled={!iframe}
          variant="outlined"
        >
          {Translate({ messageKey: 'print' })}
        </Button>
      </ModalContent>
    </Modal>
  )
}

export default DatagridPrint
