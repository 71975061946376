import TextField from '@mui/material/TextField'
import React from 'react'

const TextInput = ({
  type = 'text',
  name,
  value = '',
  placeHolder = '',
  label = '',
  disabled = false,
  required = false,
  helperText,
  onChange = () => {},
  onPaste = () => {},
  onClick,
  error,
  fullWidth,
  variant,
  containerStyle = {},
}) => (
  <div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: '10px',
        ...containerStyle,
      }}
    >
      <TextField
        variant={variant}
        fullWidth={fullWidth}
        type={type}
        name={name}
        value={value ?? ''}
        placeholder={placeHolder}
        label={label}
        onChange={e => onChange(e.target.value, e)}
        onPaste={e => onPaste(e)}
        disabled={disabled}
        required={required}
        helperText={helperText}
        slotProps={{
          input: {
            sx: {
              width: '100%',
            },
          },
        }}
        onClick={onClick}
        error={Boolean(error)}
      />
    </div>
  </div>
)

export default TextInput
