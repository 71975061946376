import { PpiIcon, Translate } from '@chipinside/frontend'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CSSProperties } from 'react'

import Colors from '#/styles/Colors'

const Label = styled('p')({
  margin: 0,
  lineHeight: '16px',
  fontWeight: 500,
  color: '#666',
})

const Content = styled('div')(
  ({ orientation }: { orientation: TypePPIProps['orientation'] }) => ({
    display: 'flex',
    flexDirection: orientation,
    alignItems: 'center',
    columnGap: 8,
  }),
)

const Icon = styled(PpiIcon)(({ status }: { status: PPI['status'] }) => ({
  fontSize: 25,
  height: '30px',
  color: Colors.ppi[status],
}))

export type PPI = {
  status: 'challenge' | 'normal'
  text: string
}

export type TypePPIProps = {
  value: PPI
  orientation: CSSProperties['flexDirection']
  textStyle: CSSProperties
}

const TypePPI = ({
  value,
  orientation = 'column',
  textStyle,
}: TypePPIProps) => {
  const { status, text } = value ?? {}

  if (!status) {
    return <span>-</span>
  }

  return (
    <Tooltip title={`${Translate({ messageKey: 'ppi' })} - ${text}`}>
      <Content orientation={orientation}>
        <Icon status={status} />
        <Label style={textStyle}>{text}</Label>
      </Content>
    </Tooltip>
  )
}

export { TypePPI }
