import { fmt, Moment, Translate } from '@chipinside/frontend'
import { Paper } from '@mui/material'
import React from 'react'

import OutputFields from '#/components/OutputFields'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Old/Colors'
import { plotToHtml } from '#/utils'

import classes from './PlotLines.module.css'
import styles from './styles'
import { basePlot, getPlotIconHtml, labelTextHandler } from './utils'

export default function milkPlotlines(events, startOf) {
  let groupedByDate = {}
  const milkQualityPlots = []
  //joins samples by date   to create single plot
  if (Array.isArray(events) && events.length) {
    events.forEach(event => {
      const key = Moment(event.timestamp).startOf(startOf).format(fmt.api)

      if (!groupedByDate[key]) groupedByDate[key] = []
      groupedByDate[key] = [...groupedByDate[key], event]
    })

    Object.entries(groupedByDate).forEach(([timestamp, samples]) => {
      const { id } = samples[0]

      const milkQualityDate = Moment(timestamp)

      const plotText = plotToHtml(
        renderMilkQualityPlot({
          slug: `milk-quality-${id}`,
          name: Translate({ messageKey: 'milk_quality_abreviation' }),
          title: Translate({ messageKey: 'milk_quality' }),
          subtitle: milkQualityDate.format('L LT'),
          samples,
          color: Colors.milk_quality,
          icon: getPlotIconHtml('milk_quality'),
        }),
      )
      const plotId = `plot-line-id-${id}`
      const plotClassName = 'plot-line-milk-quality'

      milkQualityPlots.push({
        ...basePlot,
        className: `${plotId} transition-opacity-300ms ${plotClassName}`,
        value: milkQualityDate.valueOf(),
        color: Colors.milk_quality,
        label: {
          ...basePlot.label,
          text: labelTextHandler({
            id: plotId,
            className: plotClassName,
            plotTextComponent: plotText,
          }),
        },
      })
    })

    return milkQualityPlots
  }

  return []
}

const renderMilkQualityInfos = (mqObject, idx) => {
  const { fat, lactose, protein, sample, scc, snf, solid, spc, urea } = mqObject

  let fieldsArray = [
    {
      label: Translate({ messageKey: 'sample' }),
      value: sample,
    },
    {
      label: Translate({ messageKey: 'SPC_x1000' }),
      value: spc,
    },
    {
      label: Translate({ messageKey: 'SCC_x1000' }),
      value: scc,
    },
    {
      label: Translate({ messageKey: 'protein_percentage' }),
      value: protein,
    },
    {
      label: Translate({ messageKey: 'fat_percentage' }),
      value: fat,
    },
    {
      label: Translate({ messageKey: 'solid_percentage' }),
      value: solid,
    },
    {
      label: Translate({ messageKey: 'urea_percentage' }),
      value: urea,
    },
    {
      label: Translate({ messageKey: 'lactose_percentage' }),
      value: lactose,
    },
    {
      label: Translate({ messageKey: 'SFN_percentage' }),
      value: snf,
    },
  ]

  return (
    <OutputFields
      key={idx}
      direction="row"
      fields={fieldsArray}
      style={{ flex: '1', marginLeft: idx > 0 ? 16 : 0 }}
    />
  )
}

const renderMilkQualityPlot = ({
  slug,
  name,
  title,
  subtitle,
  samples,
  color,
  icon,
}) => {
  return (
    <div className={classes.headerContainer} key={slug}>
      <div style={styles.labelContainer}>
        <span style={styles.icon} dangerouslySetInnerHTML={{ __html: icon }} />
        <span style={{ ...styles.name, backgroundColor: color }}>{name}</span>
      </div>
      <Paper key={`${slug}-popover`} className={classes.popover} elevation={2}>
        <div style={{ fontSize: Fonts.fontSize.M, color }}>{title}</div>
        <div style={{ fontSize: Fonts.fontSize.S, margin: '0.35em 0' }}>
          {subtitle}
        </div>
        <div style={{ display: 'flex' }}>
          {samples && samples.length
            ? samples.map(renderMilkQualityInfos)
            : null}
        </div>
      </Paper>
    </div>
  )
}
