import { Translate } from '@chipinside/frontend'
import React from 'react'

import CowmedChip from '#/components/Chip'
import Palette from '#/styles/Old/Palette'

const style = {
  'queued': {
    backgroundColor: Palette.cyan.accent[4],
  },
  'started': {
    backgroundColor: Palette.cyan.accent[4],
  },
  'finished': {
    backgroundColor: Palette.green_grey.self,
  },
  'success': {
    backgroundColor: Palette.primary.self,
  },
  'success-with-warnings': {
    backgroundColor: Palette.ambar.darken[1],
  },
  'failed': {
    backgroundColor: Palette.red.lighten[2],
  },
}

const JobStatusBadge = ({ status }) => {
  const parsedStatus = status.replace(/-/g, '_')

  return (
    <CowmedChip
      value={Translate({ messageKey: parsedStatus })}
      customStyle={{ ...style[status], color: '#fff' }}
      width="unset"
    />
  )
}

export default JobStatusBadge
