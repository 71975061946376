import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getPermissions } from '../ducks/auth'

/**
 * withSystem HOC
 * Add system context to the component
 * @param {Object} Component: <typeof ReactComponent>
 * @returns {Object} Component wrapped with system permissions
 */
export function withSystem(Component) {
  const WithSystem = props => {
    const dispatch = useDispatch()

    useEffect(() => {
      if (dispatch) {
        dispatch(getPermissions({ context: 'system' }))
      }
    }, [dispatch])

    return <Component {...props} />
  }

  return WithSystem
}
