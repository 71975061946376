/**
 * Check if string is boolean
 * @memberOf Utils
 * @function stringIsTrue
 * @param {String} data
 *
 * @return {Boolean}
 */
const stringIsTrue = data => {
  return data === '1' || data === 'true' || data === true || data === 1
}

export default stringIsTrue
