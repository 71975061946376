import { Moment, Translate } from '@chipinside/frontend'
import Typography from '@mui/material/Typography'
import { uniqueId } from 'lodash'
import React from 'react'

import BirthInfo from '#/components/EventInfos/Birth'
import FormatCell from '#/components/FormatCell'

import BooleanBadge from '../badges/BooleanBadge'
import QuickInfo from '../QuickInfo'

const white_list = [
  'recommendation',
  'deleted_at',
  'comment',
  'comments',
  'moderation_comment',
  'observation',
  'condition',
  'treated',
  'treatment',
  'treatments',
  'diagnostic_id',
  'donor',
  'semen_label',
  'with_anormal_behavior',
  'abnormal_behaviour',
  'gender',
  'calf_status',
  'complication',
  'from_algorithm',
  'with_ftai',
  'with_heat',
  'health_completion_type',
  'method_text',
  'heat_detection_method_text',
  'heat_intensity_text',
  'heat_signs_text',
  'description',
  'received_at',
  'processed_at',
  'confirmed_at',
  'confirmer_name',
  'restored',
  'detected_at',
  'created_at',
  'creator_name',
  'creator.name',
  'inseminator_name',
  'embryo_type_text',
  'discard_milk',
  'milking_return',
  'calving_procedure',
  'calves',
  'foetus_text',
  'semen_type_text',
  'ppi_status',
  'last_processed_at',
  'updated_at',
  'answered_at',
  'mammary_quarter',
  'mastitis_causative_agent',
  'mastitis_intensity_level',
]

const component_white_list = ['birth_event']

export const renderEventInfos = event => {
  const getKeyField = (key, value) => {
    let response = {
      name: key,
      value,
    }
    switch (key) {
      case 'mammary_quarter':
        response.label = Translate({ messageKey: 'mammary_quarter' })
        response.value = value?.length ? (
          <ul style={{ margin: '5px 0' }}>
            {value?.map(({ text }) => (
              <li key={uniqueId(text)}>{text}</li>
            ))}
          </ul>
        ) : null
        break

      case 'mastitis_causative_agent':
        response.label = Translate({ messageKey: 'mastitis_causative_agent' })
        response.value = value?.text
        break

      case 'mastitis_intensity_level':
        response.label = Translate({ messageKey: 'mastitis_intensity_level' })
        response.value = value?.text
        break

      case 'answered_at':
        response.label = Translate({ messageKey: 'answered_at' })
        response.value = value ? Moment(value).format('L') : null
        break

      case 'comment':
      case 'comments':
      case 'moderation_comment':
      case 'observation':
        response.label = Translate({ messageKey: 'comment_plural' })
        break

      case 'ppi_status':
        response.label = 'PPI'
        response.value = value
        break

      case 'condition':
        response.label = Translate({ messageKey: 'animal_condition_today' })
        response.value = value ? Translate({ messageKey: value }) : null //REVISAR
        break

      case 'diagnostic':
        response.label = Translate({ messageKey: 'diagnostic' })
        break

      case 'treated':
        response.label = Translate({ messageKey: 'treated_male' })
        response.value = Translate({ messageKey: value ? 'yes' : 'no' })
        break

      case 'treatment':
        response.label = Translate({ messageKey: 'used_treatment' })
        break

      case 'treatments':
        response.label = Translate({ messageKey: 'treatment' })

        response.value = value?.length ? (
          <ul style={{ margin: '5px 0' }}>
            {value?.map(({ name }) => (
              <li key={uniqueId(name)}>{name}</li>
            ))}
          </ul>
        ) : null
        break

      case 'donor':
        response.label = Translate({ messageKey: 'donor' })
        break

      case 'health_completion_type':
        response.label = Translate({ messageKey: 'closure_by' })
        break

      case 'semen_label':
        response.label = Translate({ messageKey: 'bull_or_semen' })
        break

      case 'with_anormal_behavior':
      case 'abnormal_behaviour':
        response.label = Translate({
          messageKey: 'did_the_animal_exhibit_any_abnormal_behavior',
        })
        response.value = value ? <BooleanBadge onlyText value={value} /> : null
        break

      case 'gender':
        response.label = Translate({ messageKey: 'animal_gender' })
        response.value = value ? Translate({ messageKey: value }) : null
        break

      case 'complication':
        response.label = Translate({ messageKey: 'calvin_complication' })
        response.value = value ? <BooleanBadge onlyText value={value} /> : null
        break

      case 'calf_status':
        response.label = Translate({ messageKey: 'calf_condition' })
        response.value = value ? Translate({ messageKey: value }) : null //REVISAR
        break

      case 'embryo_type_text':
        response.label = Translate({ messageKey: 'embryo_type' })
        response.value = value ? Translate({ messageKey: value }) : null
        break

      case 'semen_type_text':
        response.label = Translate({ messageKey: 'semen_type' })
        response.value = value ? Translate({ messageKey: value }) : null
        break

      case 'from_algorithm':
        response.label = Translate({ messageKey: 'automatically_detected' })
        response.value = value ? <BooleanBadge onlyText value={value} /> : null
        break

      case 'with_ftai':
        response.label = Translate({ messageKey: 'heat_type_iatf' })
        response.value = value ? <BooleanBadge onlyText value={value} /> : null
        break

      case 'with_heat':
        response.label = Translate({
          messageKey: 'heat_register_12_hours_before',
        })
        response.value = value ? <BooleanBadge onlyText value={value} /> : null
        break

      case 'method_text':
      case 'heat_detection_method_text':
        response.label = Translate({ messageKey: 'detection_method' })
        break

      case 'heat_intensity_text':
        response.label = Translate({ messageKey: 'intensity' })
        break

      case 'heat_signs_text':
        response.label = Translate({ messageKey: 'signs' })
        response.value = value?.length ? (
          <FormatCell type="items-list" value={value} onlyText />
        ) : null
        break

      case 'description':
        response.label = Translate({ messageKey: 'description' })
        break

      case 'confirmed_at':
        response.label = Translate({ messageKey: 'confirmed_at' })
        response.value = value ? Moment.view(value) : null
        break

      case 'deleted_at':
        response.label = Translate({ messageKey: 'deleted_at' })
        response.value = value ? Moment.view(value) : null
        break

      case 'restored':
        response.label = Translate({ messageKey: 'recovered_event' })
        response.value = value ? <BooleanBadge onlyText value={value} /> : null
        break

      case 'created_at':
        response.label = Translate({ messageKey: 'created_item_at' })
        response.value = value ? Moment.view(value) : null
        break

      case 'updated_at':
        response.label = Translate({ messageKey: 'updated_at' })
        response.value = value ? Moment.view(value) : null
        break

      case 'detected_at':
        response.label = Translate({ messageKey: 'detected_at' })
        response.value = value ? Moment.view(value) : null
        break

      case 'received_at':
        response.label = Translate({
          messageKey: 'collar_event_received_at',
        })
        response.value = value ? Moment.view(value) : null
        break

      case 'processed_at':
        response.label = Translate({ messageKey: 'processed_at' })
        response.value = value ? Moment.view(value) : null
        break

      case 'last_processed_at':
        response.label = Translate({ messageKey: 'last_processed_at' })
        response.value = value ? Moment.view(value) : '-'
        break

      case 'creator.name':
      case 'creator_name':
        response.label = Translate({ messageKey: 'registered_by' })
        break

      case 'confirmer_name':
        response.label = Translate({ messageKey: 'confirmed_by' })
        break

      case 'inseminator_name':
        response.label = Translate({ messageKey: 'inseminator' })
        break

      case 'discard_milk':
        response.label = Translate({ messageKey: 'milk_discard' })
        response.value = <BooleanBadge onlyText value={value} invertedColors />
        break

      case 'milking_return':
        response.label = Translate({ messageKey: 'milking_return' })
        response.value = value ? Moment.view(value) : null
        break

      case 'calving_procedure':
        response.label = Translate({ messageKey: 'calving_dificulty' })

        switch (value) {
          case 'normal':
            response.value = Translate({ messageKey: 'normal' })
            break

          case 'intervention':
            response.value = Translate({ messageKey: 'easy_unassisted' })
            break

          case 'mechanical-traction':
            response.value = Translate({ messageKey: 'difficult_assistance' })
            break

          case 'veterinary-care':
            response.value = Translate({ messageKey: 'difficult_vet_care' })
            break

          case 'cirurgical-procedure':
            response.value = Translate({ messageKey: 'caesarean_surgery' })
            break

          default:
            response.value = null
            break
        }
        break

      // cow.gender: male, female
      // cow.calf_status: normal, premature, stillbirth
      case 'calves':
        response.label = Translate({ messageKey: 'son_plural' })
        response.value = value
          ?.map(cow => {
            const status =
              cow?.calf_status && typeof cow.calf_status === 'string'
                ? Translate({ messageKey: cow.calf_status })
                : ''

            const gender = cow.gender
              ? Translate({ messageKey: cow.gender })
              : Translate({ messageKey: 'calf_unknown' })

            return `${gender} ${status}`
          })
          .join(', ')
        break

      // foetus_text: [Fêmea, Macho, Macho]
      case 'foetus_text':
        response.label = Translate({ messageKey: 'foetus' })
        response.value = value?.join(', ')
        break

      default: //nothing to moo
    }

    return response
  }

  const getComponentField = (key, value) => {
    switch (key) {
      case 'birth_event':
        return <BirthInfo birthInfos={value} key={key} />

      default:
        break
    }
  }

  const renderItem = (item, key) => (
    <div key={key} style={{ display: 'flex' }}>
      <Typography
        variant="body2"
        component="div"
        style={{ fontSize: 12, paddingBottom: 5 }}
      >
        <strong>{item.label}: </strong>
        {item.value}
      </Typography>
    </div>
  )

  const result = []
  Object.keys(event).forEach((key, idx) => {
    if (white_list.includes(key)) {
      const el = getKeyField(key, event[key])
      if (el.value) {
        result.push(renderItem(el, `${key}-${idx}`))
      }
    } else if (component_white_list.includes(key)) {
      result.push(getComponentField(key, event[key]))
    }
  })

  return result
}

export const EventInfos = ({ event, ...rest }) => {
  return (
    <QuickInfo
      title={Translate({ messageKey: 'information_plural' })}
      {...rest}
    >
      {renderEventInfos(event)}
    </QuickInfo>
  )
}
