import React from 'react'

import styles from './ChartLoader.module.css'

export const GraphLoader = props => {
  const { label, customStyle } = props

  const color = 'rgba(0,0,0,.3)'

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...customStyle,
      }}
    >
      <svg version="1.1" id="Layer_1" viewBox="0 0 100 100" height={100}>
        <rect
          fill={color}
          width="3"
          height="100"
          transform="translate(0) rotate(180 3 50)"
        >
          <animate
            attributeName="height"
            attributeType="XML"
            dur="1s"
            values="30; 100; 30"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="17"
          fill={color}
          width="3"
          height="100"
          transform="translate(0) rotate(180 20 50)"
        >
          <animate
            attributeName="height"
            attributeType="XML"
            dur="1s"
            values="30; 100; 30"
            repeatCount="indefinite"
            begin="0.1s"
          />
        </rect>
        <rect
          x="40"
          fill={color}
          width="3"
          height="100"
          transform="translate(0) rotate(180 40 50)"
        >
          <animate
            attributeName="height"
            attributeType="XML"
            dur="1s"
            values="30; 100; 30"
            repeatCount="indefinite"
            begin="0.3s"
          />
        </rect>
        <rect
          x="60"
          fill={color}
          width="3"
          height="100"
          transform="translate(0) rotate(180 58 50)"
        >
          <animate
            attributeName="height"
            attributeType="XML"
            dur="1s"
            values="30; 100; 30"
            repeatCount="indefinite"
            begin="0.5s"
          />
        </rect>
        <rect
          x="80"
          fill={color}
          width="3"
          height="100"
          transform="translate(0) rotate(180 76 50)"
        >
          <animate
            attributeName="height"
            attributeType="XML"
            dur="1s"
            values="30; 100; 30"
            repeatCount="indefinite"
            begin="0.1s"
          />
        </rect>
        <rect
          x="100"
          fill={color}
          width="3"
          height="100"
          transform="translate(0) rotate(180 95 50)"
        >
          <animate
            attributeName="height"
            attributeType="XML"
            dur="1.2s"
            values="30; 100; 30"
            repeatCount="indefinite"
            begin="0.1s"
          />
        </rect>
      </svg>
      <div className={styles.label} data-text={label}>
        {label}
      </div>
    </div>
  )
}
