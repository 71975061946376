import { fmt, Moment } from '@chipinside/frontend'

import dateFormatSafari from '#/utils/dateFormatSafari'

import PlotLinesGenerator from '../PlotLines'
/**
 * Função de manipulação do eixo X
 *
 * formato de data aceita do backend:
 * @dateFormat EN: MM/DD/YYYY HH:mm, PT: DD/MM/YYYY HH:mm
 *
 * @param xAxis Recebe eixo X
 * @param xAxisFormat Recebe formato para data no eixo, sobreescreve o formato do backend
 * @return Retorna eixoX com label modificado caso seja informado(xAxisFormat) ou vindo do backend
 */
export default function xAxisFormatter(props) {
  const {
    xAxis,
    xAxisFormat,
    plotKeys,
    plotData,
    range,
    TIMEZONE,
    tickInterval,
    plotTypes,
    graph_tick,
  } = props
  if (Array.isArray(xAxis)) {
    return plotData
      ? {
          type: 'datetime',
          tickInterval,
          plotLines: PlotLinesGenerator(
            plotKeys,
            plotData,
            range,
            TIMEZONE,
            plotTypes,
            graph_tick,
          ),
          labels: {
            useHTML: true,
            style: {
              textTransform: 'uppercase',
            },
            formatter() {
              return Moment(this.value).format(xAxisFormat)
            },
          },
        }
      : {
          ...xAxis[0],
          type: null, //Corrige bug de conversão para datetime
          categories: dateFormatter(
            xAxis[0].categories,
            xAxis[0].format ?? xAxisFormat,
          ),
          labels: {
            useHTML: true,
            style: {
              textTransform: 'uppercase',
            },
            formatter() {
              let label
              if (xAxisFormat) {
                label = Moment(this.value).format(xAxisFormat)
              } else {
                label = this.value
              }
              return label
            },
          },
        }
  }
  return xAxis
}

function dateFormatter(categories, xAxisFormat) {
  const dateVerify = new Date(dateFormatSafari(categories[0]))
  if (!isNaN(dateVerify.getMonth())) {
    return xAxisFormat === 'DD/MM'
      ? categories.map(c => Moment(c).startOf('d').format(fmt.api))
      : categories.map(c => Moment(c).format(fmt.api))
  }
  return categories
}
