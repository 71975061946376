import { FormBuilder } from '@chipinside/formbuilder'
import { Translate } from '@chipinside/frontend'
import { isEqual } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LinearIndeterminate from '#/components/Loader'
import { toggleDialog } from '#/store/ducks/dialog'
import { getEvent, resetSaveEvent, saveEvent } from '#/store/ducks/event'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import {
  getFormFields,
  loadFormFields,
  resetFormFields,
} from '#/store/ducks/form'
import { endpoint } from '#/store/services/endpoints'
import eventCode from '#/utils/eventCode'

function ReproductionEventsForm(props) {
  const {
    eventType,
    saveEventName,
    animalSlug,
    selectedAnimals,
    eventSlug,
    isUpdate,
    isConfirm = false,
    onSuccessAction = () => {},
    isQuickAction,
  } = props

  const farmSlug = useSelector(getCurrentFarmSlug, isEqual)

  const paramsData = {
    farm: farmSlug,
    animal: animalSlug,
    event: eventSlug,
  }

  const formName = `${saveEventName}/form`
  const {
    data: formData,
    error: formError,
    status: formStatus,
  } = useSelector(state => getFormFields(state, formName), isEqual)
  const { status: eventStatus } = useSelector(
    state => getEvent(state, saveEventName),
    isEqual,
  )
  const dispatch = useDispatch()

  let saveEventController = useMemo(() => {
    if (isUpdate) {
      return endpoint.farm.animal.event.reproduction_event_self(paramsData)
    }
    return animalSlug
      ? endpoint.farm.animal.event.reproduction_event(paramsData)
      : endpoint.farm.animal.event.reproduction_event_many(paramsData)
  }, [animalSlug, isUpdate, paramsData])

  let formController = null

  switch (eventType) {
    case eventCode.reproduction.gynecologic_exam_apt:
      formController = !isUpdate
        ? endpoint.farm.gynecologic.apt.form(paramsData)
        : endpoint.farm.gynecologic.apt.update_form(paramsData)
      break

    case eventCode.reproduction.gynecologic_exam_inapt:
      formController = !isUpdate
        ? endpoint.farm.gynecologic.inapt.form(paramsData)
        : endpoint.farm.gynecologic.inapt.update_form(paramsData)
      break

    case eventCode.reproduction.abortion:
      if (isQuickAction) {
        formController = endpoint.farm.abortion.form_without_animal(paramsData)
      } else {
        formController = !isUpdate
          ? endpoint.farm.abortion.form(paramsData)
          : endpoint.farm.abortion.update_form(paramsData)
      }
      break

    case eventCode.reproduction.calving:
      formController = isConfirm
        ? endpoint.farm.event.reproduction.confirmation.self_form(paramsData)
        : !isUpdate
          ? endpoint.farm.calving.form(paramsData)
          : endpoint.farm.calving.update_form(paramsData)
      saveEventController = isConfirm
        ? endpoint.farm.event.reproduction.confirmation.self(paramsData)
        : saveEventController
      break

    case eventCode.reproduction.dry:
      if (isQuickAction) {
        formController = endpoint.farm.dry.form_without_animal(paramsData)
      } else {
        formController = !isUpdate
          ? endpoint.farm.dry.form(paramsData)
          : endpoint.farm.dry.update_form(paramsData)
      }
      break

    case eventCode.reproduction.embryo_transfer:
      formController = !isUpdate
        ? endpoint.farm.embryo_transfer.form(paramsData)
        : endpoint.farm.embryo_transfer.update_form(paramsData)
      break

    case eventCode.reproduction.empty:
      formController =
        endpoint.farm.event.reproduction.empty_update_form(paramsData)
      break

    case eventCode.reproduction.heat:
      formController = endpoint.farm.heat.form(paramsData)
      saveEventController = endpoint.farm.heat.confirm(paramsData)
      break

    case eventCode.reproduction.visual_heat:
      if (isQuickAction) {
        formController =
          endpoint.farm.visual_heat.form_without_animal(paramsData)
      } else {
        formController = !isUpdate
          ? endpoint.farm.visual_heat.form(paramsData)
          : endpoint.farm.visual_heat.update_form(paramsData)
      }
      break

    case eventCode.reproduction.heifer:
      formController = !isUpdate
        ? endpoint.farm.event.reproduction.heifer_register_form(paramsData)
        : endpoint.farm.event.reproduction.heifer_update_form(paramsData)
      break

    case eventCode.reproduction.induced_lactation:
      formController = !isUpdate
        ? endpoint.farm.induced_lactation.form(paramsData)
        : endpoint.farm.induced_lactation.update_form(paramsData)
      break

    case eventCode.reproduction.insemination:
      formController = !isUpdate
        ? endpoint.farm.insemination.form(paramsData)
        : endpoint.farm.insemination.update_form(paramsData)
      break

    case eventCode.reproduction.natural_breeding:
      formController = !isUpdate
        ? endpoint.farm.natural_breeding.form(paramsData)
        : endpoint.farm.natural_breeding.update_form(paramsData)
      break

    case eventCode.reproduction.pregnant:
      if (selectedAnimals) {
        formController = endpoint.farm.pregnancy.many.first(paramsData)
      }
      if (isUpdate) {
        formController = endpoint.farm.pregnancy.update_form(paramsData)
      }

      if (!isUpdate && !selectedAnimals) {
        formController = isQuickAction
          ? endpoint.farm.pregnancy.form_without_animal(paramsData)
          : endpoint.farm.pregnancy.form(paramsData)
      }

      break

    case eventCode.reproduction.pregnant_pre_delivery:
      formController = !isUpdate
        ? endpoint.farm.pre_calving.form(paramsData)
        : endpoint.farm.pre_calving.update_form(paramsData)
      break

    case eventCode.reproduction.weaning:
      formController = !isUpdate
        ? endpoint.farm.weaning.form(paramsData)
        : endpoint.farm.weaning.update_form(paramsData)
      break

    default: //nothing to moo
  }

  const loadForm = (params = {}) =>
    dispatch(
      loadFormFields({
        name: formName,
        controller: formController,
        params: {
          ...params,
          ...getAnimals(),
        },
      }),
    )

  useEffect(() => {
    loadForm()
    dispatch(resetSaveEvent({ name: saveEventName }))
    return () => dispatch(resetFormFields({ name: formName }))
  }, [dispatch, formController, formName, saveEventName, selectedAnimals])

  const getAnimals = values => {
    if (isQuickAction && values?.slug) {
      return { animals: [values.slug] }
    }
    if (!animalSlug && selectedAnimals) {
      return { animals: selectedAnimals.map(animal => animal.slug) }
    }
    if (animalSlug && !values) {
      return { animal: animalSlug }
    }

    return {}
  }

  if (!isEmpty(formData) && formStatus === 'done') {
    const handleSubmitForm = values => {
      /**
       * GAMBIARRA FEIA PRA FAZER O QUICKACTIONS DO MUCCILLO
       * Altera o endopoint de salvamento com o animal vindo do formulário
       */
      if (isQuickAction && values?.slug) {
        /* CIO VISUAL / SECAGEM / ABORTO  */
        saveEventController = endpoint.farm.animal.event.reproduction_event({
          farm: farmSlug,
          animal: values?.slug,
        })
      }

      dispatch(
        saveEvent({
          method: isUpdate ? 'put' : 'post',
          name: saveEventName,
          controller: saveEventController,
          params: {
            ...values,
            ...getAnimals(values),
          },
          retryOnConflict: true,
          // checks if register-new-animal button will appear in the calving success dialog
          actionsOnSuccess: !isUpdate &&
            eventType === eventCode.reproduction.calving &&
            values.calf_status !== 'stillborn' && {
              asCreate: true,
              btnClick: () => {
                onSuccessAction()
                dispatch(toggleDialog({ name: saveEventName })) // close success dialog
              },
              btnProps: {
                variant: 'extended',
                color: 'primary',
                size: 'medium',
              },
              title: Translate({ messageKey: 'register_new_animal' }),
            },
        }),
      )
    }

    return (
      <FormBuilder
        formData={formData}
        formError={formError}
        formStatus={formStatus}
        onSubmit={handleSubmitForm}
        submittingStatus={eventStatus}
        formEvents={{ resourceForm: params => loadForm(params) }}
      />
    )
  }
  return formStatus ? <LinearIndeterminate status={formStatus} /> : null
}

export default ReproductionEventsForm
