/* eslint-disable no-unsafe-optional-chaining */

import { fetcher } from '@chipinside/fetcher'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import omit from 'lodash/omit'
import unionBy from 'lodash/unionBy'
import uniqBy from 'lodash/uniqBy'

const defaultState = {}

const errorDefault = ''

async function fetchDatagrid(controller, params, options) {
  const { data: response } = await fetcher({
    controller,
    params,
    options,
  }).catch(error => console.error(error))

  const {
    data: list,
    meta: {
      columns: cols,
      filters,
      search,
      current_page: currentPage,
      last_page: lastPage,
      per_page: rowsPerPage,
      to,
      total,
      bulk_actions,
    },
    summary,
  } = response

  /**
   * Reorder columns to visible first
   * nothing change just reorder for easy priority
   */
  const columns = [
    ...cols.filter(c => c.visible),
    ...cols.filter(c => !c.visible),
  ]

  return {
    list,
    columns,
    filters,
    search,
    summary,
    bulk_actions,
    pagination: {
      firstPage: 0,
      currentPage: currentPage - 1, // first page api is 1 and in container is 0
      lastPage: lastPage - 1, // first page api is 1 and in container is 0
      rowsPerPage,
      to,
      total,
    },
  }
}

export const loadDatagrid = createAsyncThunk(
  'datagrid/loadDatagrid',
  async (payload, thunkAPI) => {
    let {
      controller,
      name,
      withInfinitePagination = false,
      params,
      options = {},
    } = payload

    if (!isEmpty(controller)) {
      try {
        let mergedFilters = params?.filters // default values for filters

        /**
         * If table hasSupport to URI filters
         * Use defaultParams={{ uriFilters: true }} as a prop
         * in <DatagridContainer> to enable URI filters
         * Default value is false
         **/
        if (params?.uriFilters && window.location.search.length) {
          let result = []

          const paramsFromUrl = new URLSearchParams(window.location.search)

          for (const [name, value] of paramsFromUrl) {
            result.push({ name, value: value || false })
          }

          mergedFilters = unionBy(result, params.filters, 'name').filter(
            item => !!item.value,
          )
        }

        const paramsFromController = controller.split('?')

        if (
          paramsFromController.length > 1 &&
          Array.isArray(params.filters) &&
          params.filters.length
        )
          controller = paramsFromController[0]

        let mergedParameters = { ...params, filters: mergedFilters }

        // remove uriFilters param from request
        delete mergedParameters.uriFilters

        const fetchedData = await fetchDatagrid(
          controller,
          mergedParameters,
          options,
        )

        const { list, pagination, columns, filters, search, summary } =
          isFunction(payload.manipulateDatagrid)
            ? payload.manipulateDatagrid(fetchedData)
            : fetchedData

        return {
          name,
          controller,
          list,
          pagination,
          columns,
          filters,
          search,
          summary,
          bulkActions: fetchedData.bulk_actions,
          refreshList: false,
          withInfinitePagination,
        }
      } catch (e) {
        return thunkAPI.rejectWithValue({
          name,
          controller,
          error: e.message,
          refreshList: false,
          withInfinitePagination,
        })
      }
    } else {
      return thunkAPI.rejectWithValue({
        name,
        controller,
        error: 'Datagrid not found',
        withInfinitePagination,
      })
    }
  },
)

const datagridSlice = createSlice({
  name: 'datagrid',
  initialState: defaultState,
  reducers: {
    reloadDatagrid(state, action) {
      state[action.payload?.name].refreshList = action.payload?.refreshList
    },
    resetDatagrid(state, action) {
      return omit(state, action.payload?.name)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadDatagrid.pending, (state, action) => {
        state[action.meta.arg.name] = {
          controller: action.meta.arg.controller,
          status: 'started',
          refreshList: action.meta.arg.refreshList,
          withInfinitePagination: action.meta.arg.withInfinitePagination,
          error: errorDefault,
          exporting: {},
          data: state?.[action.meta.arg.name]?.data ?? {},
        }
      })
      .addCase(loadDatagrid.fulfilled, (state, action) => {
        const list = action.payload?.withInfinitePagination
          ? uniqBy(
              [
                ...get(state[action.payload?.name].data, 'list', []),
                ...action.payload?.list,
              ],
              'slug',
            )
          : action.payload?.list
        state[action.payload?.name].status = 'done'
        state[action.payload?.name].data = {
          list,
          columns: action.payload?.columns,
          pagination: action.payload?.pagination,
          filters: action.payload?.filters,
          summary: action.payload?.summary,
          search: action.payload?.search,
          bulkActions: action.payload?.bulkActions,
        }
      })
      .addCase(loadDatagrid.rejected, (state, action) => {
        state[action.payload?.name].status = 'failed'
        state[action.payload?.name].error = action.payload?.error
      })
  },
})

export const { reloadDatagrid, resetDatagrid } = datagridSlice.actions

export const getDatagrid = (state, datagridName) =>
  state?.datagrid?.[datagridName] ?? {}

export default datagridSlice.reducer
