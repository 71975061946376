/* eslint-disable no-prototype-builtins */
import { fmt, Moment, Translate } from '@chipinside/frontend'
import { Paper } from '@mui/material'
import _get from 'lodash/get'
import _snackCase from 'lodash/snakeCase'
import React from 'react'

import FormatCell from '#/components/FormatCell'
import OutputFields from '#/components/OutputFields'
import Colors from '#/styles/Colors'
import OldColors from '#/styles/Old/Colors'
import plotToHtml from '#/utils/plotToHtml'
import stringIsTrue from '#/utils/stringIsTrue'

import classes from './PlotLines.module.css'
import styles from './styles'
import { basePlot, getPlotIconHtml, labelTextHandler } from './utils'

export default function AnimalEvents(events, startOf) {
  let groupedByDate = {}
  const formattedPlotLines = []
  if (events) {
    events.forEach(event => {
      const key = Moment(event.timestamp).startOf(startOf).format(fmt.api)

      if (!groupedByDate[key]) groupedByDate[key] = []
      groupedByDate[key] = [...groupedByDate[key], event]
    })

    Object.entries(groupedByDate).forEach((group, idx) => {
      const [date, events] = group

      events.forEach((event, eventIdx) => {
        if (event && event.virtual_type && event.virtual_type_text) {
          let type = _snackCase(event.virtual_type.split('.').slice(0, -1))
          if (
            type === 'reproduction_heat' &&
            event.hasOwnProperty('confirmed_as') &&
            event.confirmed_as !== null &&
            !stringIsTrue(event.confirmed_as)
          ) {
            type = 'reproduction_heat_negatively_confirmed'
          }
          const healthEvent = event?.parameters?.health_status
          const plotText = plotToHtml(formatPlotLabelText(event, healthEvent))
          const eventClass = event.virtual_type.split('.')[0]
          const plotId = `plot-line-id-${idx}`
          const plotClassName = `plot-line-${eventClass}`

          const timestamp = Moment(date).format(fmt.iso)
          formattedPlotLines.push({
            ...basePlot,
            className: `${plotId} transition-opacity-300ms ${plotClassName}`,
            value: Moment(timestamp).valueOf(),
            color: OldColors[healthEvent ?? type],
            label: {
              ...basePlot.label,
              y: events.length > 1 ? 50 * eventIdx : 0, // custom label height based on groups
              text: labelTextHandler({
                id: plotId,
                className: plotClassName,
                plotTextComponent: plotText,
              }),
            },
          })
        }
      })
    })
  }

  return formattedPlotLines
}

const formatPlotLabelText = (events, healthEvent) => {
  if (Array.isArray(events)) {
    // multiples plots on same date
    const multiPlots = []
    events.forEach(e => {
      multiPlots.push(renderPlot(e, healthEvent))
      multiPlots.push(<br key={`line-break-${e.slug}`} />)
    })
    return multiPlots
  }
  // one single plot
  return renderPlot(events, healthEvent)
}

const renderPlot = (event, healthEvent) => {
  const vtt = _get(event, 'virtual_type_text', '')
  let name = vtt.charAt(0)
  const vtSplit = event.virtual_type.split('.')
  let type = _snackCase(vtSplit.slice(0, -1))
  const eventInfos = renderEventInfos(event, type)

  switch (type) {
    case 'reproduction_implant_insertion':
    case 'reproduction_implant_removal':
    case 'reproduction_embryo_transfer':
      name += vtt.charAt(1)
      break
    case 'reproduction_pregnant':
      name += '+'
      break
    case 'reproduction_negative_pregnancy':
      name += '-'
      break
    case 'reproduction_implant_lost':
      name = Translate({ messageKey: 'implant_lost_initials' })
      break
    default: //nothing too moo
  }

  let customStyle = {}
  if (
    type === 'reproduction_heat' &&
    event.hasOwnProperty('confirmed_as') &&
    event.confirmed_as !== null &&
    !stringIsTrue(event.confirmed_as)
  ) {
    type = 'reproduction_heat_negatively_confirmed'
    customStyle = { textDecoration: 'line-through' }
  } else if (vtSplit.length > 2 && vtSplit[2] === 'pg') {
    type = 'reproduction_prostaglandin'
  }

  return (
    <div className={classes.headerContainer} key={`${type}-plot-${event.slug}`}>
      <div style={styles.labelContainer}>
        <span
          style={styles.icon}
          dangerouslySetInnerHTML={{
            __html: getPlotIconHtml(type, healthEvent),
          }}
        />
        <span
          style={{
            ...styles.name,
            backgroundColor: OldColors[healthEvent ?? type],
            ...customStyle,
          }}
        >
          {name}
        </span>
      </div>
      <Paper
        key={`${type}-plot-${event.slug}-popover`}
        className={classes.popover}
        elevation={2}
      >
        <p
          style={{
            margin: 0,
            fontSize: 16,
            lineHeight: 1.5,
            color: OldColors[healthEvent ?? type],
          }}
        >
          {event.virtual_type_text}
        </p>
        <p
          style={{
            margin: 0,
            marginBottom: 5,
            lineHeight: 1.5,
          }}
        >
          <FormatCell value={event.timestamp} type="timestamp" />
        </p>
        {eventInfos}
      </Paper>
    </div>
  )
}

const renderEventInfos = (event, type) => {
  let fieldsArray = []
  const fromAlgorithm = _get(event, 'creator_type') === 'algorithm'
  if (type.match(/reproduction_.*/)) {
    fieldsArray = [
      {
        label: fromAlgorithm
          ? Translate({ messageKey: 'detected_at' })
          : Translate({ messageKey: 'registered_at_date' }),
        value: fromAlgorithm
          ? Moment.view(event.parameters.detected_at)
          : Moment.view(event.created_at),
      },
      {
        label: fromAlgorithm ? Translate({ messageKey: 'validated_at' }) : null,
        value: fromAlgorithm ? Moment.view(event.created_at) : null,
      },
      {
        label: fromAlgorithm
          ? Translate({ messageKey: 'registered_by' })
          : Translate({ messageKey: 'user_name' }),
        value: fromAlgorithm ? 'VIC' : event?.creator?.name,
      },
      {
        label: Translate({ messageKey: 'DEL' }),
        value: event?.parameters?.DEL ? event.parameters.DEL.toString() : null,
      },
      {
        label: Translate({ messageKey: 'detection_method' }),
        value: event?.parameters?.heat_detection_method_text ?? null,
      },
      {
        label: Translate({ messageKey: 'intensity' }),
        value: event?.parameters?.heat_intensity_text ?? null,
      },
      {
        label: Translate({ messageKey: 'signs' }),
        value: event?.parameters?.heat_signs_text
          ? event?.parameters?.heat_signs_text.join(', ')
          : null,
      },
      {
        label: Translate({ messageKey: 'bull_or_semen' }),
        value: event?.semen?.war_name || event?.parameters?.bull_semen,
      },
      {
        label: Translate({ messageKey: 'donor' }),
        value: event?.parameters?.donor,
      },
      {
        label: Translate({ messageKey: 'inseminator' }),
        value: event?.inseminator?.name,
      },
      {
        label: Translate({ messageKey: 'comment' }),
        value: event?.parameters?.comment,
      },
      {
        label: Translate({ messageKey: 'gender' }),
        value: event?.parameters?.gender
          ? Translate({ messageKey: event.parameters.gender })
          : null,
      },
      {
        label: Translate({ messageKey: 'calving_procedure' }),
        value: event?.calving_procedure_text,
      },
    ]
  } else if (type.match(/handling_.*/) || type.match(/rumination_.*/)) {
    fieldsArray = [
      {
        label: fromAlgorithm
          ? Translate({ messageKey: 'detected_at' })
          : Translate({ messageKey: 'registered_at_date' }),
        value: Moment.view(event.confirmed_at) || '-',
      },
      {
        label: Translate({ messageKey: 'user_name' }),
        value: event?.creator?.name,
      },
      {
        label: Translate({ messageKey: 'description' }),
        value: event?.parameters?.description,
      },
    ]
  } else if (type.match(/health_.*/)) {
    fieldsArray = [
      {
        label: Translate({ messageKey: 'health_status' }),
        value: (
          <span
            style={{
              fontWeight: 700,
              color:
                OldColors[event.parameters.health_status] ??
                Colors.health[event.parameters.health_status]?.color,
            }}
          >
            {event.health_status_text}
          </span>
        ),
      },
      {
        label: fromAlgorithm
          ? Translate({ messageKey: 'detected_at' })
          : Translate({ messageKey: 'registered_at_date' }),
        value: fromAlgorithm
          ? Moment.view(event.parameters.detected_at)
          : Moment.view(event.created_at),
      },
      {
        label: fromAlgorithm ? Translate({ messageKey: 'processed_at' }) : null,
        value: fromAlgorithm ? Moment.view(event.created_at) : null,
      },
      {
        label: Translate({ messageKey: 'user_name' }),
        value: fromAlgorithm ? 'VIC' : event?.creator?.name,
      },
      {
        label: Translate({ messageKey: 'DEL' }),
        value: event.parameters.DEL ? event.parameters.DEL.toString() : null,
      },
      {
        label: Translate({ messageKey: 'PS' }),
        value: event.parameters.observation,
      },
      {
        label: Translate({ messageKey: 'diagnostic' }),
        value: event?.diagnostic?.name_text,
      },
      {
        label: Translate({ messageKey: 'treatment' }),
        value: event.treatment,
      },
    ]
  }
  return <OutputFields direction="row" fields={fieldsArray} />
}
