import { PageBuilderElements } from '@chipinside/pagebuilder'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import React, { useState } from 'react'

import { BasicSelector } from '#/components/PageBuilder/Components/Tabs/BasicSelector'

const StyledBoxTab = styled(Box)(({ theme }) => ({
  borderBottom: 1,
  borderColor: 'divider',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    display: 'block',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}))

export function PageBuilderTabs(props) {
  const { tabs, defaultTab, globalFilter } = props
  const [currentFilter, setCurrentFilter] = useState([
    { name: globalFilter?.name, value: globalFilter?.default },
  ])

  const hasGlobalFilters = globalFilter?.values?.length > 0

  const [value, setValue] = useState(defaultTab)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const handleFilterChange = filterValue => {
    setCurrentFilter([{ name: filterValue.name, value: filterValue.value }])
  }

  return (
    <TabContext value={value}>
      <StyledBoxTab>
        <TabList onChange={handleChange}>
          {tabs.map(tab => (
            <Tab key={tab.id} label={tab.label} value={tab.id} />
          ))}
        </TabList>
        {hasGlobalFilters && (
          <BasicSelector filters={globalFilter} onSelect={handleFilterChange} />
        )}
      </StyledBoxTab>
      {tabs.map(tab => {
        return (
          <TabPanel key={tab.id} value={tab.id} sx={{ p: 0 }}>
            <PageBuilderElements
              elements={tab.elements}
              params={{ filters: currentFilter }}
              onChangeFilters={props.onChangeFilters}
            />
          </TabPanel>
        )
      })}
    </TabContext>
  )
}
