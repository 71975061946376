import { List, ListItem } from '@mui/material'
import { uniqueId } from 'lodash'
import React from 'react'

import useStyles from './styles'

const DatagridCardInfo = ({ summary }) => {
  const classes = useStyles()
  return (
    <div className={classes.Card}>
      {summary.map(info => {
        return (
          <List className={classes.List} key={info.id} dense>
            <p className={classes.ListTitle} key={`${info.id}-title`}>
              {info.title}
            </p>
            {info.data.map(value => {
              if (value.type === 'separator') {
                return <hr key={uniqueId('separator')} />
              }
              if (value.type === 'section') {
                return (
                  <ListItem className={classes.ListItem} key={uniqueId()}>
                    <p className={classes.ListSection}>{value.title}</p>
                  </ListItem>
                )
              }
              return (
                <ListItem className={classes.ListItem} key={uniqueId()}>
                  <p className={classes.ItemTitle}>{value.title}</p>
                  <p className={classes.ItemValue}>{value.value}</p>
                </ListItem>
              )
            })}
          </List>
        )
      })}
    </div>
  )
}

export default DatagridCardInfo
