export const BarOptions = {
  title: null,
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  chart: {
    type: 'bar',
    events: {
      load() {
        // Obtém a largura e os dados das series do gráfico
        let { chartWidth, options } = this
        // Calcula a altura com base no número de dados no series
        let chartHeight = (options?.series[0]?.data?.length ?? 1) * 40

        // Define a altura do gráfico
        this.setSize(chartWidth, chartHeight, false)
      },
    },
  },
  tooltip: {
    formatter() {
      return (
        `<span style="color:${this.point?.color}">\u25CF</span> ` +
        `<b>${this.category}: ${this.y}</b>`
      )
    },
  },
  xAxis: {
    title: {
      text: null,
    },
    tickWidth: undefined,
    tickmarkPlacement: undefined,
    gridLineWidth: 0,
    lineWidth: 0,
    labels: {
      y: undefined,
      style: {
        color: '#666',
        fontSize: 14,
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: null,
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 0,
  },
  plotOptions: {
    bar: {
      cursor: 'pointer',
      borderRadius: 5,
      pointWidth: 20,
      dataLabels: {
        enabled: true,
        formatter() {
          return `<span>${this.y}${this.point.suffix || ''}</span>`
        },
      },
    },
  },
}
