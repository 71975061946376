import Box from '@mui/material/Box'
import React from 'react'

const Flex = ({ children, ...rest }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    {...rest}
  >
    {children}
  </Box>
)

export default Flex
