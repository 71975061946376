import { Translate } from '@chipinside/frontend'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import IconButton from '@mui/material/IconButton'
import TablePagination from '@mui/material/TablePagination'
import { withStyles } from '@mui/styles'
import React from 'react'

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(5),
  },
})

const Pagination = props => {
  const {
    rowsPerPageOptions,
    firstPage = 0,
    lastPage = 0,
    currentPage = 0,
    rowsPerPage = 0,
    total = 0,
    onChangePage = () => {},
    onChangeRowsPerPage = () => {},
  } = props

  const PaginationActions = actionProps => {
    const { classes, theme } = actionProps
    return (
      <div className={classes.root}>
        <IconButton
          onClick={() => onChangePage(firstPage)}
          disabled={currentPage === firstPage}
          aria-label={Translate({ messageKey: 'first_page' })}
          size="large"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={() => onChangePage(currentPage - 1)}
          disabled={currentPage === firstPage}
          aria-label={Translate({ messageKey: 'previous_page' })}
          data-cy="datagrid-previous-page-btn"
          size="large"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={() => onChangePage(currentPage + 1)}
          disabled={currentPage >= lastPage}
          aria-label={Translate({ messageKey: 'next_page' })}
          data-cy="datagrid-next-page-btn"
          size="large"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={() => onChangePage(lastPage)}
          disabled={currentPage >= lastPage}
          aria-label={Translate({ messageKey: 'last_page' })}
          size="large"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
  }
  const PaginationActionsWrapped = withStyles(actionsStyles, {
    withTheme: true,
  })(PaginationActions)

  return (
    <TablePagination
      aria-label={Translate({ messageKey: 'datagrid_results_per_page' })}
      component="div"
      data-cy="pagination-footer-container"
      labelDisplayedRows={({ from, to, count: total }) =>
        Translate({
          messageKey: 'datagrid_records_info',
          params: {
            from,
            to,
            total,
          },
        })
      }
      labelRowsPerPage={Translate({
        messageKey: 'datagrid_results_per_page',
      })}
      rowsPerPageOptions={rowsPerPageOptions}
      SelectProps={{
        'data-cy': 'results-per-page-select',
      }}
      count={total}
      rowsPerPage={rowsPerPage || 15} // fix 0 warning
      page={currentPage}
      onPageChange={() => {}}
      onRowsPerPageChange={event => {
        onChangeRowsPerPage(event.target.value)
      }}
      ActionsComponent={PaginationActionsWrapped}
    />
  )
}

export default Pagination
