import { Moment } from '@chipinside/frontend'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

import NewBadge from '#/components/badges/NewBadge'
import Colors from '#/styles/Colors'

const Wrapper = styled('div')(({ bold, level }) => ({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  fontWeight: bold ? 700 : 400,
  color: Colors.feedback[level]?.color,
}))

const Badge = styled(NewBadge)({
  display: 'flex',
  alignItems: 'center',
  marginTop: 0,
})

const TypeTimestamp = ({
  value,
  withBadge,
  format = 'L LT',
  children,
  customStyle,
  level,
  bold,
}) => {
  if (!value) return <span>-</span>

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Wrapper style={customStyle} level={level} bold={bold}>
        {Moment(value).format(format)}
      </Wrapper>
      {withBadge && <Badge timestamp={value} />}
      <span>{children}</span>
    </Stack>
  )
}
export default TypeTimestamp
