import { HeatStrengthIcon } from '@chipinside/frontend'
import { Translate } from '@chipinside/frontend'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CSSProperties } from 'react'

const Label = styled('p')({
  margin: 0,
  lineHeight: '16px',
  fontWeight: 500,
  color: '#666',
})

const Content = styled('div')(
  ({ orientation }: { orientation: TypeHeatStrengthProps['orientation'] }) => ({
    display: 'flex',
    flexDirection: orientation,
    alignItems: 'center',
    columnGap: 8,
  }),
)

export type HeatStrength = {
  status: 1 | 2 | 3 | 4 | 5
  text: string
}

export type TypeHeatStrengthProps = {
  value: HeatStrength
  orientation: CSSProperties['flexDirection']
  textStyle: CSSProperties
}

const TypeHeatStrength = ({
  value,
  orientation = 'column',
  textStyle,
}: TypeHeatStrengthProps) => {
  const { status, text } = value ?? {}
  if (!status) return <span>-</span>

  return (
    <Tooltip title={`${Translate({ messageKey: 'heat_strength' })} - ${text}`}>
      <Content orientation={orientation}>
        <HeatStrengthIcon status={status} style={{ fontSize: 30 }} />
        <Label style={textStyle}>{text}</Label>
      </Content>
    </Tooltip>
  )
}

export { TypeHeatStrength }
