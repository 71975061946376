import { TipIcon } from '@chipinside/frontend'
import Close from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import { styled } from '@mui/material/styles'
import React from 'react'

import Colors from '#/styles/Colors'
import { HSLDarker } from '#/utils/colorManipulation'

const StyledIconButton = styled(IconButton)(({ level }) => ({
  ...Colors.feedback?.[level],
  padding: 8,
}))

const StyledPopover = styled(`div`)(({ level }) => ({
  maxWidth: 300,
  padding: '20px 30px 20px 20px',
  overflowWrap: 'break-word',
  ...Colors.feedback?.[level],
}))

const CloseIcon = styled(Close)(({ level }) => ({
  'position': 'absolute',
  'top': '5px',
  'right': '5px',
  'cursor': 'pointer',
  'fontSize': 16,
  '&:hover': {
    color: HSLDarker(Colors.feedback?.[level]?.color, 10),
  },
}))

export default function TypeInsights({ value, level }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return value ? (
    <div>
      <StyledIconButton
        level={level}
        size="small"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <TipIcon fontSize={20} />
      </StyledIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledPopover level={level}>
          {value}
          <CloseIcon level={level} onClick={handleClose} />
        </StyledPopover>
      </Popover>
    </div>
  ) : (
    <span>-</span>
  )
}
