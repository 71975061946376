import { KeyboardArrowDown } from '@mui/icons-material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'

const StyledBox = styled(Box)(({ theme }) => ({
  minWidth: 200,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
}))

export function BasicSelector({ filters, onSelect }) {
  const [filter, setFilter] = useState(filters.default)

  const handleChange = event => {
    const filterValue = event.target.value
    setFilter(filterValue)
    onSelect({ value: filterValue, name: filters.name })
  }

  return (
    <StyledBox>
      <FormControl fullWidth size="small">
        <Select
          labelId="tabs-filter-select"
          IconComponent={KeyboardArrowDown}
          id="tabs-filter-select"
          value={filter}
          onChange={handleChange}
          sx={{ borderRadius: '12px' }}
        >
          {filters?.values?.map(filter => (
            <MenuItem key={filter.value} value={filter.value}>
              {filter.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledBox>
  )
}
