import { Box, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { Link } from 'react-router-dom'

import Colors from '#/styles/Colors'

const StyleValue = styled(`p`)(({ font }) => ({
  fontWeight: '700',
  letterSpacing: '0.5px',
  margin: 0,
  ...(font === 'small' && {
    lineHeight: '15px',
    fontSize: '15px',
  }),
  ...(font === 'normal' && {
    lineHeight: '24px',
    fontSize: '24px',
  }),
  ...(font === 'large' && {
    lineHeight: '32px',
    fontSize: '32px',
  }),
}))

const Content = styled(Box)(({ position, level, disabled }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'flexDirection': 'column',
  'gap': 8,
  'flexGrow': 1,
  'padding': '16px',
  'borderRadius': '16px',
  'justifyContent': position,
  'height': '100%',
  'backgroundColor': Colors.feedback?.[level]?.backgroundColor,
  '& p': {
    color: disabled ? '#CCC' : Colors.feedback?.[level]?.color,
  },
}))

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
})

const Variation = styled(Chip)(({ variationlevel = 'success' }) => {
  const VariationColor = {
    ...Colors.health,
    ...Colors.feedback,
  }

  return {
    'height': '18px',
    'backgroundColor': VariationColor?.[variationlevel]?.backgroundColor,
    '& .MuiChip-label': {
      color: VariationColor?.[variationlevel]?.color,
      fontSize: '12px',
      padding: '0 8px',
    },
  }
})

const Label = styled(`p`)({
  textAlign: 'center',
  fontSize: '14px',
  letterSpacing: '0.5px',
  margin: 0,
})

const StyledLink = styled(Link)({
  'borderRadius': '16px',
  'display': 'block',
  '&:hover': {
    background: 'rgba(0,0,0,0.03)',
  },
})

const Wrapper = ({ link, children }) => {
  if (link) {
    return <StyledLink to={link}>{children}</StyledLink>
  }
  return children
}

export const PageBuilderCard = props => {
  const {
    label,
    value,
    variation,
    font = 'normal',
    position = 'center',
    variationLevel,
    link,
    level,
    sx,
    id,
    disabled,
  } = props

  return (
    <Wrapper link={link}>
      <Content
        disabled={disabled}
        position={position}
        level={level}
        sx={{ ...sx }}
        id={id}
      >
        <StyledBox>
          <StyleValue font={font}>{value ?? '-'}</StyleValue>
          {variation ? (
            <Variation label={variation} variationlevel={variationLevel} />
          ) : null}
        </StyledBox>
        <Label>{label}</Label>
      </Content>
    </Wrapper>
  )
}
