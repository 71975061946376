import { Translate } from '@chipinside/frontend'
/**
 * Função de manipulação da exportação
 *
 * @param exportable Valor Booleano
 * @return Retorna dados caso o valor seja true
 */
export default function exportableFormatter(exportable, exporting, refresh) {
  if (exportable) {
    return {
      exporting: {
        enabled: true,
        printMaxWidth: 1920,
        menuItemDefinitions: {
          downloadXLSX: {
            onclick: exporting,
            text: Translate({
              messageKey: 'download_spreadsheet',
              params: { item: 'XLSX' },
            }),
          },
        },
        buttons: {
          contextButton: {
            menuItems: ['printChart', 'downloadXLSX'],
          },
          ...refresh,
        },
      },
    }
  }
  return {
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['printChart'],
        },
        ...refresh,
      },
    },
  }
}
