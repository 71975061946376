import { Translate, WaitingIcon } from '@chipinside/frontend'
import { Stack, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const Wrapper = styled(Stack)(({ gap }) => ({
  'flexDirection': 'row',
  'alignItems': 'center',
  'flexWrap': 'wrap',
  'gap': gap,
  '& *': {
    margin: '0 !Important',
  },
}))

const TypeReproduction = ({ value, contrastColor, ...rest }) => {
  if (!value) return '-'
  const { status, text, protocol } = value

  let statusStyle

  switch (status) {
    case 'pregnant':
      statusStyle = Colors.reproduction.pregnant
      break

    case 'empty':
      statusStyle = Colors.reproduction.empty
      break

    case 'empty-post-delivery':
      statusStyle = Colors.reproduction.emptyPostDelivery
      break

    case 'pregnant-pre-delivery':
      statusStyle = Colors.reproduction.pregnantPreDelivery
      break

    case 'inseminated':
      statusStyle = Colors.reproduction.inseminated
      break

    case 'late-inseminated':
      statusStyle = Colors.reproduction.lateInseminated
      break

    case 'calf-new-born':
      statusStyle = Colors.reproduction.calfNewBorn
      break

    case 'calf':
      statusStyle = Colors.reproduction.calf
      break

    case 'heifer':
      statusStyle = Colors.reproduction.heifer
      break

    case 'male':
      statusStyle = Colors.reproduction.male
      break

    default: //nothing to moo but colors
  }

  return (
    <Wrapper gap={8}>
      <CowmedChip value={text} customStyle={statusStyle} {...rest} />
      {protocol && (
        <ProtocolStatus {...protocol} contrastColor={contrastColor} />
      )}
    </Wrapper>
  )
}

/**
 * Renderiza o protocolo com um tooltip exibindo a informação de fase do protocolo.
 *
 * @param {string} status - Refere-se a fase atual do protocolo: waiting-service, synchronized...
 * @param {string} text - Refere-se a tradução do status: Aguardando, Sincronizando...
 * @param {string} code - Refere-se ao tipo de protocolo, IATF, TETF, PG.
 * @returns {JSX.Element} - Retorna o JSX que representa o protocolo com tooltip.
 */
function ProtocolStatus({ status, text, code, contrastColor }) {
  if (!status) return null
  return (
    <Tooltip
      title={
        <div>
          {Translate({
            messageKey: ['animal_in_protocol', code],
            translate: [true, false],
            separator: ': ',
          })}
          <br />
          {text}
        </div>
      }
    >
      <Wrapper
        style={contrastColor ? {} : { color: Colors.feedback.warning.color }}
      >
        {status === 'scheduled' ? <WaitingIcon /> : null}
        <span>{code}</span>
      </Wrapper>
    </Tooltip>
  )
}

export default TypeReproduction
