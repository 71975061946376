import { Translate } from '@chipinside/frontend'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import isFunction from 'lodash/isFunction'
import React from 'react'

import Modal from '#/components/Modal'

import DatagridCell from '../DatagridCell'

const styles = {
  cellStyle: {
    borderColor: 'transparent',
  },
  tableCellStyleTitle: {
    width: '40%',
  },
  tableCellStyleValue: {
    width: '60%',
  },
}

const createModalContent = (
  rowEnabledColumns = new Map(),
  row,
  withoutData = '-',
) => {
  const infos = []

  rowEnabledColumns.forEach((value, head) => {
    infos.push({ head, value })
  }, rowEnabledColumns)

  return (
    <div>
      <Table>
        <TableBody>
          {infos.map((item, index) => {
            const hasRenderCell = isFunction(item.head.renderCell)
            const params = hasRenderCell
              ? item.head.renderCell(item.value, row, item.head)
              : {}

            if (item.head.bodyStyle) {
              params.style = { ...item.head.bodyStyle, ...params.style }
            }

            return (
              <TableRow key={index}>
                <TableCell style={styles.tableCellStyleTitle}>
                  <DatagridCell
                    cellStyle={styles.cellStyle}
                    component={item.head.title}
                  />
                </TableCell>
                <TableCell style={styles.tableCellStyleValue}>
                  <DatagridCell
                    cellStyle={{ ...styles.cellStyle, ...params.style }}
                    component={hasRenderCell ? params.value : item.value}
                    withoutData={params.withoutData || withoutData}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

const ModalMore = props => {
  const { rowEnabledColumns, row, withoutData } = props
  return (
    <Modal
      element={createModalContent(rowEnabledColumns, row, withoutData)}
      open={props.open}
      onClose={props.onClose}
      title={Translate({ messageKey: 'more_details' })}
      fullWidth
    />
  )
}

export default ModalMore
