import React from 'react'

import useStyles from './styles'

const OutputFields = props => {
  const {
    fields = [],
    style = {},
    className = '',
    direction = 'column',
    margin = true,
    gutters = true,
  } = props

  const classes = useStyles()

  switch (direction) {
    case 'column':
      return (
        <div
          className={[classes.outputFields, className].join(' ')}
          style={style}
        >
          {!!fields &&
            fields.map((s, i) => (
              <div
                key={`output_field_${i}`}
                style={{ margin: margin ? '5px 10px 7px' : 0 }}
              >
                {s.label && (
                  <label
                    className={classes.label}
                    style={!gutters ? { margin: 0 } : {}}
                  >
                    {s.label}
                  </label>
                )}
                <span>{s.value}</span>
              </div>
            ))}
        </div>
      )
    case 'row':
      return (
        <div
          className={[classes.outputFieldsVertical, className].join(' ')}
          style={style}
        >
          {!!fields &&
            fields.map(
              (item, i) =>
                item.value &&
                item.value !== '-' && (
                  <div
                    key={`output_field_${i}`}
                    style={{ marginBottom: margin ? 4 : 0 }}
                  >
                    <span>
                      {item.label && <b>{item.label}: </b>}
                      {item.value}
                    </span>
                  </div>
                ),
            )}
        </div>
      )
    default: //nothing to moo
  }
}

export default OutputFields
