import { fetcher } from '@chipinside/fetcher'
import { Moment, Translate } from '@chipinside/frontend'
import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import MarkdownView from 'react-showdown'

import { endpoint } from '#/store/services/endpoints'
import tryCache from '#/utils/tryCache'

const style = {
  root: {
    textAlign: 'left',
  },
  box: {
    width: '100%',
    marginBottom: 20,
  },
  title: {
    margin: 0,
  },
}

export default function ReleaseTab(props) {
  const {
    data,
    status,
    farm,
    setHasNewRelease,
    setIsOpen,
    context,
    viewed_last_release_note,
  } = props
  /**
   * Show Orange Gift if not click in "Ok, Entendi"
   */
  useEffect(() => {
    if (
      viewed_last_release_note === null ||
      viewed_last_release_note?.viewed === false
    ) {
      //Set Icon Orange
      setHasNewRelease(true)
    }
  }, [viewed_last_release_note])

  /**Release Buttons Function */
  function FooterClick(note, type) {
    if (note) {
      const controller =
        type === 'read-later'
          ? endpoint.system.release_notes.read_later({ note })
          : endpoint.system.release_notes.view({ note })

      try {
        fetcher({ method: 'put', controller }).then(() => {
          /** Clear Header Cache */
          tryCache.clear(`notification-list-${farm ?? context}-header`)
        })

        /**
         * Gray only if click in "Ok Entendi"
         */
        if (!type) {
          setHasNewRelease(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    setIsOpen(false)
  }

  const FormattedRelease = () =>
    data ? (
      <div style={style.root}>
        <Box style={style.box}>
          <h2 style={style.title}>
            {Translate({ messageKey: 'your_software_was_updated' })}
          </h2>
          <small>
            {Translate({ messageKey: 'version_updated_at' })}
            {' : '}
            {Moment(data.release_date).format('L')}
          </small>
        </Box>
        <Divider />
        <MarkdownView markdown={data.release_note} />
        <MarkdownView markdown={data.coming_soon} />
      </div>
    ) : (
      <Typography color="textSecondary" component="p" style={{ padding: 20 }}>
        {Translate({ messageKey: 'without_information' })}
      </Typography>
    )

  return {
    key: 'release',
    endpoint: endpoint.system.release_notes.last(),
    onClose: id => FooterClick(id, 'read-later'),
    footer: [
      {
        text: Translate({ messageKey: 'read_later' }),
        onClick: () => FooterClick(data?.id, 'read-later'),
      },
      {
        text: Translate({ messageKey: 'i_got_it' }),
        onClick: () => FooterClick(data?.id),
      },
    ],
    content: status !== 'done' ? <LinearProgress /> : <FormattedRelease />,
  }
}
