export const HighchartsTheme = () => {
  return {
    chart: {
      zoomType: 'x',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Roboto',
      },
    },
    accessibility: { enabled: false },
    credits: { enabled: false },
    plotOptions: {
      series: {
        turboThreshold: 0,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    xAxis: {
      labels: {
        style: {
          textTransform: 'capitalize',
        },
      },
    },
    colors: [
      '#7cb5ec', // azul claro
      '#434348', // cinza escuro
      '#90ed7d', // verde claro
      '#f7a35c', // laranja claro
      '#8085e9', // roxo claro
      '#f15c80', // vermelho claro
      '#e4d354', // amarelo
      '#2b908f', // teal
      '#f45b5b', // vermelho
      '#91e8e1', // cyan
    ],
  }
}
