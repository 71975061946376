/**
 * Baseado nesse link
 * https://www.codegrepper.com/code-examples/javascript/check+if+browser+is+safari+or+opera
 */

const isSafari = () => {
  return navigator.userAgent.indexOf('Safari') !== -1
}

/* 
  Function used in graphics for correction in safari browsers
*/

export default function dateFormatSafari(value) {
  //Return if timestamp
  if (typeof value !== 'string' || !isSafari()) return value

  //replace '-' to '/', fix safari Date function
  const date = value.split(' ')
  const dateFormated = date[0].replace(new RegExp('-', 'gm'), '/')
  return `${dateFormated} ${date[1] ? date[1] : ''}`
}
