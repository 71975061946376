import { FormBuilderProvider } from '@chipinside/formbuilder'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { FormBuilderComponents } from '#/components/FormControl/Components'
import { FormBuilderFields } from '#/components/FormControl/Fields'
import { FormBuilderValidations } from '#/components/FormControl/Validations'
import store, { persist } from '#/store/configureStore'
import { ChartBuilderProvider } from '#/store/providers/Chartbuilder'
import { LocalizationProviderConfig } from '#/store/providers/LocalizationProviderConfig'
import { PageBuilderProvider } from '#/store/providers/PagebuilderProvider'
import { ThemeProvider } from '#/store/providers/ThemeProvider'

// Componente Providers: Envolve a aplicação com todos os provedores necessários
export function Providers(props) {
  return (
    // O Provider do Redux fornece o estado da loja para toda a aplicação
    <Provider store={store}>
      {/* PersistGate garante que a persistência do estado do Redux seja reidratada antes de renderizar a aplicação */}
      <PersistGate persistor={persist()} loading={false}>
        <ThemeProvider>
          {/* LocalizationProviderConfig envolve a aplicação com o contexto de localização */}
          <LocalizationProviderConfig>
            {/* StyledEngineProvider garante que os estilos do Material-UI sejam aplicados corretamente */}
            <FormBuilderProvider
              types={FormBuilderFields}
              components={FormBuilderComponents}
              validations={FormBuilderValidations}
            >
              <ChartBuilderProvider>
                <PageBuilderProvider>
                  {/* Renderiza os componentes filhos */}
                  {props.children}
                </PageBuilderProvider>
              </ChartBuilderProvider>
            </FormBuilderProvider>
          </LocalizationProviderConfig>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}
