import { Translate } from '@chipinside/frontend'
import Checkbox from '@mui/material/Checkbox'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import React, { Component } from 'react'

import DatagridCell from '#/components/datagrid/DatagridCell'

import classes from './DatagridHeaderContainer.module.css'

const styles = {
  firstColumn: {
    paddingLeft: '12px',
  },
}

class DatagridHeaderContainer extends Component {
  constructor(props) {
    super(props)

    this.onClickCell = this.onClickCell.bind(this)
    this.orderByColumn = this.orderByColumn.bind(this)
  }

  onClickCell({ head }) {
    if (head.orderable) {
      this.orderByColumn(head)
    }
  }

  orderByColumn(head) {
    const { columns, orderChanged = () => {} } = this.props

    let order = columns
      .filter(column => !isEmpty(column.order))
      .map(item => item.order)

    let orderThisColumn = find(order, { column: head.ref })

    order = order.filter(order => order.column !== head.ref)
    order = sortBy(order, 'priority').map((item, index) => {
      const auxOrder = { ...item }
      auxOrder.priority = index
      return auxOrder
    })

    if (isEmpty(orderThisColumn)) {
      orderThisColumn = {
        column: head.ref,
        direction: 'asc',
        priority: order.length,
      }
    } else {
      orderThisColumn = {
        column: head.ref,
        direction: orderThisColumn.direction === 'asc' ? 'desc' : null,
        priority: orderThisColumn.direction === 'asc' ? order.length : null,
      }
    }
    if (orderThisColumn.direction) {
      order.push(orderThisColumn)
    }

    orderChanged(order)
  }

  render() {
    const {
      withDetails,
      bulkActions,
      onSelectAllRows,
      rowsCount,
      cellClass,
      cellStyle = {},
      visibleColumns = [],
      invisibleColumns = [],
      actionsEnabled = () => {},
    } = this.props

    const response = []

    const themeCell = classes.light

    if (withDetails) {
      response.push(
        <DatagridCell
          key="details"
          cellClass={[classes.cell, themeCell, cellClass].join(' ')}
          cellStyle={{ ...cellStyle }}
          empty
        />,
      )
    }

    if (!!bulkActions && !isEmpty(bulkActions)) {
      const rowsSelected = Array.isArray(bulkActions.selectedRows)
        ? bulkActions.selectedRows.length
        : 0
      response.push(
        <DatagridCell
          key="select-all-rows"
          cellClass={[classes.cell, themeCell, cellClass].join(' ')}
          cellStyle={{ ...cellStyle }}
          component={
            <Checkbox
              checked={
                rowsSelected === rowsCount ||
                rowsSelected === bulkActions.max_bulk_items
              }
              onChange={e => onSelectAllRows(e)}
              inputProps={{ 'aria-label': 'select all rows' }}
              color="primary"
            />
          }
        />,
      )
    }

    response.push(
      ...visibleColumns.map((head, index) => {
        const cellStyle = head.headStyle || {}
        // apply the extra left space on the first header cell not orderable
        const firstColumn =
          index === 0 && !head.orderable ? styles.firstColumn : {}
        return (
          <DatagridCell
            key={index}
            cellClass={[classes.cell, themeCell, cellClass].join(' ')}
            cellStyle={{ ...cellStyle, ...cellStyle, ...firstColumn }}
            onClick={proxy => this.onClickCell({ proxy, head, index })}
            component={head.title}
            orderable={head.orderable}
            order={head.order}
            help={head.help}
          />
        )
      }),
    )

    if (!isEmpty(actionsEnabled)) {
      response.push(
        <DatagridCell
          key="actions"
          component={Translate({ messageKey: 'datagrid_actions' })}
          cellClass={[classes.cell, themeCell, cellClass].join(' ')}
          cellStyle={{ ...cellStyle, justifyContent: 'center' }}
        />,
      )
    }

    if (!isEmpty(invisibleColumns)) {
      response.push(
        <DatagridCell
          key="more"
          cellClass={[classes.cell, themeCell, cellClass].join(' ')}
          cellStyle={{ ...cellStyle }}
          empty
        />,
      )
    }

    return response
  }
}

export default DatagridHeaderContainer
