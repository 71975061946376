import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const TypeProtocolStatus = ({ value, contrastColor, ...rest }) => {
  if (!value) return '-'
  const { status, text } = value

  let statusStyle

  switch (status) {
    case 'scheduled':
      statusStyle = Colors.protocol.scheduled
      break

    case 'sync':
      statusStyle = Colors.protocol.sync
      break

    case 'pre-sync':
      statusStyle = Colors.protocol.pre_sync
      break

    case 'heat-observation':
      statusStyle = Colors.protocol.heat_observation
      break

    case 'completed':
      statusStyle = Colors.protocol.completed
      break

    case 'canceled':
      statusStyle = Colors.protocol.canceled
      break

    case 'service':
      statusStyle = Colors.protocol.service
      break

    default: //nothing to moo but colors
  }

  return (
    <CowmedChip
      {...rest}
      value={text}
      customStyle={statusStyle}
      tooltip={value.text}
    />
  )
}

export default TypeProtocolStatus
