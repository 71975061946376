import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isNumber } from 'lodash'

import Erro_404 from '#/assets/fallback/Erro_404.svg'
import Erro_429 from '#/assets/fallback/Erro_429.svg'
import Erro_500 from '#/assets/fallback/Erro_500.svg'
import Suspended from '#/assets/fallback/Suspended.svg'
import Flex from '#/components/Flex'
import Palette from '#/styles/Palette'

const FallbackImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 20,
  [theme.breakpoints.up('sm')]: {
    marginTop: 100,
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 20,
  },
}))

const FallbackCode = styled('span')(({ theme }) => ({
  position: 'absolute',
  fontSize: '28vw',
  color: '#e9f2f6',
  [theme.breakpoints.down('lg')]: {
    fontSize: '50vw',
  },
}))

const FallbackVic = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '400px',
  },
  [theme.breakpoints.down('md')]: {
    height: '250px',
  },
  zIndex: 5,
  maxWidth: '100%',
}))

const MessageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  flex: 1,
  margin: 30,
})

export const FallbackMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  maxWidth: '100%',
  fontSize: 20,
  color: Palette.gray.self[700],
  [theme.breakpoints.up('md')]: {
    width: '80ch',
  },
}))

export const FallbackTitle = styled(Typography)({
  textAlign: 'center',
  fontSize: 30,
  color: Palette.gray.self[600],
})

export const FallbackTimer = styled('p')({
  textAlign: 'center',
  color: Palette.gray.self[500],
})

export const FallbackWrapper = ({ code, children }) => {
  const image = {
    500: Erro_500,
    429: Erro_429,
    404: Erro_404,
    suspended: Suspended,
  }
  return (
    <Flex flexDirection="column" id="fallback-error">
      <FallbackImageContainer className="noselect">
        {isNumber(code) ? (
          <FallbackCode id="fallback-code">{code}</FallbackCode>
        ) : null}
        <FallbackVic src={image[code]} alt="Vic 404" />
      </FallbackImageContainer>
      <MessageWrapper>{children}</MessageWrapper>
    </Flex>
  )
}
