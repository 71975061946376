import {
  AnimalIcon,
  ComfortIcon,
  ConnectedIcon,
  CowComfortIcon,
  DisconnectedIcon,
  HealthIcon,
  IndicatorsIcon,
  PartiallyDisconnectedIcon,
  ProductionIcon,
  ReproductionIcon,
  WellnessIcon,
} from '@chipinside/frontend'

/* prettier-ignore */
export const PageBuilderIcons = {
  'comfort':                 <ComfortIcon />,
  'health':                  <HealthIcon />,
  'production':              <ProductionIcon />,
  'reproduction':            <ReproductionIcon />,
  'indicators':              <IndicatorsIcon />,
  'herd':                    <AnimalIcon />,
  'wellness':                <WellnessIcon />,
  'connected':               <ConnectedIcon />,
  'disconnected':            <DisconnectedIcon />,
  'partially-disconnected':  <PartiallyDisconnectedIcon />,
  'cow-comfort':             <CowComfortIcon />,
}
