import { Translate } from '@chipinside/frontend'
import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useRef, useState } from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const Wrapper = styled('div')(({ hover, disabled }) => ({
  ...(disabled && {
    pointerEvents: 'none',
    backgroundColor: '#f1f1f1',
    opacity: 0.8,
  }),
  border: '2px dashed',
  borderRadius: '8px',
  padding: '10px',
  minHeight: 100,
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  borderColor: '#ccc',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  flexDirection: 'column',
  ...(hover && {
    borderColor: Colors.primary,
    color: Colors.primary,
  }),
}))

const Preview = styled('img')(({ hover }) => ({
  maxHeight: 300,
  borderRadius: '8px',
  transition: 'opacity 0.3s ease',
  ...(hover && {
    filter: 'blur(4px)',
    opacity: 0.15,
  }),
}))

const Title = styled('div')(({ disabled }) => ({
  color: disabled ? Colors.darkerGray : Colors.primary,
  fontWeight: 500,
  pointerEvents: 'none',
  textAlign: 'center',
}))

const SubTitle = styled('div')({
  fontSize: '12px',
  color: Colors.darkerGray,
  textAlign: 'center',
  marginTop: 8,
})

const Clear = styled(CowmedChip)({
  position: 'absolute',
  top: '10px',
  right: '10px',
})

const Description = ({ title, description, disabled }) => (
  <div style={{ position: 'absolute' }}>
    <Title disabled={disabled}>{title}</Title>
    {description && <SubTitle>{description}</SubTitle>}
  </div>
)

const InputImage = ({
  value,
  onChange,
  disabled,
  accept = 'image/*',
  label,
  required,
  description,
  error,
}) => {
  const [hover, setHover] = useState(false)
  const [preview, setPreview] = useState(value)
  const fileInput = useRef(null)

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      const previewUrl = URL.createObjectURL(file)
      setPreview(previewUrl)
      onChange?.(file)
    }
  }

  const handleClear = event => {
    event.stopPropagation()
    setPreview(null)
    fileInput.current.value = ''
    onChange?.(null)
  }

  return (
    <div style={{ paddingTop: 20 }}>
      <InputLabel shrink required={required} error={Boolean(error)}>
        {label}
      </InputLabel>
      <FormControl fullWidth error={Boolean(error)}>
        <Wrapper
          disabled={disabled}
          hover={hover}
          onClick={() => fileInput.current.click()}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {preview ? (
            <>
              <Preview src={preview} alt="Preview" hover={hover} />
              {hover && (
                <>
                  <Description
                    disabled={disabled}
                    title={Translate({ messageKey: 'change_image' })}
                    subtitle={description}
                  />
                  <Clear
                    className="action"
                    size="small"
                    color="error"
                    onClick={handleClear}
                    width="unset"
                    value={Translate({ messageKey: 'clear' })}
                  />
                </>
              )}
            </>
          ) : (
            <Description
              disabled={disabled}
              title={Translate({ messageKey: 'upload_image' })}
              description={description}
            />
          )}
          <input
            type="file"
            ref={fileInput}
            accept={accept}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Wrapper>
        {error && (
          <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
        )}
      </FormControl>
    </div>
  )
}

export default InputImage
