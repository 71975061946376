type PagesListContext = 'farm' | 'system'
export type PagesListReturn = {
  title: string
  description: string
  url: string
  permission: boolean
  context: PagesListContext
}
export type PagesListProps = {
  context?: PagesListContext
  cypress?: boolean
  permissions?: {
    system?: Record<string, boolean>
    farm?: Record<string, boolean>
  }
}

export const PagesList = (props: PagesListProps): PagesListReturn[] => {
  const { context, cypress, permissions } = props ?? {}

  const PAGES = [
    /**
     *  #####################################################################################
     *  ##################################  SYSTEM  #########################################
     *  #####################################################################################
     */
    /**
     * #####################################################################################
     * ACESSO
     * #####################################################################################
     */
    {
      title: 'Acessos',
      description: '',
      type: '',
      url: '/',
      permission: true,
      context: 'system',
    },
    /**
     * #####################################################################################
     * USUÁRIO
     * #####################################################################################
     */
    {
      title: 'Usuário / Informações do usuário',
      description: '',
      type: '',
      url: '/user/settings',
      permission: true,
      context: 'system',
    },
    {
      title: 'Usuário / Informações do usuário',
      description: '',
      type: '',
      url: '/user/notifications',
      permission: true,
      context: 'system',
    },

    /**
     * #####################################################################################
     * DATAVIEW
     * #####################################################################################
     */
    {
      title: 'Data view',
      description: '',
      type: '',
      url: '/data-view',
      permission: permissions?.system?.viewDataView,
      context: 'system',
    },
    /**
     * BENCHMARKING
     */
    {
      title: 'Data view / Benchmarking / Taxa de serviço',
      description: '',
      type: '',
      url: '/data-view/benchmarking',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Benchmarking / Taxa de concepção',
      description: '',
      type: '',
      url: '/data-view/benchmarking/conception-rate',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Benchmarking / Taxa de prenhez',
      description: '',
      type: '',
      url: '/data-view/benchmarking/pregnancy-rate',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Benchmarking / DEL médio',
      description: '',
      type: '',
      url: '/data-view/benchmarking/avagare-del',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Benchmarking / IEP',
      description: '',
      type: '',
      url: '/data-view/benchmarking/calving-interval',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Benchmarking / Taxa de descarte',
      description: '',
      type: '',
      url: '/data-view/benchmarking/discard-rate',
      permission: true,
      context: 'system',
    },
    /**
     * CCOM
     */
    {
      title: 'Data view / C-Com / Visão geral',
      description: '',
      type: '',
      url: '/data-view/ccom',
      permission: true,
      context: 'system',
    },
    /**
     * CONTAS E CONTRATOS
     */
    {
      title: 'Data view / Contas e contratos / Contratos ativos',
      description: '',
      type: '',
      url: '/data-view/contracts/active',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Contas e contratos / Contratos assinados',
      description: '',
      type: '',
      url: '/data-view/contracts/signed',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Contas e contratos / Lead time CM',
      description: '',
      type: '',
      url: '/data-view/contracts/lead_time_cm',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Contas e contratos / Upsell / Churn',
      description: '',
      type: '',
      url: '/data-view/contracts/upsell-churn',
      permission: true,
      context: 'system',
    },
    {
      title: 'Data view / Contas e contratos / Oportunidades',
      description: '',
      type: '',
      url: '/data-view/contracts/opportunities',
      permission: true,
      context: 'system',
    },
    /**
     * #####################################################################################
     * FARM DATABASE
     * #####################################################################################
     */
    /**
     * TOURO/SEMEN
     */
    {
      title: 'Touro/Sêmen',
      description: '',
      type: '',
      url: '/database/semen',
      permission: true,
      context: 'system',
    },
    {
      title: 'Touro/Sêmen / Empresas',
      description: '',
      type: '',
      url: '/database/semen/companies',
      permission: true,
      context: 'system',
    },
    /**
     * TRATAMENTOS
     */
    {
      title: 'Touro/Sêmen / Empresas',
      description: '',
      type: '',
      url: '/database/semen/companies',
      permission: true,
      context: 'system',
    },
    {
      title: 'Tratamentos / Fabricantes',
      description: '',
      type: '',
      url: '/database/treatment/manufacturer',
      permission: true,
      context: 'system',
    },
    /**
     * #####################################################################################
     * CONTAS
     * #####################################################################################
     */
    /**
     * FAZENDAS
     */
    {
      title: 'Contas / Fazendas',
      description: '',
      type: '',
      url: '/accounts/farm',
      permission: true,
      context: 'system',
    },
    /**
     * FAZENDA INDIVIDUAL
     */
    {
      title: 'Contas / Fazendas / Fazenda / Dados cadastrais',
      description: '',
      type: '',
      url: '/accounts/farm/{farm}',
      permission: true,
      context: 'system',
    },
    {
      title: 'Contas / Fazendas / Fazenda / Rede',
      description: '',
      type: '',
      url: '/accounts/farm/{farm}/network',
      permission: true,
      context: 'system',
    },
    {
      title: 'Contas / Fazendas / Fazenda / Contrato',
      description: '',
      type: '',
      url: '/accounts/farm/{farm}/contract',
      permission: true,
      context: 'system',
    },
    {
      title: 'Contas / Fazendas / Fazenda / Módulos extras',
      description: '',
      type: '',
      url: '/accounts/farm/{farm}/extra-modules',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * EMPRESAS
     */
    {
      title: 'Contas / Empresas',
      description: '',
      type: '',
      url: '/accounts/company',
      permission: true,
      context: 'system',
    },
    /**
     * EMPRESA INDIVIDUAL
     */
    {
      title: 'Contas / Empresas / Empresa / Visão geral',
      description: '',
      type: '',
      url: '/accounts/company/{company}',
      permission: true,
      context: 'system',
    },
    {
      title: 'Contas / Empresas / Empresa / Usuários',
      description: '',
      type: '',
      url: '/accounts/company/{company}/users',
      permission: true,
      context: 'system',
    },
    {
      title: 'Contas / Empresas / Empresa / Papéis',
      description: '',
      type: '',
      url: '/accounts/company/{company}/roles',
      permission: true,
      context: 'system',
    },
    {
      title: 'Contas / Empresas / Empresa / Carteira',
      description: '',
      type: '',
      url: '/accounts/company/{company}/portfolio',
      permission: true,
      context: 'system',
    },
    {
      title: 'Contas / Empresas / Empresa / Personalização',
      description: '',
      type: '',
      url: '/accounts/company/{company}/customization',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * #####################################################################################
     * CS
     * #####################################################################################
     */
    /**
     * CENTRAL DO CLIENTE
     */
    {
      title: 'Root / Central do cliente',
      description: '',
      type: '',
      url: '/cs/customer-center',
      permission: true,
      context: 'system',
    },
    /**
     * CENTRAL DO CLIENTE INDIVIDUAL
     */
    {
      title: 'CS / Central do cliente / Fazenda / Relacionamento',
      description: '',
      type: '',
      url: '/cs/customer-center/relationship/{farm}/relationship',
      permission: true,
      context: 'system',
    },
    {
      title: 'CS / Central do cliente / Fazenda / Perfil da fazenda',
      description: '',
      type: '',
      url: '/cs/customer-center/relationship/{farm}/farm-profile',
      permission: true,
      context: 'system',
    },

    /* FIM */
    /**
     * NPS
     */
    {
      title: 'CS / NPS / Campanhas',
      description: '',
      type: '',
      url: '/cs/nps/campaigns',
      permission: true,
      context: 'system',
    },
    /**
     * NPS INDIVIDUAL
     */
    {
      title: 'CS / NPS / Campanhas / Respostas',
      description: '',
      type: '',
      url: '/cs/nps/campaigns/{nps}/answers',
      permission: true,
      context: 'system',
    },
    {
      title: 'CS / NPS / Campanhas / Contas sem resposta',
      description: '',
      type: '',
      url: '/cs/nps/campaigns/{nps}/unanswered',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * C-TECH
     */
    /**
     * STATUS OPERACIONAL
     */
    {
      title: 'C-Tech / Status operacional',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/status',
      permission: true,
      context: 'system',
    },
    /**
     * STATUS OPERACIONAL INDIVIDUAL
     */
    {
      title: 'Inventário / C-Tech / Fazenda / Todas',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/farm/{farm}/status/all',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Fazenda / Trocas',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/farm/{farm}/status/replacement',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Fazenda / Revisão técnica',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/farm/{farm}/status/technical-revision',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Fazenda / Revisão na fazenda',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/farm/{farm}/status/onFarm',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Fazenda / Novas',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/farm/{farm}/status/new',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Fazenda / Fora',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/farm/{farm}/status/misplaced',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Fazenda / Perdidas',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/farm/{farm}/status/lost',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * LISTA DE DISPOSITIVOS
     */
    {
      title: 'C-Tech / Lista de dispositivos',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/list',
      permission: true,
      context: 'system',
    },

    /**
     * CTECH INDIVIDUAL
     */
    {
      title: 'Inventário / C-Tech / Status da coleira',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/list/{ctech}/status',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Mapa de report de coleira',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/list/{ctech}/map',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Histórico de animais',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/list/{ctech}/history',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Histórico de movimentações',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/list/{ctech}/farm-history',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Tech / Histórico de erros',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/list/{ctech}/error-history',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * QUEBRAS
     */
    {
      title: 'C-Tech / Quebra',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/break',
      permission: true,
      context: 'system',
    },
    /**
     * QUEBRAS INDIVIDUAL
     */
    {
      title: 'C-Tech / Quebra / Distribuição em fazenda',
      description: '',
      type: '',
      url: '/system/inventory/c-tech/break/{ctech_version}/{ctech_manufacture}/farm-distribution',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * #####################################################################################
     * COWCOMFORT
     * #####################################################################################
     */
    {
      title: 'Root / CowComfort',
      description: '',
      type: '',
      url: '/system/inventory/c-env',
      permission: true,
      context: 'system',
    },
    /**
     * COWCOMFORT INDIVIDUAL
     */
    {
      title: 'Inventário / CowComfort / Status do dispositivo',
      description: '',
      type: '',
      url: '/system/inventory/c-env/{cenv}/status',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / CowComfort / Histórico de movimentações',
      description: '',
      type: '',
      url: '/system/inventory/c-env/{cenv}/history',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * #####################################################################################
     * CCOM
     * #####################################################################################
     */
    /**
     * STATUS OPERACIONAL
     */
    {
      title: 'Inventário / CowComfort / Histórico de movimentações',
      description: '',
      type: '',
      url: '/system/inventory/c-env/{cenv}/history',
      permission: true,
      context: 'system',
    },
    /**
     * STATUS OPERACIONAL INDIVIDUAL
     */
    {
      title: 'C-Com / Status operacional / Fazenda /Visão geral',
      description: '',
      type: '',
      url: '/system/inventory/c-com/farm/{farm}',
      permission: true,
      context: 'system',
    },
    {
      title: 'C-Com / Status operacional / Fazenda /C-Coms',
      description: '',
      type: '',
      url: '/system/inventory/c-com/farm/{farm}/list',
      permission: true,
      context: 'system',
    },
    {
      title: 'C-Com / Status operacional / Fazenda /Conectividade',
      description: '',
      type: '',
      url: '/system/inventory/c-com/farm/{farm}/connectivity',
      permission: true,
      context: 'system',
    },
    {
      title:
        'C-Com / Status operacional / Fazenda /Intervalo de dados por lote',
      description: '',
      type: '',
      url: '/system/inventory/c-com/farm/{farm}/data-inverval',
      permission: true,
      context: 'system',
    },
    {
      title: 'C-Com / Status operacional / Fazenda /Notas de suporte',
      description: '',
      type: '',
      url: '/system/inventory/c-com/farm/{farm}/notes',
      permission: true,
      context: 'system',
    },
    {
      title: 'C-Com / Status operacional / Fazenda /Alertas',
      description: '',
      type: '',
      url: '/system/inventory/c-com/farm/{farm}/alerts',
      permission: true,
      context: 'system',
    },
    {
      title: 'C-Com / Status operacional / Fazenda /Histórico de estoque',
      description: '',
      type: '',
      url: '/system/inventory/c-com/farm/{farm}/history',
      permission: true,
      context: 'system',
    },

    /* FIM */
    /**
     * LISTA DE DISPOSITIVOS
     */
    {
      title: 'C-Com / Lista de dispositivos',
      description: '',
      type: '',
      url: '/system/inventory/c-com',
      permission: true,
      context: 'system',
    },
    /**
     * CCOM INDIVIDUAL
     */
    {
      title: 'Inventário / C-Com / Status da c-com',
      description: '',
      type: '',
      url: '/system/inventory/c-com/{ccom}/status',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Com / Status SBC',
      description: '',
      type: '',
      url: '/system/inventory/c-com/{ccom}/status-sbc',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Com / Histórico de fazendas',
      description: '',
      type: '',
      url: '/system/inventory/c-com/{ccom}/farm-history',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Com / Histórico de manutenções',
      description: '',
      type: '',
      url: '/system/inventory/c-com/{ccom}/maintenance-history',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Com / Histórico de configurações',
      description: '',
      type: '',
      url: '/system/inventory/c-com/{ccom}/config-history',
      permission: true,
      context: 'system',
    },
    {
      title: 'Inventário / C-Com / Mapa de status de c-com',
      description: '',
      type: '',
      url: '/system/inventory/c-com/{ccom}/map',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * INTERVALO DE DADOS
     */
    {
      title: 'Intervalo de dados / Geral da fazenda',
      description: '',
      type: '',
      url: '/system/inventory/c-com/interval',
      permission: true,
      context: 'system',
    },
    {
      title: 'Intervalo de dados / Lotes monitorados',
      description: '',
      type: '',
      url: '/system/inventory/c-com/interval/batch',
      permission: true,
      context: 'system',
    },
    /**
     * FALHAS NO REPORT
     */
    {
      title: 'Falhas no report / Relatório de falhas',
      description: '',
      type: '',
      url: '/system/inventory/c-com/report-fails',
      permission: true,
      context: 'system',
    },
    /**
     * FALHA NO REPORT INDIVIDUAL
     */
    {
      title: 'Falhas no report / Fazenda / Relatório de falhas',
      description: '',
      type: '',
      url: '/system/inventory/c-com/report-fails/{farm}',
      permission: true,
      context: 'system',
    },
    /* FIM */
    /**
     * #####################################################################################
     * ROOT
     * #####################################################################################
     */

    /**
     * MODULOS
     */
    {
      title: 'Root / Módulos',
      description: '',
      type: '',
      url: '/system/settings/modules',
      permission: true,
      context: 'system',
    },

    /**
     * PERMISSOES
     */
    {
      title: 'Root / Permissões',
      description: '',
      type: '',
      url: '/system/settings/permissions',
      permission: true,
      context: 'system',
    },

    /**
     * PAPEIS
     */
    {
      title: 'Root / Papéis',
      description: '',
      type: '',
      url: '/system/settings/roles',
      permission: true,
      context: 'system',
    },

    /**
     * NOTAS DE VERSAO
     */
    {
      title: 'Root / Notas da versão',
      description: '',
      type: '',
      url: '/system/settings/release-notes',
      permission: true,
      context: 'system',
    },

    /**
     * EMAILS TRANSACIONAIS
     */
    {
      title: 'Root / Emails transacionais',
      description: '',
      type: '',
      url: '/system/settings/mails',
      permission: true,
      context: 'system',
    },

    /**
     * TESTES
     */
    {
      title: 'Importar dados',
      description: '',
      type: '',
      url: '/system/settings/tests',
      permission: true,
      context: 'system',
    },

    /**
     * #####################################################################################
     * IMPORTAR DADOS
     * #####################################################################################
     */
    {
      title: 'Importar dados',
      description: '',
      type: '',
      url: '/system/import',
      permission: true,
      context: 'system',
    },

    /**
     *  #####################################################################################
     *  ###################################  FARM  ##########################################
     *  #####################################################################################
     */
    /**
     * #####################################################################################
     * DASHBOARD
     * #####################################################################################
     */
    {
      title: 'Dashboard',
      description: '',
      type: '',
      url: '/farm/{farm}',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * CONFIGURACOES DA FAZENDA
     * #####################################################################################
     */
    {
      title: 'Configurações / Visão geral',
      description: '',
      type: '',
      url: '/farm/{farm}/settings',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / Rede',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/network',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / Manejo da fazenda',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/handling',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / OKR',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/okr',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / OKR',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/okr',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / Usuários',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/users',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / Papéis',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/roles',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / Unidades',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/units',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Configurações / Tokens',
      description: '',
      type: '',
      url: '/farm/{farm}/settings/tokens',
      permission: true,
      context: 'farm',
    },

    /**
     * #####################################################################################
     * ANIMAIS
     * #####################################################################################
     */
    {
      title: 'Rebanho / Animais',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals',
      permission: true,
      context: 'farm',
    },
    /**
     * ANIMAL INDIVIDUAL
     */
    {
      title: 'Animais / Animal / Monitoramento dia',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Monitoramento hora',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/hourly',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Reprodução',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/reproduction',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Saúde',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/health',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Produção',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/production',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Pesagem',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/weighing',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Notas',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/notes',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Índice de cio',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/heat-index',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Índice de saúde',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/health-index',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Índice de parto',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/birth-index',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Índice de respiração',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/breathing-index',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Animais / Animal / Histórico de coleiras',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/animals/{animal}/collar-history',
      permission: true,
      context: 'farm',
    },
    /* FIM */
    /**
     * LOTES
     */
    {
      title: 'Rebanho / Lotes',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/batch',
      permission: true,
      context: 'farm',
    },
    /**
     * LOTE INDIVIDUAL
     */
    {
      title: 'Rebanho / Lote / Monitoramento do lote',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/batch/{batch}/monitoring',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Rebanho / Lote / Perfil e consumo',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/batch/{batch}/profile',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Rebanho / Lote / Acumulado',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/batch/{batch}/accumulated',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Rebanho / Lote / Dieta do lote',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/batch/{batch}/diet',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Rebanho / Lote / Animais para revisão',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/batch/{batch}/review',
      permission: true,
      context: 'farm',
    },
    /* FIM */
    /**
     * TROCA DE LOTES
     */
    {
      title: 'Rebanho / Troca de lotes',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/herd/manage',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * CRIA / RECRIA
     * #####################################################################################
     */
    /**
     * ENFERMARIA
     */
    {
      title: 'Saúde / Enfermaria Baby / Sem diagnóstico',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/infirmary-baby',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Saúde / Enfermaria Baby / Com diagnóstico',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/infirmary-baby/diagnosis',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Saúde / Enfermaria Baby / Recuperados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/infirmary-baby/recovered',
      permission: true,
      context: 'farm',
    },
    /**
     * DESALEITAMENTO
     */
    {
      title: 'Cria/Recria / Desaleitamento / Programados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/rearing/weaning',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cria/Recria / Desaleitamento / Realizados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/rearing/weaning/executed',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * REPRODUCAO
     * #####################################################################################
     */
    /**
     * ALERTAS DE CIO
     */
    {
      title: 'Reprodução / Alertas de cio',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/heat',
      permission: true,
      context: 'farm',
    },
    /**
     * EXAME GINECOLOGICO
     */
    {
      title: 'Reprodução / Exame ginecológico',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/gynecologic',
      permission: true,
      context: 'farm',
    },
    /**
     * RETORNO DE CIO
     */
    {
      title: 'Cow analytics / Retorno de cio',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/heat-return',
      permission: true,
      context: 'farm',
    },
    /**
     * DIAGNOSTICO DE PRENHEZ
     */
    {
      title: 'Reprodução / Diagnóstico de prenhez / Confirmação',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/pregnancy',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Diagnóstico de prenhez / 2ª confirmação',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/pregnancy/check2',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Diagnóstico de prenhez / Suspeita de perdas',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/pregnancy/suspected',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Diagnóstico de prenhez / Taxa de prenhez',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/pregnancy/rate',
      permission: true,
      context: 'farm',
    },
    /**
     * PROTOCOLOS
     */
    {
      title: 'Reprodução / Protocolos / Meus protocolos',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/protocol',
      permission: true,
      context: 'farm',
    },
    /**
     * PROTOCOLO INDIVIDUAL
     */
    {
      title: 'Reprodução / Protocolos / Protocolo / Animais protocolados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/protocol/{protocol}/detail',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Protocolos / Protocolo / Programação do protocolo',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/protocol/{protocol}/detail/schedule',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Protocolos / Protocolo / Análise de resultados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/protocol/{protocol}/detail/results',
      permission: true,
      context: 'farm',
    },
    /* FIM */
    {
      title: 'Reprodução / Protocolos / Modelo de protocolo',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/protocol/model',
      permission: true,
      context: 'farm',
    },
    /**
     * SECAGEM
     */
    {
      title: 'Reprodução / Secagem / Programados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/drying',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Secagem / Realizados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/drying/performed',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Secagem / Projeção de secagens',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/drying/projection',
      permission: true,
      context: 'farm',
    },
    /**
     * PRE-PARTO
     */
    {
      title: 'Reprodução / Pré-parto',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/pre-calving-prediction',
      permission: true,
      context: 'farm',
    },
    /**
     * PARTO
     */
    {
      title: 'Reprodução / Parto / Programados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/calving',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Parto / Realizados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/calving/performed',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Parto / Projeção de partos',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/calving/projection',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Parto / IEP',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/calving/intervals',
      permission: true,
      context: 'farm',
    },
    /**
     * PPI ANIMAL
     */
    {
      title: 'Cow analytics / Transição / PPI do rebanho',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/transition',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Transição / PPI do rebanho',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/transition',
      permission: true,
      context: 'farm',
    },
    /**
     * TOURO SEMEN
     */
    {
      title: 'Reprodução / Touro/Sêmen',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/bull-semen',
      permission: true,
      context: 'farm',
    },
    /**
     * SEMEN INDIVIDUAL
     */
    {
      title: 'Reprodução / Touro/Sêmen / Estoque de sêmen',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/bull-semen/{semen}/stock',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Reprodução / Touro/Sêmen / Histórico de uso',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/bull-semen/{semen}/history',
      permission: true,
      context: 'farm',
    },
    /* FIM */
    /**
     * DOADORAS
     */
    {
      title: 'Reprodução / Doadoras',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/embryo-donor',
      permission: true,
      context: 'farm',
    },
    /**
     * INSEMINADOR
     */
    {
      title: 'Reprodução / Inseminador',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/inseminator',
      permission: true,
      context: 'farm',
    },
    /**
     * ACASALAMENTO
     */
    {
      title: 'Reprodução / Acasalamento',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/reproduction/mating',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * SAUDE
     * #####################################################################################
     */
    /**
     * ENFERMARIA
     */
    {
      title: 'Enfermaria / Sem diagnóstico',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/infirmary',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Enfermaria / Com diagnóstico',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/infirmary/diagnosis',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Enfermaria / Recuperados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/infirmary/recovered',
      permission: true,
      context: 'farm',
    },
    /**
     * ENFERMARIA BABY - DUPLICADO
     */
    /**
     * ANIMAIS EM DESAFIO
     */
    {
      title: 'Saúde / Animais em desafio',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/challenge',
      permission: true,
      context: 'farm',
    },
    /**
     * HISTORICO DE EVENTOS
     */
    {
      title: 'Saúde / Histórico de eventos',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/health/history',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * NUTRICAO
     * #####################################################################################
     */
    /**
     * LOTES MONITORADOS
     */
    {
      title: 'Nutrição / Lotes monitorados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/precision-nutrition/monitored',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Nutrição / Gestão de insumos',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/precision-nutrition/ingredient',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Nutrição / Cadastro de dieta',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/precision-nutrition/diet',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * CONTROLE LEITEIRO
     * #####################################################################################
     */
    /**
     * ANÁLISE DE PRODUCAO
     */
    {
      title: 'Controle leiteiro / Geral',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/analysis',
      permission: true,
      context: 'farm',
    },
    /**
     * REGISTRO DE PRODUCAO
     */
    {
      title: 'Controle leiteiro / Registro da produção',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/production',
      permission: true,
      context: 'farm',
    },
    /**
     * PRODUCAO INDIVIDUAL
     */
    {
      title: 'Controle leiteiro / Registro da produção / Produção individual',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/production/{production_per_animal}/per_animal',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Controle leiteiro / Registro da produção / Media de lotes',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/production/{production_per_animal}/per_batch',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Controle leiteiro / Registro da produção / Media de lotes',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/production/{production_per_batch}/per_batch',
      permission: true,
      context: 'farm',
    },
    /* FIM */
    /**
     * DESCARTE DE LEITE
     */
    {
      title: 'Controle leiteiro / Descarte de leite',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/discard',
      permission: true,
      context: 'farm',
    },

    /**
     * QUALIDADE DO LEITE
     */
    {
      title: 'Controle leiteiro / Qualidade do leite',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/quality',
      permission: true,
      context: 'farm',
    },
    /**
     * INDUCAO DE LACTACAO
     */
    {
      title: 'Controle leiteiro / Indução de lactação / Marcados para indução',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/induced/marked',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Controle leiteiro / Indução de lactação / Induções realizadas',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/milk-control/induced/realized',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * ESTRESSE TERMICO
     * #####################################################################################
     */
    /**
     * VISAO GERAL
     */
    {
      title: 'Estresse térmico',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/thermal-stress',
      permission: true,
      context: 'farm',
    },
    /**
     * PONTO INDIVIDUAL
     */
    {
      title: 'Estresse térmico / Hora/hora',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/thermal-stress/{point}/hourly',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Estresse térmico / Tempo real',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/thermal-stress/{point}/real-time',
      permission: true,
      context: 'farm',
    },
    /* FIM */
    /**
     * #####################################################################################
     * EVENTOS DE MANEJO
     * #####################################################################################
     */
    /**
     * REGISTRO DE MANEJO
     */
    {
      title: 'Eventos de manejo / Registro de manejo',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/cattle-handling',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * MONITORAMENTO
     * #####################################################################################
     */
    /**
     * VISAO GERAL
     */
    {
      title: 'Monitoramento / Visão geral',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/overview',
      permission: true,
      context: 'farm',
    },
    /**
     * ANIMAIS MONITORADOS
     */
    {
      title: 'Monitoramento / Animais monitorados',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/animals',
      permission: true,
      context: 'farm',
    },
    /**
     * LOTES MONITORADOS - DUPLICADO
     */
    /**
     * GESTAO DE COLEIRAS
     */
    {
      title: 'Monitoramento / Gestão de coleira / Todas',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-tech',
      permission: true,
      context: 'farm',
    },
    /**
     * COLEIRA INDIVIDUAL
     */
    {
      title: 'Monitoramento / Gestão de coleira / Mapa de report de coleira',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-tech/details/{ctech}/map',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Monitoramento / Gestão de coleira / Histórico de animais',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-tech/details/{ctech}/history',
      permission: true,
      context: 'farm',
    },

    /* FIM */
    {
      title: 'Monitoramento / Gestão de coleira / Trocas',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-tech/replace',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Monitoramento / Gestão de coleira / Revisão técnica',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-tech/tecnical',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Monitoramento / Gestão de coleira / Revisão na fazenda',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-tech/on-farm',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Monitoramento / Gestão de coleira / Baby expirada',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-tech/expired',
      permission: true,
      context: 'farm',
    },
    /**
     * GESTAO DE COW COMFORT
     */
    {
      title: 'Monitoramento / Gestão de CowComfort / CowComfort',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-env',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Monitoramento / Gestão de CowComfort / Ponto de monitoramento',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/monitoring/c-env/points',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * COW ANALYTICS
     * #####################################################################################
     */
    {
      title: 'Cow analytics',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics',
      permission: true,
      context: 'farm',
    },
    /**
     * BEM ESTAR GERAL
     */
    {
      title: 'Cow analytics / Bem-estar geral / Nutrição e consumo',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/wellness/nutrition',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Bem-estar geral / Conforto térmico e descanso',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/wellness/comfort',
      permission: true,
      context: 'farm',
    },
    /**
     * CONCEPCAO
     */
    {
      title: 'Cow analytics / Concepção / Taxa de concepção',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/conception/rate',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Concepção / Por inseminador',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/conception/per-inseminator',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Concepção / Por touro/sêmen',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/conception/per-bull-semen',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Concepção / Por tipo de serviço',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/conception/per-service-type',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Concepção / Por DEL',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/conception/per-del',
      permission: true,
      context: 'farm',
    },
    /**
     * CRIA/RECRIA
     */
    {
      title: 'Cow analytics / Cria/Recria / Peso médio nascimento',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/rearing/birth',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Cria/Recria / Peso médio ao desaleitamento',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/rearing/weaning',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Cria/Recria / Ganho médio diário',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/rearing/gain',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Cria/Recria / Mortalidade',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/rearing/mortality',
      permission: true,
      context: 'farm',
    },
    /**
     * DESCARTE DE ANIMAIS
     */
    {
      title: 'Cow analytics / Descarte de animais / Taxa de descarte',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/discard/rate',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Descarte de animais / Total de descarte',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/discard/{farm}',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Descarte de animais / Motivo de descarte',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/discard/reason',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Descarte de animais / Momento do Descarte',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/discard/moment',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Descarte de animais / Marcada para descarte',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/discard/unfit',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Descarte de animais / Animais descartados',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/discard/report',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Descarte de animais / Saída de animais',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/discard/departure',
      permission: true,
      context: 'farm',
    },
    /**
     * DIAGNOSTICO DE PRENHEZ - DUPLICADO
     */
    /**
     * PARTOS - DUPLICADO
     */
    /**
     * PERDAS GESTACIONAIS
     */
    {
      title: 'Cow analytics / Perdas gestacionais / Taxa de perdas',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/gestational/rate',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Perdas gestacionais / Perdas mensais',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/gestational/monthly',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Perdas gestacionais / Perdas por del',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/gestational/dim',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Perdas gestacionais / Perdas por período',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/gestational/stage',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Perdas gestacionais / Perdas por touro',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/gestational/bull',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Perdas gestacionais / Perdas registradas',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/gestational/list',
      permission: true,
      context: 'farm',
    },
    /**
     * PRE-PARTO - DUPLICADO
     */
    /**
     * PREVISAO DE SECAGEM - DUPLICADO
     */
    /**
     * REBANHO
     */
    {
      title: 'Cow analytics / Rebanho / Rebanho geral',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/herd/general',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Rebanho / Distribuição reprodutiva',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/herd/reproductive-distribution',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Rebanho / Animais em lactação',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/herd/lactating-animals',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Rebanho / Taxa de reposição',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/herd/replacement',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Rebanho / DEL médio',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/herd/average-dim',
      permission: true,
      context: 'farm',
    },
    /**
     * RETORNO DE CIO - DUPLICADO
     */
    /**
     * SAUDE
     */
    {
      title: 'Cow analytics / Saúde / Total de ocorrências',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/health/occurrences',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Saúde / Histórico de casos',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/health/history',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Saúde / Animais diagnosticados',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/health/diagnosed',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Saúde / Alerta de saúde',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/health/health-alert',
      permission: true,
      context: 'farm',
    },
    /**
     * SEM SERVICO
     */
    {
      title: 'Cow analytics / Sem serviço',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/no-service',
      permission: true,
      context: 'farm',
    },
    /**
     * SERVICOS
     */
    {
      title: 'Cow analytics / Serviços / Taxa de serviço',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/services/rate',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Serviços / DEL no 1º serviço',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/services/dim-first-service',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Serviços / Serviços semanais',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/services/weekly',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Serviços / Ciclos de serviços',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/services/cycles',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Serviços / Realizados',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/services/performed',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Serviços / Vacas inaptas',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/services/ineligible-cows',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Cow analytics / Serviços / Cios detectados',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/services/heat-detected',
      permission: true,
      context: 'farm',
    },
    /**
     * TRANSICAO - DUPLICADO
     */
    /**
     * VACAS APTAS
     */
    {
      title: 'Cow analytics / Vacas aptas',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/eligible-cows',
      permission: true,
      context: 'farm',
    },
    /**
     * VACAS INAPTAS - DUPLICADO
     */
    /**
     * VACAS PROBLEMAS
     */
    {
      title: 'Cow analytics / Vacas problema / Del alto sem prenhez',
      description: '',
      type: '',
      url: '/farm/{farm}/cow-analytics/problem-cows/critical-dim-without-pregnancy',
      permission: true,
      context: 'farm',
    },

    /**
     * #####################################################################################
     * IMPORTAR DADOS
     * #####################################################################################
     */
    {
      title: 'Importar dados / Importações',
      description: '',
      type: '',
      url: '/farm/{farm}/import',
      permission: true,
      context: 'farm',
    },
    {
      title: 'Importar dados / Arquivos',
      description: '',
      type: '',
      url: '/farm/{farm}/import/files',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * EXPORTAR DADOS
     * #####################################################################################
     */
    {
      title: 'Exportar dados',
      description: '',
      type: '',
      url: '/farm/{farm}/export',
      permission: true,
      context: 'farm',
    },
    /**
     * #####################################################################################
     * NOTIFICACOES
     * #####################################################################################
     */
    {
      title: 'Notificações',
      description: '',
      type: '',
      url: '/farm/{farm}/modules/notifications',
      permission: true,
      context: 'farm',
    },
  ]

  /* prettier-ignore */
  const FILTERED_PAGES = PAGES
    .filter( page => cypress ? true : page.permission )
    .filter( page => context ? page.context === context : true ) as PagesListReturn[]

  return FILTERED_PAGES as PagesListReturn[]
}
