import { Wrapper } from '@googlemaps/react-wrapper'
import React from 'react'

export default function MapWrapper({ children }) {
  return (
    <Wrapper apiKey={import.meta.env.VITE_GOOGLEMAP_API_KEY}>
      {children}
    </Wrapper>
  )
}
