import { Moment, Translate } from '@chipinside/frontend'
import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import _has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import _max from 'lodash/max'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import DatagridCardInfo from '#/components/datagrid/DatagridCardInfo'
import DatagridPrint from '#/components/datagrid/DatagridPrint'
import TopRight from '#/components/datagrid/TopRight'
import ExportFile from '#/components/ExportFile'
import LinearIndeterminate from '#/components/Loader'
import ActionsDialog from '#/components/Modal/ActionDialog'
import ResponseDialog from '#/components/Modal/ResponseDialog'
import { loadDatagrid } from '#/store/ducks/datagrid'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Old/Colors'

import DatagridFiltersContainer from '../DatagridFiltersContainer/DatagridFiltersContainer'
import DatagridFooterContainer from '../DatagridFooterContainer/DatagridFooterContainer'
import DatagridTableContainer from '../DatagridTableContainer/DatagridTableContainer'
import classes from './DatagridContainer.module.css'

const getActivedFilters = filters =>
  filter(
    filters,
    filter => filter.current !== null && filter.name !== 'date-filter',
  ).map(filter => ({ name: filter.name, value: filter.current }))

const dateRangeFilters = dateFilters =>
  dateFilters
    .filter(each => {
      return (
        _has(each, ['current', 'to']) &&
        _has(each, ['current', 'from']) &&
        each.current.to &&
        each.current.from
      )
    })
    .map(filter => ({ name: filter.name, value: filter.current }))

const getActivedOrder = columns =>
  filter(columns, column => !isEmpty(column.order)).map(item => item.order)

class DatagridContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.onSearchChange = this.onSearchChange.bind(this)
    this.searchedValue = debounce(this.searchedValue.bind(this), 900)
    this.filtersChanged = this.filtersChanged.bind(this)
    this.callDatagrid = this.callDatagrid.bind(this)
    this.orderChanged = this.orderChanged.bind(this)
    this.pageChanged = this.pageChanged.bind(this)
    this.rowsPerPageChanged = this.rowsPerPageChanged.bind(this)
    this.exportAs = this.exportAs.bind(this)
    this.renderExportModal = this.renderExportModal.bind(this)
    this.renderPrintModal = this.renderPrintModal.bind(this)
    this.resetExportModal = this.resetExportModal.bind(this)
    this.resetPrintModal = this.resetPrintModal.bind(this)
    this.multiOrderChanged = this.multiOrderChanged.bind(this)
    this.onSelectRow = this.onSelectRow.bind(this)
    this.onSelectAllRows = this.onSelectAllRows.bind(this)
    this.customRowCommonStyle = this.customRowCommonStyle.bind(this)

    const {
      search = '',
      columns = [],
      filters = [],
      defaultParams = {},
      pagination = {},
    } = props

    const { uriFilters, hasMultiOrder } = defaultParams
    const { rowsPerPage } = pagination

    this.state = {
      search: {
        value: search,
        isSearchable: false,
      },
      selectedRows: [],
      isOrderable: false,
      multiOrder: false,
      enabledColumns: [],
      searchableColumns: [],
      actionsEnabled: [],
      exportModalOpen: false,
      printModalOpen: false,
      exportType: null,
      defaultParams: { hasMultiOrder },
      params: {
        search: isEmpty(search) ? undefined : search,
        filters: [...getActivedFilters(filters), ...dateRangeFilters(filters)],
        uriFilters,
        order_by: sortBy(getActivedOrder(columns), 'priority'),
        page: null,
        per_page: this.props.rowsPerPage
          ? _max([this.props.rowsPerPage, rowsPerPage])
          : rowsPerPage,
        // paginated: true
      },
    }
  }

  componentDidMount() {
    if (!this.props.renderOnly) this.callDatagrid({})
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filters !== this.props.filters ||
      prevProps.actionsDialog !== this.props.actionsDialog ||
      prevProps.defaultParams !== this.props.defaultParams
    ) {
      const {
        columns = [],
        filters = [],
        actionsColumn,
        actionsDialog,
        defaultParams,
        onDetailsOpened,
        refreshList,
        bulkActions,
      } = this.props

      const enabledColumns = filter(
        sortBy(columns, 'position'),
        item => !!item.enabled,
      )
      const searchableColumns = filter(columns, item => item.searchable)
      const isSearchable = !isEmpty(searchableColumns)
      const actionsEnabled = filter(
        actionsColumn,
        action => action.hasPermission,
      )
      const withoutAnyOrder = isEmpty(columns.find(head => !!head.order))
      const isOrderable = !isEmpty(columns.find(head => head.orderable))

      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          isSearchable,
        },
        isOrderable,
        searchableColumns,
        enabledColumns,
        actionsEnabled,
        actionsDialog,
        onDetailsOpened,
        refreshList,
        bulkActions,
        params: {
          ...this.state.params,
          filters: [
            ...getActivedFilters(filters),
            ...dateRangeFilters(filters),
          ],

          order_by: !isEmpty(this.state.params.order_by)
            ? sortBy(this.state.params.order_by, 'priority')
            : withoutAnyOrder
              ? sortBy(defaultParams?.order_by, 'priority')
              : sortBy(getActivedOrder(columns), 'priority'),
        },
      })
    }
  }

  callDatagrid({ infinitePagination = false }) {
    // fetch datagrid data
    this.props
      .dispatch(
        loadDatagrid({
          name: this.props.name,
          controller: this.props.controller,
          manipulateDatagrid: this.props.manipulateDatagrid,
          params: { ...this.state.params },
          withInfinitePagination: infinitePagination,
        }),
      )
      .then(() => {
        // reset selectedRows if necessary
        if (this.state.selectedRows.length) {
          this.setState({
            selectedRows: [],
          })
        }
      })
  }

  onSearchChange(value) {
    this.setState(
      {
        search: {
          ...this.state.search,
          value,
        },
      },
      () => {
        this.searchedValue(this.state.search.value)
      },
    )
  }

  searchedValue(search) {
    this.setState(
      {
        params: {
          ...this.state.params,
          page: 1, // first page api is 1
          search,
        },
      },
      () => {
        this.callDatagrid({})
      },
    )
  }

  filtersChanged(filters) {
    this.setState(
      {
        params: {
          ...this.state.params,
          page: 1, // first page api is 1
          filters,
        },
      },
      () => {
        // updates URI filters at browsers address bar
        if (this.state.params.uriFilters) {
          let defaultFilters = this.state.params.filters
            .reduce((response, item) => {
              if (!(item.name === 'withTrashed' && item.value === false))
                response.push(`${item.name}=${item.value}`)
              return response
            }, [])
            .join('&')

          window.history.replaceState(
            null,
            '',
            !isEmpty(defaultFilters)
              ? `${window.location.pathname}?${defaultFilters}`
              : window.location.pathname,
          )
        } else if (window.location.search.length) {
          window.history.replaceState(null, '', window.location.pathname)
        }

        this.callDatagrid({})
      },
    )
  }

  orderChanged(order_by) {
    let orderBy = order_by
    if (!this.state.multiOrder && order_by.length > 1) {
      orderBy = last(order_by)
      orderBy.priority = 0
      orderBy = [orderBy]
    }
    this.setState(
      prevState => ({
        ...prevState,
        params: {
          ...prevState.params,
          order_by: !orderBy.length ? null : orderBy,
        },
      }),
      () => {
        this.callDatagrid({})
      },
    )
  }

  multiOrderChanged(current) {
    this.setState(
      {
        multiOrder: current,
      },
      () => {
        const {
          multiOrder,
          params: { order_by },
        } = this.state
        if (!multiOrder && order_by.length > 1) {
          this.orderChanged(order_by)
        }
      },
    )
  }

  pageChanged(page) {
    this.setState(
      {
        params: {
          ...this.state.params,
          page: page + 1,
        },
      },
      () => {
        this.callDatagrid({
          infinitePagination: this.props.withInfinitePagination,
        })
      },
    )
  }

  rowsPerPageChanged(per_page) {
    this.setState(
      {
        params: {
          ...this.state.params,
          page: 1, // first page api is 1
          per_page,
        },
      },
      () => {
        this.callDatagrid({})
      },
    )
  }

  customRowCommonStyle(row, index) {
    let styles = {}
    const { customRowStyle, backgroundDiscard = true } = this.props

    if (this.state.selectedRows.indexOf(row) >= 0) {
      styles = {
        ...styles,
        backgroundColor: index % 2 ? Colors.lightGreen : Colors.lightGreenSub,
      }
    }
    if (row.deleted_at && backgroundDiscard) {
      styles = {
        ...styles,
        color: Colors.muted,
        backgroundColor: 'rgb(245, 222, 223)',
      }
    } else if (
      row.confirmed_as === null &&
      row.confirmed_at === null &&
      Moment(row.timestamp).isAfter(Moment().subtract(24, 'h'))
    ) {
      styles = {
        ...styles,
        backgroundColor: index % 2 ? Colors.lightYellow : Colors.lightYellowSub,
      }
    } else if (row.active === false) {
      styles = { ...styles, backgroundColor: 'rgb(245, 222, 223)' }
    }
    // styles coming from outside component
    if (customRowStyle) {
      styles = { ...styles, ...customRowStyle(row, index) }
    }

    return styles
  }

  onSelectRow(event, row) {
    const { bulkActions } = this.props
    if (event.target.checked) {
      // add row to selectedRows
      this.setState({
        selectedRows: unionBy(this.state.selectedRows, [row], bulkActions.id),
      })
    } else {
      // remove row from selectedRows
      this.setState({
        selectedRows: this.state.selectedRows.filter(
          obj => obj[bulkActions.id] !== row[bulkActions.id],
        ),
      })
    }
  }

  onSelectAllRows(event) {
    const { list, bulkActions } = this.props

    if (event.target.checked) {
      if (this.state.selectedRows.length < bulkActions.max_bulk_items) {
        //Ignora os items ja selecionados
        const listWithoutSelected = list.filter(
          l => !this.state.selectedRows.includes(l),
        )
        const elements =
          bulkActions.max_bulk_items - this.state.selectedRows.length
        this.setState({
          selectedRows: unionBy(
            this.state.selectedRows,
            listWithoutSelected.slice(0, elements),
            bulkActions.id,
          ),
        })
      }
    } else {
      // remove ALL rows from selectedRows
      this.setState({
        selectedRows: [],
      })
    }
  }

  exportAs(exportType = 'xlsx', print) {
    if (print) {
      this.setState({
        printModalOpen: true,
      })
    } else {
      this.setState({
        exportType,
        exportModalOpen: true,
      })
    }
  }

  resetExportModal() {
    this.setState({
      exportType: null,
      exportModalOpen: false,
    })
  }

  resetPrintModal() {
    this.setState({
      printModalOpen: false,
    })
  }

  renderPrintModal() {
    const { controller, name, params, pagination } = this.props
    const { printModalOpen } = this.state

    return (
      printModalOpen && (
        <DatagridPrint
          controller={controller}
          params={params}
          filename={name}
          totalRecords={pagination?.total}
          onCloseModal={this.resetPrintModal}
          modalOpen={printModalOpen}
        />
      )
    )
  }

  renderExportModal() {
    const { controller, name } = this.props
    const { exportModalOpen, exportType, params } = this.state

    return (
      exportModalOpen && (
        <ExportFile
          controller={controller}
          params={params}
          filename={name}
          exportType={exportType}
          onCloseModal={this.resetExportModal}
          modalOpen={exportModalOpen}
        />
      )
    )
  }

  render() {
    const {
      responseDialog = {},
      bulkActions,
      bulkActionsButtonProps,
      filters,
      summary,
      list = [],
      pagination = {},
      status = '',
      error = '',
      withToolbar = true,
      withDetails,
      onDetailsOpened,
      withInfinitePagination,
      containerClass,
      containerStyle = {},
      headerCellClass,
      headerCellStyle = {},
      bodyCellClass,
      bodyCellStyle = {},
      defaultParams = {
        uriFilters: false,
        hasMultiOrder: false,
      },
      bodyIsStriped,
      paperStyle = {},
      noPaperWrapped,
      name,
    } = this.props

    const {
      search: { value: searchValue, isSearchable },
      enabledColumns,
      actionsEnabled,
      isOrderable,
      multiOrder,
      selectedRows,
      actionsDialog,
    } = this.state

    const { hasMultiOrder } = defaultParams

    const tableContainer = (
      <div className={classes.table} id={name}>
        {withToolbar && (
          <React.Fragment>
            <div className={classes.tools}>
              <Toolbar className={classes.toolBarHeader}>
                {selectedRows.length ? (
                  <React.Fragment>
                    {bulkActionsButtonProps.messageForSelected ? (
                      <Typography>
                        {bulkActionsButtonProps.messageForSelected(
                          selectedRows.length,
                        )}
                      </Typography>
                    ) : null}
                    <TopRight
                      refreshDatagrid={this.callDatagrid}
                      buttonCreate={{
                        ...bulkActionsButtonProps,
                        onClickParams: selectedRows,
                      }}
                      disabled={status !== 'done'}
                      renderPrint={this.renderPrint}
                      exportAs={this.exportAs}
                      aditionalButton={this.props.aditionalButton}
                      controller={this.props.controller}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <DatagridFiltersContainer
                      filters={
                        hasMultiOrder &&
                        isOrderable &&
                        isEmpty(
                          filters.filter(
                            filter => filter.name === 'multiOrder',
                          ),
                        )
                          ? [
                              ...filters,
                              {
                                name: 'multiOrder',
                                current: multiOrder,
                                enabled: true,
                                title: Translate({
                                  messageKey: 'datagrid_multiorder',
                                }),
                                type: 'switch',
                                visible: true,
                              },
                            ]
                          : filters
                      }
                      filtersChanged={this.filtersChanged}
                      multiOrderChanged={this.multiOrderChanged}
                      isSearchable={isSearchable}
                      searchValue={searchValue}
                      onSearchChange={this.onSearchChange}
                      disabled={status !== 'done'}
                    />
                    <TopRight
                      refreshDatagrid={this.callDatagrid}
                      renderPrint={this.renderPrint}
                      exportAs={this.exportAs}
                      disabled={status !== 'done'}
                      buttonCreate={this.props.topRightButtonCreateProps}
                      aditionalButton={this.props.aditionalButton}
                      controller={this.props.controller}
                    />
                  </React.Fragment>
                )}
              </Toolbar>
            </div>
            {selectedRows.length ? (
              <div style={{ display: 'flex' }}>
                <Alert icon={false} className={classes.alertSelectedAnimals}>
                  <strong>
                    {Translate({
                      messageKey:
                        selectedRows.length === 1
                          ? 'multiselect_item'
                          : 'multiselect_item_plural',
                      params: {
                        quantity: selectedRows.length,
                        max: bulkActions.max_bulk_items,
                      },
                    })}{' '}
                  </strong>
                  {Translate({ messageKey: 'multiselect_register' })}
                </Alert>
              </div>
            ) : null}
          </React.Fragment>
        )}
        <LinearIndeterminate status={status} />
        {!isEmpty(enabledColumns) && !isEmpty(list) ? (
          <DatagridTableContainer
            orderChanged={this.orderChanged}
            enabledColumns={enabledColumns}
            withDetails={withDetails}
            bulkActions={
              !isEmpty(bulkActions) ? { ...bulkActions, selectedRows } : false
            }
            onSelectRow={this.onSelectRow}
            onSelectAllRows={this.onSelectAllRows}
            onDetailsOpened={onDetailsOpened}
            actionsEnabled={actionsEnabled}
            containerClass={containerClass}
            containerStyle={containerStyle}
            headerCellClass={headerCellClass}
            headerCellStyle={headerCellStyle}
            bodyCellClass={bodyCellClass}
            bodyCellStyle={bodyCellStyle}
            bodyIsStriped={bodyIsStriped}
            customRowStyle={this.customRowCommonStyle}
            list={list}
            data-cy="datagrid-table-container"
          />
        ) : (
          <div>
            {status === 'done' ? (
              <div
                data-cy="datagrid-empty-message"
                className={classes.isEmpty}
                style={{ fontSize: Fonts.fontSize.M }}
              >
                {Translate({ messageKey: 'without_information' })}
              </div>
            ) : null}
            {status === 'failed' ? (
              <div
                data-cy="datagrid-failed-message"
                className={classes.isEmpty}
                style={{ fontSize: Fonts.fontSize.M }}
              >
                {error.message}
              </div>
            ) : null}
          </div>
        )}
        <DatagridFooterContainer
          withInfinitePagination={withInfinitePagination}
          pagination={pagination}
          pageChanged={this.pageChanged}
          rowsPerPageChanged={this.rowsPerPageChanged}
          isLoading={status === 'started'}
        />
        {status === 'done' && !isEmpty(summary) ? (
          <DatagridCardInfo summary={summary} />
        ) : null}

        {withToolbar ? this.renderExportModal() : null}
        {withToolbar ? this.renderPrintModal() : null}

        {actionsDialog && <ActionsDialog dialogActions={actionsDialog} />}

        {responseDialog && (
          <ResponseDialog
            dialogResponse={responseDialog}
            afterClose={this.callDatagrid}
          />
        )}
      </div>
    )

    return (
      <React.Fragment>
        {noPaperWrapped ? (
          tableContainer
        ) : (
          <Paper style={{ paddingTop: 4, ...paperStyle }}>
            {tableContainer}
          </Paper>
        )}
      </React.Fragment>
    )
  }
}

export default connect()(DatagridContainer)
