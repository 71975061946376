import React from 'react'

import CollarStatusByUnit from '#/components/reportStatus/CollarStatusByUnit'

const TypeUnitCollarStatus = ({ value, key }) => {
  if (!value) return <span>-</span>
  return <CollarStatusByUnit unit={value} key={key} />
}

export default TypeUnitCollarStatus
