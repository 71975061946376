import React from 'react'

import FormatCell from '#/components/FormatCell'

export function adjustColumnObject(column, index) {
  let priority = index < 10 ? 10 - index : 0

  // Infos priority
  if (column.name === 'infos') {
    priority = 10
  }

  return {
    ref: column.name,
    orderable: column.orderable,
    searchable: column.searchable,
    enabled: column.visible,
    title: column.title,
    bold: column.bold,
    help: column.help,
    order: column.order,
    position: index,
    priority,
    renderCell: value => ({
      value: <FormatCell value={value} type={column.type} />,
    }),
  }
}
