import { CheckIcon, DoubleCheckIcon, WaitingIcon } from '@chipinside/frontend'
import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const TypeHeatStatus = props => {
  const { status, text, has_reproduction_method_attached } = props

  if (!status) return null

  const style = {
    verified: Colors.heat.verified,
    awaiting: Colors.heat.awaiting,
  }

  let icon
  if (status === 'verified') {
    icon = has_reproduction_method_attached ? (
      <DoubleCheckIcon fontSize={18} />
    ) : (
      <CheckIcon fontSize={18} />
    )
  } else {
    icon = <WaitingIcon fontSize={18} />
  }

  const value = (
    <div>
      {icon} {text}
    </div>
  )

  return <CowmedChip value={value} customStyle={style[status]} width="unset" />
}

export default TypeHeatStatus
