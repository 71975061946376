import { Translate } from '@chipinside/frontend'
import { mdiKeyWireless } from '@mdi/js'
import Icon from '@mdi/react'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Duplicate from '@mui/icons-material/FileCopy'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck'
import Replay from '@mui/icons-material/Replay'
import AjustmentsIcon from '@mui/icons-material/Settings'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import TableChartIcon from '@mui/icons-material/TableChart'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import Button from '#/components/FormControl/Inputs/Button'
import Colors from '#/styles/Colors'
import Fonts from '#/styles/Fonts'

const useStyles = makeStyles(theme => ({
  root: {
    width: 45,
    height: 45,
  },
  label: {
    fontSize: Fonts.fontSize.L,
    color: Colors.darkerGray,
  },
  createManyEvent: {
    [theme.breakpoints.down('xl')]: {
      'minWidth': 0,
      '& > span > span': {
        display: 'none',
      },
    },
  },
  icons: {
    padding: 0,
    marginRight: 5,
    width: 40,
    height: 40,
  },
  disable: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}))

const RowAction = props => {
  const {
    type,
    onAction = () => {},
    iconDefault,
    labelDefault = '',
    dropDownList = [],
    ...rest
  } = props
  const classes = useStyles()

  let icon
  let label
  let button = false
  switch (type) {
    case 'multiple-icons': {
      button = (
        <div>
          {rest.list?.map(
            ({ icon, enabled, title, onClick, border = '1px solid gray' }) => {
              return (
                <Tooltip title={title} key={title}>
                  <IconButton
                    onClick={enabled ? onClick : null}
                    aria-label={title}
                    key={title}
                    classes={!enabled ? { root: classes.disable } : {}}
                    className={classes.icons}
                    style={{ border }}
                    size="large"
                  >
                    {icon}
                  </IconButton>
                </Tooltip>
              )
            },
          )}
        </div>
      )
      break
    }
    case 'moderate-event': {
      button = (
        <Button
          asModerate
          btnClick={onAction}
          btnProps={{ variant: 'extended', size: 'small' }}
        >
          {Translate({ messageKey: 'moderate' })}
        </Button>
      )
      label = Translate({ messageKey: 'moderate' })
      break
    }
    case 'confirm-event': {
      icon = <CheckIcon style={{ color: Colors.primary }} />
      label = Translate({ messageKey: 'confirm_item' })
      break
    }
    case 'unconfirm-event': {
      icon = <Replay />
      label = Translate({
        messageKey: 'revert_item',
        params: {
          item: Translate({ messageKey: 'confirmation', lower: true }),
        },
      })
      break
    }
    case 'create-many-event': {
      button = (
        <Button
          asAction
          dropdownItems={dropDownList}
          btnProps={{ variant: 'outlined', color: 'primary' }}
          btnClass={classes.createManyEvent}
        />
      )
      label = ''
      break
    }
    case 'update': {
      icon = <EditIcon />
      label = Translate({ messageKey: 'update_item' })
      break
    }
    case 'delete': {
      icon = <DeleteIcon />
      label = Translate({ messageKey: 'remove_item' })
      break
    }
    case 'adjustments': {
      icon = <AjustmentsIcon />
      label = Translate({ messageKey: 'adjustments' })
      break
    }
    case 'move': {
      icon = <SwapHorizIcon />
      label = Translate({
        messageKey: 'move_item',
        params: { item: Translate({ messageKey: 'animal', lower: true }) },
      })
      break
    }
    case 'details': {
      icon = <InfoOutlined />
      label = Translate({ messageKey: 'more_details' })
      break
    }
    case 'attach': {
      icon = <LinkIcon />
      label = Translate({
        messageKey: 'add_item',
        params: { item: Translate({ messageKey: 'attachment', lower: true }) },
      })

      break
    }
    case 'config': {
      icon = <AjustmentsIcon />
      label = Translate({ messageKey: 'config_item' })
      break
    }
    case 'hexadecimal-key': {
      icon = <Icon path={mdiKeyWireless} />
      label = Translate({ messageKey: 'hexadecimal_key' })
      break
    }
    case 'replace': {
      icon = <AutorenewIcon />
      label = Translate({ messageKey: 'replace_item' })
      break
    }
    case 'detach': {
      icon = <LinkOffIcon />
      label = Translate({
        messageKey: 'remove_item',
        params: { item: Translate({ messageKey: 'attachment', lower: true }) },
      })
      break
    }
    case 'map': {
      icon = <TableChartIcon />
      label = Translate({ messageKey: 'collar_map' })
      break
    }
    case 'edit-permissions': {
      icon = <PlaylistAddCheck />
      label = Translate({ messageKey: 'permission_plural' })
      break
    }
    case 'duplicate': {
      icon = <Duplicate />
      label = Translate({ messageKey: 'duplicate' })
      break
    }
    case 'status': {
      icon = <SettingsInputAntennaIcon />
      label = Translate({ messageKey: 'status' })
      break
    }
    case 'renew': {
      icon = <LockOpenIcon />
      label = Translate({ messageKey: 'renew_password' })
      break
    }
    case 'recovery': {
      icon = <Replay />
      label = Translate({ messageKey: 'recover_this_register' })
      break
    }
    default: {
      icon = iconDefault
      label = labelDefault
    }
  }

  return (
    <div title={label}>
      {button || (
        <IconButton
          onClick={onAction}
          aria-label={`${label}`}
          className={[
            classes.root,
            classes.label,
            classes.createManyEvent,
          ].join(' ')}
          size="large"
        >
          {icon}
        </IconButton>
      )}
    </div>
  )
}

export default RowAction
