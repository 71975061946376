import { InaptIcon, Moment, Translate } from '@chipinside/frontend'
import { Box, styled } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import toLower from 'lodash/toLower'
import React from 'react'

const Title = styled('p')({
  fontSize: 12,
  margin: '0 0 8px',
})

const Wrapper = styled(Box)({
  letterSpacing: 0.5,
})

const TooltipText = ({ status, text, from, expected_return, reasonList }) => {
  const ExpectedReturn = () => {
    if (expected_return) {
      const description =
        status === 'discard'
          ? Translate({
              messageKey: 'prediction_of',
              params: { item: toLower(text) },
            })
          : Translate({ messageKey: 'forecast_return' })

      return (
        <div>
          <span>
            {description}: {Moment(expected_return).format('L')}
          </span>
        </div>
      )
    }
  }

  const From = () => {
    return from ? (
      <div>
        <span>
          {Translate({ messageKey: 'date_of_marking' })}:{' '}
          {Moment(from).format('L')}
        </span>
      </div>
    ) : null
  }

  return (
    <Wrapper>
      <Title>{reasonList[status]}</Title>
      <From />
      <ExpectedReturn />
    </Wrapper>
  )
}

function TypeInapt({ flag, placement, size }) {
  if (!flag) {
    return null
  }
  const { status } = flag

  const reasonList = {
    discard: Translate({ messageKey: 'marked_for_discard' }),
    induction: Translate({ messageKey: 'marked_for_induction' }),
    unfit: Translate({ messageKey: 'marked_as_unfit' }),
  }

  return (
    <Tooltip
      title={<TooltipText {...flag} reasonList={reasonList} />}
      placement={placement ?? 'right'}
      arrow
    >
      <div>
        <InaptIcon status={status} size={size} />
      </div>
    </Tooltip>
  )
}

export default TypeInapt
