import { Alert, AlertProps, AlertTitle } from '@mui/material'
import { styled } from '@mui/styles'

const StyledAlert = styled(Alert)({
  '&:hover': {
    opacity: 0.95,
    cursor: 'pointer',
  },
  '&:active': {
    transform: 'scale(0.99)',
    opacity: 0.9,
  },
  'transition': 'transform 0.2s, opacity 0.2s',
})

interface TopAlertProps {
  severity?: AlertProps['severity']
  variant?: AlertProps['variant']
  title: string
  content: string | string[]
  onClick: () => void
}

const TopAlert: React.FC<TopAlertProps> = ({
  severity = 'error',
  variant,
  title,
  content,
  onClick,
}) => {
  const items = typeof content === 'string' ? [content] : content

  return (
    <StyledAlert
      className="noselect"
      variant={variant}
      severity={severity}
      onClick={onClick}
    >
      <AlertTitle>{title}</AlertTitle>
      <ul style={{ marginBottom: 0 }}>
        {items?.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    </StyledAlert>
  )
}

export { TopAlert }
