import React from 'react'

import { Square } from './Square'

export const HourScale = props => {
  const { hours, currentHour, styles, size } = props

  // set the interval between the hours displayed

  const distribution = size === 'small' ? 15 : 3

  return (
    <div style={styles.line_container}>
      {hours.map((item, index) => {
        return (
          <div style={styles.hours} key={`key-${index}`}>
            {currentHour === item.hour && (
              <div style={styles.currentHour_line}>
                <Square style={styles.currentHour_base}>{item.hour}</Square>
              </div>
            )}
            {index % distribution === 0 && currentHour !== item.hour
              ? `${item.hour}h`
              : null}
          </div>
        )
      })}
    </div>
  )
}
