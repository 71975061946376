import CheckboxMaterial from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'

import classes from './Checkbox.module.css'

const Checkbox = props => {
  const {
    checked = false,
    value = '',
    label = '',
    onChange = () => {},
    disabled = false,
    name,
  } = props
  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <CheckboxMaterial
            checked={checked}
            onChange={e => {
              onChange(name, e.target.checked, e.target.value)
            }}
            value={value}
            color="primary"
            disabled={disabled}
          />
        }
        label={label}
      />
    </div>
  )
}

export default Checkbox
