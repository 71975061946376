import { isEqual } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { adjustColumnObject } from '#/components/datagrid/DatagridContainer/adjustColumnObject'
import DatagridContainer from '#/components/datagrid/DatagridContainer/DatagridContainer'
import { manipulateFilters } from '#/components/datagrid/DatagridFiltersContainer/manipulateFilters'
import FormatCell from '#/components/FormatCell'
import { getDatagrid } from '#/store/ducks/datagrid'

const manipulateColumnsDefault = columns => {
  if (!columns) return []
  return columns.map((head, index) => {
    let response = adjustColumnObject(head, index)
    switch (head.name) {
      default: {
        response = {
          ...response,
          renderCell: (value, row) => ({
            value: (
              <FormatCell value={value} type={head.type} bold={row.total} />
            ),
          }),
        }
      }
    }

    return response
  })
}

export function PageBuilderDatagrid({ id, controller, datagridProps = {} }) {
  const {
    manipulateColumns = manipulateColumnsDefault,
    actionsColumn = [],
    topRightButtonCreateProps = {},
    withInfinitePagination,
  } = datagridProps[id] ?? {}

  const name = id ?? window.location.pathname

  const {
    data: datagrid,
    error,
    status,
    exporting,
  } = useSelector(state => getDatagrid(state, name), isEqual)

  const datagridParams = {
    ...datagrid,
    controller,
    exporting,
    name,
    error,
    status,
    topRightButtonCreateProps,
    actionsColumn,
  }

  function manipulateDatagrid(stateFetch) {
    let { list, pagination, columns, filters, search, summary } = stateFetch

    columns = manipulateColumns(columns)
    filters = manipulateFilters(filters, columns)

    return { list, pagination, columns, filters, search, summary }
  }

  return (
    <DatagridContainer
      {...datagridParams}
      manipulateDatagrid={manipulateDatagrid}
      withInfinitePagination={withInfinitePagination}
      renderOnly={!controller}
      noPaperWrapped
    />
  )
}
