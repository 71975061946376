import { Moment } from '@chipinside/frontend'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

const TypeFromNow = ({ value }) => {
  if (!value) return <span>-</span>
  return (
    <Tooltip title={Moment.view(value)}>
      <div>{Moment(value).fromNow()}</div>
    </Tooltip>
  )
}
export default TypeFromNow
