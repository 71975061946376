import Colors from '#/styles/Colors'

export const PageBuilderColors = {
  //Feedback
  'success': '#59B852',
  'error': Colors.feedback.danger.color,
  'info': Colors.feedback.info.color,
  'warning': '#FDF56F',

  //Reproduction
  'lateInseminated': Colors.reproduction.lateInseminated.backgroundColor,
  'inseminated': Colors.reproduction.inseminated.backgroundColor,
  'calf': Colors.reproduction.calf.backgroundColor,
  'calfNewBorn': Colors.reproduction.calfNewBorn.backgroundColor,
  'empty': Colors.reproduction.empty.backgroundColor,
  'emptyPostDelivery': Colors.reproduction.emptyPostDelivery.backgroundColor,
  'heifer': Colors.reproduction.heifer.backgroundColor,
  'male': Colors.reproduction.male.backgroundColor,
  'prostaglandin': Colors.reproduction.prostaglandin.backgroundColor,
  'pregnant': Colors.reproduction.pregnant.backgroundColor,
  'pregnantPreDelivery':
    Colors.reproduction.pregnantPreDelivery.backgroundColor,
  'hormoneImplanted': Colors.reproduction.hormoneImplanted.backgroundColor,
  'implantRemoved': Colors.reproduction.implantRemoved.backgroundColor,

  //Multi, Primi, Adult
  'primiparous': Colors.animal.primiparous.backgroundColor,
  'multiparous': Colors.animal.multiparous.backgroundColor,
  'adult': Colors.animal.adult.backgroundColor,

  //Production
  'lactating': '#C9E4F5',
  'dry': '#A5C7E2',
  'pre-calving': '#83A5C5',

  'normal': Colors.health.normal.graph,
  'challenge': Colors.health.challenge.graph,
  'critical': Colors.health.critical.graph,
  'observation': Colors.health.observation.graph,
}
