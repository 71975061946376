import { Moment } from '@chipinside/frontend'

import { getCurrentFarmTimezone } from '#/store/ducks/farm'

import seriesFormatter from './series'
import tooltipFormatter from './tooltip'
import xAxisFormatter from './xAxis'
import yAxisFormatter from './yAxis'

const tick = {
  'hourly': {
    pointInterval: 1 * 3600 * 1000,
    tickInterval: 24 * 3600 * 1000,
  },
  'daily': {
    pointInterval: 24 * 3600 * 1000,
    tickInterval: 24 * 3600 * 1000,
  },
  'weekly': {
    pointInterval: 7 * 24 * 3600 * 1000,
    tickInterval: 7 * 24 * 3600 * 1000,
  },
  'twenty-one-days': {
    pointInterval: 14 * 24 * 3600 * 1000,
    tickInterval: 14 * 24 * 3600 * 1000,
  },
  'monthly': {
    pointInterval: 30 * 24 * 3600 * 1000,
    tickInterval: 30 * 24 * 3600 * 1000,
  },
}

export default function ManipulateCharts(props) {
  const {
    data,
    xAxisFormat,
    extraLegends,
    plotBandsColors,
    overwrite,
    colorIndex,
    enableLimitColors,
    plotLines,
    tooltipIgnore,
  } = props

  /* Setting the format of the tooltip. */
  const tooltipFormat = ['hourly', 'twice-daily'].includes(data.graph_tick)
    ? 'L LT'
    : 'L'

  const { series, xAxis, yAxis, tooltip } = data
  switch (data.chart?.type) {
    /**
     * Gráficos pizza
     */
    case 'pie':
      return {
        ...data,
        series: seriesFormatter({
          series,
          extraLegends,
          type: 'pie',
          colorIndex,
        }),
        exporting: { enabled: false },
        ...overwrite,
      }

    /**
     * Gráficos de pontos
     */
    case 'scatter':
      return {
        ...data,
        series: seriesFormatter({ series, extraLegends, colorIndex }),
        exporting: { enabled: false },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
            },
          },
        },
        ...overwrite,
      }

    /**
     * Gráficos gerais
     */
    default: {
      if (!plotLines) {
        /**
         * Retorno dos dados sem plotlines
         */
        return {
          ...data,
          xAxis: xAxisFormatter({ xAxis, xAxisFormat }),
          yAxis: yAxisFormatter(yAxis, plotBandsColors),
          series: seriesFormatter({
            series,
            extraLegends,
            colorIndex,
            yAxis,
            enableLimitColors,
          }),
          ...(!tooltipIgnore && {
            tooltip: tooltipFormatter(tooltip, tooltipFormat),
          }),
          exporting: { enabled: false },
          ...overwrite,
        }
      }
      /**
       * Retorno dos dados com plotlines
       */
      const { plotData, range, plotTypes } = plotLines
      const state = window?.globalStore?.getState()
      const plotKeys = Object.keys(plotData)
      const TIMEZONE = getCurrentFarmTimezone(state)
      const { tickInterval } = tick[data.graph_tick]

      const getPointStart = () => {
        if (!plotData) return null
        return xAxisFormat === 'DD/MM'
          ? Moment(xAxis[0].categories[0]).startOf('d').valueOf()
          : Moment(xAxis[0].categories[0]).valueOf()
      }

      return {
        ...data,
        yAxis: yAxisFormatter(yAxis, plotBandsColors),
        xAxis: xAxisFormatter({
          xAxis,
          xAxisFormat,
          plotKeys,
          plotData,
          range,
          TIMEZONE,
          tickInterval,
          plotTypes,
          graph_tick: data.graph_tick,
        }),
        series: seriesFormatter({
          series,
          extraLegends,
          plotKeys,
          plotTypes,
          colorIndex,
          enableLimitColors,
          yAxis,
        }),
        plotOptions: {
          series: {
            pointStart: getPointStart(),
            pointInterval: tick[data.graph_tick].pointInterval,
          },
        },
        tooltip: tooltipFormatter(tooltip, tooltipFormat),
        time: { timezone: TIMEZONE, useUTC: false },
        exporting: { enabled: false },
        ...overwrite,
      }
    }
  }
}
