import {
  CanSortIcon,
  QuickTip,
  SortAscIcon,
  SortDescIcon,
} from '@chipinside/frontend'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import Fonts from '#/styles/Fonts'

import classes from './DatagridCell.module.css'

const styles = {
  orderPriority: {
    fontSize: Fonts.fontSize.SS,
  },
  help: {
    gridTemplateColumns: 'auto auto auto auto',
  },
  row: {
    color: 'rgba(0, 0, 0, 0.3)',
    sorted: {
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
}

const DatagridCell = props => {
  const {
    cellClass = '',
    cellStyle = {},
    component = null,
    title = '',
    onClick = () => {},
    withoutData = '-',
    empty = false,
    orderable = false,
    order = {},
    help = null,
  } = props
  const cellClasses = [classes.cell]

  let orderIcon = null
  if (orderable) {
    const themeClassIcon = isEmpty(order) ? styles.row : styles.row.sorted

    cellClasses.push(classes.canBeOrderable)
    if (isEmpty(order)) {
      orderIcon = (
        <CanSortIcon className={classes.sortIcon} style={themeClassIcon} />
      )
    } else if (order.direction === 'asc') {
      orderIcon = (
        <SortAscIcon className={classes.sortedIcon} style={themeClassIcon} />
      )
    } else if (order.direction === 'desc') {
      orderIcon = (
        <SortDescIcon className={classes.sortedIcon} style={themeClassIcon} />
      )
    }
  }

  return (
    <div
      className={[...cellClasses, cellClass].join(' ')}
      style={{
        ...cellStyle,
        ...(help && styles.help),
      }}
      onClick={onClick}
    >
      {orderIcon}
      <span>{!empty && (component || title || withoutData)}</span>
      {help && <QuickTip title={help.title} description={help.body} />}
    </div>
  )
}

export default DatagridCell
