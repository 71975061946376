import { Grid, Moment, Translate } from '@chipinside/frontend'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

import LinearIndeterminate from '#/components/Loader'

import { LabelWithHelp } from '../Fields/LabelWithHelp'

const ButtonsContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 16,
})

/* prettier-ignore */
const FormBuilderComponents = {
  Loader:                       props => <LinearIndeterminate  {...props} absolute />,
  Container:                    props => <Grid                 {...props} container spacing={2} />,
  ItemWrapper:                  props => <Grid                 {...props}/>,
  ButtonsContainer:             props => <ButtonsContainer     {...props} size={12} />,
  SubmitButton:                 props => <Button               {...props} variant="contained"/>,
  ExtraButtons:                 props => <Button               {...props} variant="outlined" color="primary" />,
  Form:                         props => <form                 {...props} noValidate />,
  LabelField:                   props => <LabelWithHelp        {...props}  />,
  Moment:                       props => Moment(props),
  Translate:                    props => Translate({ messageKey: props?.key, params:{item: props?.params?.label} }),
}

export { FormBuilderComponents }
