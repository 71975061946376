import { Moment } from '@chipinside/frontend'
import React from 'react'

import CowmedChip from '#/components/Chip'
import { monitoringVerifyRecentData } from '#/components/reportStatus/MonitoringStatus'
import Colors from '#/styles/Colors'

export function getThiColor(thi) {
  let color = Colors.thi.thermal_comfort
  if (thi >= 68) {
    color = Colors.thi.stress_zone
  }
  if (thi >= 72) {
    color = Colors.thi.mild_to_moderate_stress
  }
  if (thi >= 80) {
    color = Colors.thi.moderate_to_severe_stress
  }
  if (thi >= 90) {
    color = Colors.thi.severe_stress
  }

  return color
}

const TypeThi = ({ value, timestamp }) => {
  let formattedValue = value

  if (!value) return '-'

  if (timestamp) {
    let lastRead = Moment.diff('now', timestamp, 'h')
    formattedValue = monitoringVerifyRecentData({
      value,
      timestamp,
      withMinutesToHours: false,
    })

    if (lastRead >= 48) {
      return formattedValue
    }
  }

  return (
    <CowmedChip
      value={formattedValue}
      customStyle={getThiColor(value)}
      width={70}
    />
  )
}

export default TypeThi
