import { withStyles } from '@mui/styles'
import React from 'react'

import TypeAiTimeline from '#/components/FormatCell/Types/AiTimeline'
import TypeAnimalStatus from '#/components/FormatCell/Types/animalStatus'
import TypeAttachments from '#/components/FormatCell/Types/attachments'
import TypeAvatarLink from '#/components/FormatCell/Types/AvatarLink'
import TypeBatchesList from '#/components/FormatCell/Types/batchList'
import TypeBoolean from '#/components/FormatCell/Types/boolean'
import TypeBreathingIndicator from '#/components/FormatCell/Types/breathingIndicator'
import TypeButtonLink from '#/components/FormatCell/Types/ButtonLink'
import TypeCalvesBadge from '#/components/FormatCell/Types/calvesBadge'
import TypeCcomBadgeLink from '#/components/FormatCell/Types/ccomBadgeLink'
import TypeCComCoverage from '#/components/FormatCell/Types/ccomCoverage'
import TypeCComStatus from '#/components/FormatCell/Types/ccomStatus'
import TypeCEnv from '#/components/FormatCell/Types/cenv'
import TypeChip from '#/components/FormatCell/Types/chip'
import TypeCollarStatusBadge from '#/components/FormatCell/Types/collarStatusBadge'
import TypeCollarStockBadge from '#/components/FormatCell/Types/collarStockBadge'
import { TypeContractStatus } from '#/components/FormatCell/Types/contractStatus'
import TypeCTech from '#/components/FormatCell/Types/ctech'
import TypeDate from '#/components/FormatCell/Types/date'
import TypeDTR from '#/components/FormatCell/Types/dtr'
import TypeEnum from '#/components/FormatCell/Types/enum'
import TypeEventGroupList from '#/components/FormatCell/Types/eventGroupList'
import TypeEventInfos from '#/components/FormatCell/Types/eventInfos'
import TypeFailsReportVariation from '#/components/FormatCell/Types/failsReportVariation'
import TypeFromNow from '#/components/FormatCell/Types/fromNow'
import TypeHasDataBadge from '#/components/FormatCell/Types/hasDataBadge'
import TypeHealth from '#/components/FormatCell/Types/healthStatus'
import TypeHeatStatus from '#/components/FormatCell/Types/heatStatus'
import { TypeHeatStrength } from '#/components/FormatCell/Types/heatStrength'
import TypeInapt from '#/components/FormatCell/Types/inapt'
import TypeInsights from '#/components/FormatCell/Types/insights'
import TypeItemList from '#/components/FormatCell/Types/itemsList'
import TypeJobStatusBadge from '#/components/FormatCell/Types/jobStatusBadge'
import TypeLink from '#/components/FormatCell/Types/link'
import TypeMinutesToHours from '#/components/FormatCell/Types/minutesToHours'
import TypeMonitoring from '#/components/FormatCell/Types/monitoring'
import TypeMonthlyVariation from '#/components/FormatCell/Types/monthlyVariation'
import TypeMultiData from '#/components/FormatCell/Types/MultiData'
import TypeNPSCampaignAnswerGroup from '#/components/FormatCell/Types/npsCampaignAnswerGroup'
import TypeNPSCampaignStatus from '#/components/FormatCell/Types/npsCampaignStatus'
import TypePlansBadge from '#/components/FormatCell/Types/plansBadge'
import TypePortfolio from '#/components/FormatCell/Types/portfolio'
import { TypePPI } from '#/components/FormatCell/Types/ppi'
import TypePregnancyDiagnostics from '#/components/FormatCell/Types/pregnancyDiagnostics'
import TypeProduction from '#/components/FormatCell/Types/production'
import TypeProductionBadge from '#/components/FormatCell/Types/productionBadge'
import TypeProtocolStatus from '#/components/FormatCell/Types/protocolStatus'
import TypeQuickInfo from '#/components/FormatCell/Types/quickInfo'
import TypeReproduction from '#/components/FormatCell/Types/reproduction'
import TypeSequencer from '#/components/FormatCell/Types/sequencer'
import TypeStatistic from '#/components/FormatCell/Types/Statistics'
import TypeString from '#/components/FormatCell/Types/string'
import TypeStringArray from '#/components/FormatCell/Types/stringArray'
import TypeThi from '#/components/FormatCell/Types/thi'
import TypeTimestamp from '#/components/FormatCell/Types/timestamp'
import TypeUnitCollarStatus from '#/components/FormatCell/Types/unitCollarStatus'
import TypeUnitStatus from '#/components/FormatCell/Types/unitStatus'
import TypeVariation from '#/components/FormatCell/Types/variation'
import TypeWarningBadge from '#/components/FormatCell/Types/warningBadge'
import LineClampStyle from '#/styles/LineClamp'
import switchFn from '#/utils/switchfn'

const styles = () => ({
  overMe: {
    ...LineClampStyle,
    maxWidth: 250,
    maxHeight: 50,
  },
})

function FormatCell(props) {
  const { type } = props
  /* prettier-ignore */
  const Types = {
    'ccom_status':                  <TypeCComStatus {...props} />,
    'ccom_coverage':                <TypeCComCoverage {...props} />,
    'health_status':                <TypeHealth {...props} />,
    'campaign_status':              <TypeNPSCampaignStatus {...props}/>,
    'campaign_answer_group':        <TypeNPSCampaignAnswerGroup {...props}/>,
    'thi':                          <TypeThi {...props}/>,
    'batches_list':                 <TypeBatchesList {...props}/>,
    'breathing_indicator':          <TypeBreathingIndicator {...props} />,
    'variation':                    <TypeVariation {...props} />,
    'pregnancy_diagnostics':        <TypePregnancyDiagnostics {...props} />,
    'inapt':                        <TypeInapt {...props} />,
    'reproduction_status':          <TypeReproduction {...props} />,
    'production_status':            <TypeProduction {...props} />,
    'portfolio':                    <TypePortfolio {...props} />,
    'protocol_status':              <TypeProtocolStatus {...props} />,
    'attachments':                  <TypeAttachments {...props} />,
    'ppi_status':                   <TypePPI {...props} />,

    // TODO
    'ai-timeline':                  <TypeAiTimeline {...props} />,
    'animal-status':                <TypeAnimalStatus {...props} />,
    'avatar-link':                  <TypeAvatarLink {...props} />,
    'boolean':                      <TypeBoolean {...props} />,
    'button-link':                  <TypeButtonLink {...props} />,
    'c-env':                        <TypeCEnv {...props} />,
    'c-tech':                       <TypeCTech {...props} />,
    'calves-badge':                 <TypeCalvesBadge {...props} />,
    'ccom-badge-link':              <TypeCcomBadgeLink {...props} />,
    'chip':                         <TypeChip {...props} />,
    'collar-status-badge':          <TypeCollarStatusBadge {...props} />,
    'collar-stock-badge':           <TypeCollarStockBadge {...props} />,
    'contract_status':              <TypeContractStatus {...props} />,
    'date':                         <TypeDate {...props} />,
    'dtr':                          <TypeDTR {...props} />,
    'enum':                         <TypeEnum {...props} />,
    'event-group-list':             <TypeEventGroupList {...props} />,
    'event-infos':                  <TypeEventInfos {...props} />,
    'fails-report-variation':       <TypeFailsReportVariation {...props} />,
    'from-now':                     <TypeFromNow {...props} />,
    'has-data-badge':               <TypeHasDataBadge {...props} />,
    'heat-status':                  <TypeHeatStatus {...props} />,
    'heat-strength':                <TypeHeatStrength {...props} />,
    'insights':                     <TypeInsights {...props} />,
    'items-list':                   <TypeItemList {...props} />,
    'job-status-badge':             <TypeJobStatusBadge {...props} />,
    'link':                         <TypeLink {...props} />,
    'minutes-to-hours':             <TypeMinutesToHours {...props} />,
    'monitoring':                   <TypeMonitoring {...props} />,
    'monthly-variation':            <TypeMonthlyVariation {...props} />,
    'multi-data':                   <TypeMultiData {...props} />,
    'plan':                         <TypePlansBadge {...props} />,
    'production-badge':             <TypeProductionBadge {...props} />,
    'quick-info':                   <TypeQuickInfo {...props} />,
    'sequencer':                    <TypeSequencer {...props} />,
    'statistic':                    <TypeStatistic {...props} />,
    'string-array':                 <TypeStringArray {...props} />,
    'string':                       <TypeString {...props} />,
    'timestamp':                    <TypeTimestamp {...props} />,
    'unit-collar-status':           <TypeUnitCollarStatus {...props} />,
    'unit-status':                  <TypeUnitStatus {...props} />,
    'warning-badge':                <TypeWarningBadge {...props} />,
    'default': null
  }

  const switchA = switchFn(Types, 'default')

  return switchA(type)
}

export default withStyles(styles)(FormatCell)
