import { Tooltip } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'

const TypeSequencer = ({ value, reverse = false, attrName = '', classes }) => {
  if (isEmpty(value) || !Array.isArray(value)) return '-'

  const list = reverse ? [...value].reverse() : value

  const text = list.map(item => (attrName ? item[attrName] : item)).join(', ')

  return (
    <Tooltip title={text} aria-label={text}>
      <div className={classes.overMe}>{text}</div>
    </Tooltip>
  )
}

export default TypeSequencer
