import React from 'react'

import CowmedChip from '#/components/Chip'

const TypeMilkProductionTypeBadge = ({ value, text }) => {
  if (!value) return <span>-</span>

  return <CowmedChip value={text} />
}

export default TypeMilkProductionTypeBadge
