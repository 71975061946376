import { PageBuilderProvider as PageBuilderProviderPackage } from '@chipinside/pagebuilder'
import { PropsWithChildren } from 'react'

import { PageBuilderComponents } from '#/components/PageBuilder/components'
import { PageBuilderFallback } from '#/components/PageBuilder/fallback'
import { PageBuilderIcons } from '#/components/PageBuilder/icons'

export function PageBuilderProvider({ children }: PropsWithChildren) {
  return (
    <PageBuilderProviderPackage
      components={PageBuilderComponents}
      fallback={PageBuilderFallback}
      icons={PageBuilderIcons}
    >
      {children}
    </PageBuilderProviderPackage>
  )
}
