import { DtrIcon, Translate } from '@chipinside/frontend'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

import Colors from '#/styles/Colors'

const Content = styled('div')(({ align, flexDirection }) => ({
  display: 'flex',
  alignItems: align,
  flexDirection,
  gap: flexDirection === 'row' ? 10 : 0,
}))

const Label = styled('p')(({ status }) => ({
  margin: 'auto 0',
  lineHeight: '16px',
  fontWeight: 500,
  color: Colors.dtr[status],
}))

const Icon = styled(DtrIcon)(({ status }) => ({
  fontSize: 25,
  height: '30px',
  color: Colors.dtr[status],
}))

const TypeDTR = ({
  status,
  text,
  with_label = true,
  alignItems = 'start',
  flexDirection = 'row',
}) => {
  if (!status) {
    return <span>-</span>
  }

  return (
    <Tooltip title={`${Translate({ messageKey: 'DTR_initials' })} - ${text}`}>
      <Content align={alignItems} flexDirection={flexDirection}>
        <Icon status={status} />
        {with_label && <Label status={status}>{text}</Label>}
      </Content>
    </Tooltip>
  )
}

export default TypeDTR
