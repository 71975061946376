import { Stack } from '@mui/material'
import React from 'react'

import FormatCell from '#/components/FormatCell'

const TypeMultiData = ({ value }) => {
  if (!value) return <>-</>

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {value?.map((props, index) => (
        <FormatCell key={`${props.type}-${index}`} value={props} {...props} />
      ))}
    </Stack>
  )
}
export default TypeMultiData
