import { seriesColors } from '#/components/NewCharts/colors'
import { ChartBuilderRandom } from '#/components/NewCharts/randomValue'
import { HSLDarker } from '#/utils/colorManipulation'

import PlotLegend from '../PlotLines/plotLegend'

/**
 * Função de manipulação da serie
 * @param series Recebe toda series
 * @param extraLegends objeto com as legendas adicionais
 * @param colorIndex objeto com numero e id para alterar a cor de cada coluna ex.: colorIndex={{1: 'thief'}}
 * @return Retorna series com cor caso seja encontrado id no arquivo
 */
export default function seriesFormatter(props) {
  const {
    series,
    extraLegends,
    plotKeys,
    plotTypes,
    type,
    colorIndex,
    enableLimitColors = true,
  } = props
  const randomDataEnabled = import.meta.env.VITE_CHART_RANDOM_DATA === 'true'

  if (type === 'pie') {
    const formattedSeries = Array.isArray(series) ? series : [series]
    return formattedSeries.map(serie => {
      series.data = serie?.data?.map(data => {
        /*Aplica cor para cada data */
        data.color = seriesColors[data.id]
        return data
      })
      return series
    })
  }
  const limit = getLimit(series)
  const formattedSeries = series.map(item => {
    const serie = randomDataEnabled
      ? ChartBuilderRandom.randomData(item, props.yAxis)
      : item

    return {
      ...serie,
      data: serie.data.map(value => {
        /* Aplica cor para colorIndex */
        if (value?.colorIndex) {
          value.color = seriesColors[colorIndex[value.colorIndex]]
        }
        return value
      }),
      zIndex: getSeriesZIndex(serie.type),
      color: seriesColors[serie.id],
      ...(limit &&
      enableLimitColors &&
      serie.id !== 'limit' &&
      serie.yAxis === 0 &&
      seriesColors[serie.id]
        ? {
            zones: [
              {
                value: limit,
                color: seriesColors[serie.id],
              },
              {
                color: HSLDarker(seriesColors[serie.id], 30),
              },
            ],
          }
        : {}),
    }
  })

  /**
   * Adiciona legendas extras
   */
  const formattedExtraLegends = extraLegends
    ? extraLegends.map(extra => ({
        marker: { enabled: false },
        type: 'area',
        ...extra,
        events: { legendItemClick: () => false },
      }))
    : []

  /**
   * Adiciona plotLegends caso exista o endpoint
   */
  const plotLegends = plotKeys ? PlotLegend(plotKeys, plotTypes) : []

  return [...formattedSeries, ...formattedExtraLegends, ...plotLegends]
}

/**
 * Get Limit Value, used for attribute Zone (color)
 */
function getLimit(series) {
  const LimitValue = series
    .filter(serie => serie.id === 'limit')
    .map(serie => {
      return serie.data[0]
    })
  return LimitValue[0]
}

function getSeriesZIndex(type) {
  switch (type) {
    case 'areaspline':
      return 0

    case 'spline':
    case 'line':
      return 2

    default:
      return 1
  }
}
