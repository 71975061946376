import { CowBabyIcon, Translate } from '@chipinside/frontend'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { Link } from 'react-router-dom'

import CowmedChip from '#/components/Chip'
import { usePermissions } from '#/store/hooks/permissions'
import Colors from '#/styles/Colors'

const LinkStyled = styled(Link)({
  '&:hover': {
    opacity: '.5',
  },
})

const CollarBadge = ({ status = 'no-status-info', link, value, is_baby }) => {
  const hasStatus = status !== 'no-status-info'

  const {
    permissions: { farm, system },
  } = usePermissions({
    system: ['view_collar_status_report'],
    farm: ['view_collar_status_report'],
  })

  let { backgroundColor } = Colors.devices.ctech.normal
  let badgeTitle = null

  if (farm.viewCollarStatusReport || system.viewCollarStatusReport) {
    switch (status) {
      case 'replace':
        backgroundColor = Colors.devices.ctech.replace.backgroundColor
        badgeTitle = 'to_verify'
        break

      case 'revision':
        backgroundColor = Colors.devices.ctech.revision.backgroundColor
        break

      case null:
      case undefined:
        backgroundColor = Colors.muted
        badgeTitle = 'without_information'
        break

      default: // nothing to moo
    }
  }

  const title =
    badgeTitle && hasStatus ? Translate({ messageKey: badgeTitle }) : ''

  if (link) {
    return (
      <Tooltip
        title={Translate({
          messageKey: ['access_item', title],
          translate: [true, false],
          params: [{ item: Translate({ messageKey: 'collar', lower: true }) }],
        })}
        aria-label={value}
      >
        <LinkStyled to={link}>
          <CowmedChip
            sx={{
              '& .MuiChip-label': {
                marginBottom: '-1px',
              },
            }}
            icon={is_baby ? <CowBabyIcon style={{ color: '#fff' }} /> : null}
            value={value}
            customStyle={{ backgroundColor, color: '#fff', cursor: 'pointer' }}
            width="unset"
          />
        </LinkStyled>
      </Tooltip>
    )
  }

  return <span>{value}</span>
}

export default CollarBadge
