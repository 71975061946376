import { styled } from '@mui/material/styles'
import React from 'react'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const HealthChip = styled(CowmedChip)(({ status }) => ({
  backgroundColor: `${Colors.health[status]?.backgroundColor} !important`,
  color: `${Colors.health[status]?.color} !important`,
}))

const HealthBadge = props => {
  const { status, text } = props

  return <HealthChip value={text} status={status} />
}

export default HealthBadge
