import { Translate } from '@chipinside/frontend'
import { ElementGroup } from '@chipinside/pagebuilder/dist/types'
import { Button, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { isEmpty, isEqual, uniqueId } from 'lodash'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getCurrentFarmSlug } from '#/store/ducks/farm'

const StyledBox = styled(Box)({
  display: 'flex',
  padding: '12px',
  gap: 10,
  justifyContent: 'flex-end',
})

const ButtonLabel = ({ label }) =>
  Translate({
    messageKey: 'access_item',
    params: {
      item: Translate({ messageKey: label as string, lower: true }),
    },
  })

const ACTION_TYPES = {
  'access-batch': ({ farm }) => (
    <Link to={`/farm/${farm}/modules/herd/batch`}>
      <Button variant="outlined">
        <ButtonLabel label="batch_plural" />
      </Button>
    </Link>
  ),
  'access-infirmary': ({ farm }) => (
    <Link to={`/farm/${farm}/modules/health/infirmary`}>
      <Button variant="outlined">
        <ButtonLabel label="infirmary" />
      </Button>
    </Link>
  ),
}

export const PageBuilderActions = ({ id, actions }: ElementGroup) => {
  const farm = useSelector(getCurrentFarmSlug, isEqual)

  return !isEmpty(actions) ? (
    <>
      <Divider sx={{ opacity: 0.7 }} />
      <StyledBox id={`${id}-actions`}>
        {actions?.map(action => (
          <div id={action.id} key={uniqueId()}>
            {ACTION_TYPES[action.id]?.({ farm })}
          </div>
        ))}
      </StyledBox>
    </>
  ) : null
}
