import React from 'react'

import MonitoringBadge from '#/components/reportStatus/MonitoringStatus'

const TypeMonitoring = ({ value, collar, timestamp, ...rest }) => {
  if (!value) return <span>-</span>
  return (
    <MonitoringBadge
      value={value}
      collar={collar}
      timestamp={timestamp}
      {...rest}
    />
  )
}
export default TypeMonitoring
