import { useFetch } from '@chipinside/fetcher'
import {
  Grid,
  HardwareIcon,
  HealthIcon,
  Moment,
  ReproductionIcon,
  Translate,
} from '@chipinside/frontend'
import DeletedEventGroupIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import EyeIcon from '@mui/icons-material/RemoveRedEye'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import React, { useCallback, useEffect, useState } from 'react'

import Colors from '#/styles/Colors'
import OldColors from '#/styles/Old/Colors'

import BooleanBadge from '../badges/BooleanBadge'
import HealthBadge from '../badges/HealthBadge'
import { useEventStyles } from './styles'

const EventGroupList = ({
  route = '',
  animal = {},
  eventGroupDeleted = false,
}) => {
  const classes = useEventStyles()

  const [modal, setModal] = useState({
    open: false,
    content: Translate({ messageKey: 'without_information' }),
    title: Translate({ messageKey: 'event_plural' }),
    loading: false,
    success: false,
  })

  const { data, error, isLoading } = useFetch({
    controller: route && modal.loading ? route : null,
  })

  const renderModalContent = useCallback(events => {
    return (
      <div className={classes.rootList}>
        {events.map((event, key) => {
          let reproductionEvent = event.event_type.includes('reproduction')
          let avatarIcon = null

          if (reproductionEvent) {
            if (event.from_collar)
              avatarIcon = (
                <HardwareIcon style={{ color: OldColors.view_health }} />
              )
            else
              avatarIcon = (
                <div className={classes.wrapper}>
                  <ReproductionIcon style={{ color: OldColors.view_cattle }} />
                  <EyeIcon
                    className={[
                      classes.iconVisualHealth,
                      classes.iconVisualReproduction,
                    ].join(' ')}
                  />
                </div>
              )
          } else if (event.event_type === 'visual')
            avatarIcon = (
              <div className={classes.wrapper}>
                <HealthIcon style={{ color: OldColors.view_cattle }} />
                <EyeIcon className={classes.iconVisualHealth} />
              </div>
            )
          else if (event.event_type === 'from-collar')
            avatarIcon = (
              <HardwareIcon style={{ color: OldColors.view_health }} />
            )
          else
            avatarIcon = <HealthIcon style={{ color: OldColors.view_health }} />

          return (
            <Grid
              container
              key={key}
              style={{ padding: '15px 5px' }}
              className={classes.containerList}
            >
              {/* Avatar Icon header */}
              <Grid size={1}>
                <Avatar
                  style={{ backgroundColor: '#e4e4e4', fontSize: '1.25em' }}
                >
                  {avatarIcon}
                </Avatar>
              </Grid>

              {/*First Line Animal Info*/}
              <Grid size={11}>
                <Grid container alignItems="center" justifyContent="flex-start">
                  {reproductionEvent ? (
                    <Grid size={4}>
                      <span className={classes.textMutedBold}>
                        {' '}
                        {event.event_type_text}
                      </span>
                    </Grid>
                  ) : null}

                  <Grid size={4}>
                    <span className={classes.textMuted}>
                      {' '}
                      {Moment.view(event.timestamp)}
                    </span>
                  </Grid>

                  {event.del ? (
                    <Grid size={4}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className={classes.title}>
                          {Translate({ messageKey: 'DEL' })}
                        </span>
                        :&nbsp;
                        <div className={classes.value}>{event.del}</div>
                      </div>
                    </Grid>
                  ) : null}

                  {event.health_status ? (
                    <Grid size={4}>
                      <HealthBadge
                        status={event.health_status}
                        text={event.health_status_text}
                      />
                    </Grid>
                  ) : null}
                </Grid>

                {/* Animal Info Container */}
                <Grid container>
                  {Object.keys(event).map(eventKey => {
                    if (!isNil(event[eventKey])) {
                      let response = {
                        label: null,
                        value: (
                          <div className={classes.value}>{event[eventKey]}</div>
                        ),
                      }
                      let reproductionCode =
                        event.event_type.includes('reproduction')
                      let healthCode =
                        event.event_type.includes('from-collar') ||
                        event.event_type.includes('status') ||
                        event.event_type.includes('visual')

                      switch (eventKey) {
                        case 'recommendation':
                          response.label = Translate({
                            messageKey: 'recommendation',
                          })
                          response.value =
                            event.recommendation_text || event[eventKey]
                          break

                        case 'comment':
                        case 'comments':
                        case 'moderation_comment':
                        case 'observation':
                          response.label = Translate({
                            messageKey: 'comment_plural',
                          })
                          break

                        case 'animal_condition':
                          response.label = Translate({
                            messageKey: 'animal_condition_today',
                          })
                          response.value = Translate({
                            messageKey: event[eventKey],
                          }) //REVISAR
                          break

                        case 'treatment':
                          response.label = Translate({
                            messageKey: 'used_treatment',
                          })
                          break

                        case 'diagnostic':
                          response.label = Translate({
                            messageKey: 'diagnostic',
                          })
                          response.value = event.diagnostic_text
                          break

                        case 'confirmed':
                          response.label = Translate({
                            messageKey: 'confirmed',
                          })
                          response.value = (
                            <BooleanBadge value={!!event[eventKey]} />
                          )
                          break

                        case 'confirmed_at':
                          if (reproductionCode) {
                            response.label = event.from_collar // cio da coleira
                              ? Translate({ messageKey: 'confirmed_at' })
                              : null
                          } else {
                            response.label = Translate({
                              messageKey: 'confirmed_at',
                            })
                            response.value = Moment.view(event[eventKey])
                          }
                          break

                        case 'confirmed_by':
                          if (reproductionCode) {
                            // cio da coleira
                            response.label = event.from_collar
                              ? Translate({ messageKey: 'confirmed_by' })
                              : null
                          } else {
                            response.label = Translate({
                              messageKey: 'confirmed_by',
                            })
                          }
                          break

                        case 'event_type':
                          if (reproductionCode) {
                            if (event.from_collar) {
                              response.label = Translate({
                                messageKey: 'detected_by_collar',
                              })
                              response.value = (
                                <BooleanBadge value={!!event.from_collar} />
                              )
                            }
                          } else if (event[eventKey] === 'from-collar') {
                            response.label = Translate({
                              messageKey: 'detected_by_collar',
                            })
                            response.value = (
                              <BooleanBadge value={!!event[eventKey]} />
                            )
                          } else if (event[eventKey] === 'status') {
                            response.label = Translate({
                              messageKey: 'automatically_detected',
                            })
                            response.value = (
                              <BooleanBadge value={!!event[eventKey]} />
                            )
                          }
                          break

                        case 'created_by':
                          response.label = Translate({
                            messageKey: 'registered_by',
                          })
                          break

                        case 'detected_at':
                          if (
                            Moment.diff(
                              event[eventKey],
                              event.timestamp,
                              'ms',
                            ) !== 0
                          ) {
                            response.label = Translate({
                              messageKey: 'collar_event_detected_at',
                            })
                            response.value = Moment.view(event[eventKey])
                          }
                          break

                        case 'received_at':
                          response.label = Translate({
                            messageKey: 'collar_event_received_at',
                          })
                          response.value = Moment.view(event[eventKey])
                          break

                        case 'created_at':
                          if (reproductionCode) {
                            response.label = event.from_collar
                              ? Translate({ messageKey: 'processed_at' }) // regenerated from collar
                              : Translate({
                                  messageKey: 'created_item_at',
                                  params: { item: '' },
                                }) //created by user
                          } else if (healthCode) {
                            response.label = event.from_algorithm
                              ? Translate({ messageKey: 'processed_at' }) // regenerated from collar
                              : Translate({ messageKey: 'created_item_at' }) //created by user
                          }
                          response.value = Moment.view(event[eventKey])
                          break

                        /* Exclusive from Reproduction Events*/
                        case 'bull_semen':
                          response.label = Translate({
                            messageKey: 'bull_or_semen',
                          })
                          break

                        case 'with_anormal_behavior':
                        case 'abnormal_behaviour':
                          response.label = Translate({
                            messageKey:
                              'did_the_animal_exhibit_any_abnormal_behavior',
                          })
                          response.value = (
                            <BooleanBadge value={!!event[eventKey]} />
                          )
                          break

                        case 'gender':
                          response.label = Translate({
                            messageKey: 'animal_gender',
                          })
                          response.value = Translate({
                            messageKey: event[eventKey],
                          })
                          break

                        case 'complication':
                          response.label = Translate({
                            messageKey: 'calvin_complication',
                          })
                          response.value = (
                            <BooleanBadge value={!!event[eventKey]} />
                          )
                          break

                        case 'with_ftai':
                          response.label = Translate({
                            messageKey: 'heat_type_iatf',
                          })
                          response.value = (
                            <BooleanBadge value={!!event[eventKey]} />
                          )
                          break

                        case 'with_heat':
                          response.label = Translate({
                            messageKey: 'heat_register_12_hours_before',
                          })
                          response.value = (
                            <BooleanBadge value={!!event[eventKey]} />
                          )
                          break

                        case 'description':
                          response.label = Translate({
                            messageKey: 'description',
                          })
                          break

                        case 'restored':
                          response.label = Translate({
                            messageKey: 'recovered_event',
                          })
                          response.value = (
                            <BooleanBadge value={!!event[eventKey]} />
                          )
                          break

                        default:
                          break
                      }

                      return response.label ? (
                        <Grid
                          key={eventKey}
                          size={12}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <span className={classes.title}>
                            {' '}
                            {response.label}{' '}
                          </span>
                          :&nbsp;
                          <div className={classes.value}>{response.value}</div>
                        </Grid>
                      ) : null
                    }
                    return null
                  })}
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </div>
    )
  }, [])

  useEffect(() => {
    if (!isEmpty(data) && !isLoading) {
      const modalContent = renderModalContent(data)
      setModal(prevState => ({
        ...prevState,
        open: true,
        content: modalContent,
        loading: false,
        success: true,
      }))
    }
  }, [data, isLoading, renderModalContent])

  const getEventGroupHandler = animal => {
    setModal(prev => ({
      ...prev,
      title: (
        <div style={{ color: OldColors.primary, fontWeight: 'bold' }}>
          {animal?.earring} {animal?.name ? ` - ${animal.name}` : ''}
          <Divider />
        </div>
      ),
      loading: true,
    }))
  }

  const { loading, success } = modal
  let healthIcon = null

  if (eventGroupDeleted) {
    healthIcon = (
      <div
        className={classes.healthIconStatus}
        title={Translate({ messageKey: 'without_information' })}
      >
        <HealthIcon style={{ color: OldColors.muted }} />
      </div>
    )
  } else if (animal.health_status) {
    if (
      animal.health_status === 'normal' ||
      animal.health_status === 'recovered'
    ) {
      healthIcon = (
        <div
          className={classes.healthIconStatus}
          title={Translate({ messageKey: 'cow_normalized' })}
        >
          <HealthIcon style={{ color: OldColors.recovered }} />
        </div>
      )
    } else {
      healthIcon = (
        <div
          className={classes.healthIconStatus}
          title={Translate({ messageKey: 'cow_health_anomaly' })}
        >
          <HealthIcon style={{ color: Colors.danger }} />
        </div>
      )
    }
  }

  return (
    <div>
      <div className={classes.wrapper}>
        <IconButton
          aria-label="info"
          disabled={eventGroupDeleted}
          onClick={() => {
            route && getEventGroupHandler(animal)
          }}
          size="large"
        >
          {eventGroupDeleted ? (
            <DeletedEventGroupIcon />
          ) : (
            <InfoIcon
              className={
                success
                  ? classes.iconSuccess
                  : error
                    ? classes.iconError
                    : classes.icon
              }
            />
          )}
        </IconButton>
        {healthIcon || null}
        {loading && isLoading && (
          <CircularProgress size={25} className={classes.fabProgress} />
        )}
      </div>
      {modal.open ? (
        <Dialog
          open={modal.open}
          onClose={() => {
            setModal(prev => ({
              ...prev,
              open: false,
              loading: false,
              success: false,
            }))
          }}
          fullWidth
          maxWidth="sm"
        >
          {modal.title && (
            <DialogTitle id="event-group-dialog-title">
              {modal.title}
            </DialogTitle>
          )}
          {modal.content}
        </Dialog>
      ) : null}
    </div>
  )
}

export default EventGroupList
