import OldColors from '#/styles/Old/Colors'
import OldPalette from '#/styles/Old/Palette'
import Palette from '#/styles/Palette'

export const seriesColors = {
  'rumination': OldColors.colorRumination,
  'rumination-profile': OldColors.colorRumination,
  'rumination-adjusted': OldPalette.green.darken[2],
  'activity': OldColors.colorActivity,
  'leisure': OldColors.colorLeisure,
  'panting': Palette.pink.dark[400],
  'total_localized': OldColors.colorMilkProduction,
  'rumination_trend_line': OldColors.colorTrend,
  'thi': OldColors.danger,
  'temperature_humidity_index': OldColors.danger,
  'intake': OldColors.intake,
  'pev': '#F3D64E',

  /* Reproduction status Colors */
  'pregnant': '#A0009B',
  'not_pregnant': OldColors.dimOnFirstService_not_pregnant,
  'awaiting_confirmation': OldColors.dimOnFirstService_awaiting_confirmation,
  'empty': '#5DAAA4',
  'dry': OldColors.reproduction_dry,
  'calf-new-born': OldColors.reproduction_calf_new_born,
  'calf': OldColors.reproduction_calf,
  'heifer': OldColors.reproduction_heifer,
  'empty-post-delivery': OldColors.reproduction_empty_post_delivery,
  'pregnant-pre-delivery': OldColors.reproduction_pregnant_pre_delivery,
  'inseminated': '#67C25A',
  'late-inseminated': OldColors.reproduction_late_inseminated,
  'heat': OldColors.colorActivity,
  'ai-hormone-implanted': OldColors.reproduction_hormone_implanted,
  'et-hormone-implanted': OldColors.reproduction_hormone_implanted,
  'waiting-artificial-insemination': OldColors.reproduction_implant_removed,
  'waiting-embryo-transfer': OldColors.reproduction_implant_removed,
  'male': OldColors.reproduction_male,
  'prostaglandin': OldColors.reproduction_prostaglandin,

  /* Heat index */
  'final_index': OldColors.final_index,
  'final_index_accumulated': OldColors.final_index_accumulated,
  'final_index_accumulated_hours': OldColors.final_index_accumulated_hours,
  'limit_one_hour': OldColors.limit_one_hour,
  'limit_two_hours': OldColors.limit_two_hours,
  'limit_n_hours': OldColors.limit_n_hours,
  'heat_index_1': OldColors.heat_index_1,
  'heat_index_2': OldColors.heat_index_2,
  'heat_index_3': OldColors.heat_index_3,

  /* Health index */
  'integral_index': OldPalette.orange.accent[2],
  'integral_observation_limit': OldPalette.orange.lighten[2],
  'integral_critical_limit': OldPalette.orange.darken[4],
  'flatten_index': OldPalette.ambar.lighten[1],
  'flatten_upper_limit': OldPalette.deep_orange.lighten[5],
  'flatten_lower_limit': OldPalette.grey.darken[5],

  'health_index': OldPalette.orange.accent[2],
  'flat_health_index': OldPalette.ambar.lighten[1],
  'limit_normal_observation': OldPalette.grey.darken[5],
  'limit_observation_critical': OldPalette.deep_orange.lighten[5],
  'limit_critical_observation': OldPalette.primary.self,
  'limit_observation_normal_without_critical': OldPalette.orange.darken[4],
  'limit_observation_normal_with_critical': OldPalette.orange.lighten[2],

  /* Performance indices */
  'expected_service_rate': OldColors.expected_rate,
  'expected_conception_rate': OldColors.expected_rate,
  'expected_mean_del': OldColors.expected_rate,
  'expected_monthly_discard_rate': OldColors.expected_rate,
  'expected_pregnancy_rate': OldColors.expected_rate,
  'expected_average_delivery_interval': OldColors.expected_rate,
  'calf_discard_rate': OldColors.reproduction_calf,
  'heifer_conception_rate': OldColors.heifer_rate,
  'heifer_pregnancy_rate': OldColors.heifer_rate,
  'heifer_service_rate': OldColors.heifer_rate,
  'heifer_discard_rate': OldColors.heifer_rate,
  'primiparous_conception_rate': OldColors.primiparous_rate,
  'primiparous_pregnancy_rate': OldColors.primiparous_rate,
  'primiparous_service_rate': OldColors.primiparous_rate,
  'primiparous_discard_rate': OldColors.primiparous_rate,
  'multiparous_conception_rate': OldColors.multiparous_rate,
  'multiparous_pregnancy_rate': OldColors.multiparous_rate,
  'multiparous_service_rate': OldColors.multiparous_rate,
  'multiparous_discard_rate': OldColors.multiparous_rate,

  /* Services */
  'services': OldColors.heat_alert,
  'goal': OldColors.expected_rate,

  /* Discard */
  'discards': OldColors.voluntary_discard,
  'departures': OldColors.sold_discard,
  'reason_percent': OldColors.voluntary_discard,

  /* Milk control */
  'scc': OldColors.scc,
  'spc': OldColors.spc,
  'protein': OldColors.protein,
  'fat': OldColors.fat,
  'solid': OldColors.solid,
  'urea': OldColors.urea,
  'lactose': OldColors.lactose,
  'snf': OldColors.snf,

  /* Calvings projection  */
  'estimated_calvings': OldPalette.pink.lighten[3],
  'calvings': OldPalette.pink.darken[4],

  /* Drying projection */
  'estimated_pregnant_dryings': OldPalette.purple.lighten[3],
  'dryings': OldPalette.purple.darken[2],
  'actual_year_period_total': OldColors.expected_rate,
  'last_year_period_total': OldColors.involuntary_discard,

  /* Conception type service*/
  'insemination_rate': OldPalette.purple.lighten[3],
  'embryo_transfer_rate': OldPalette.purple.self,
  'natural_breeding_rate': OldPalette.purple.darken[3],

  /* Contracts (dataView/accounts-and-contracts) */
  'cowconnect_accounts': OldPalette.light_blue.accent[1],
  'monitoring_accounts': OldPalette.cyan.accent[4],
  'collars': OldPalette.grey.darken[5],

  /* Lead Time (dataView/lead-time) */
  'lead_time_average': OldPalette.grey.darken[5],
  'cross_sell': OldPalette.cyan.accent[4],
  'new_contracts': OldPalette.light_blue.accent[1],

  /* Lead Time (dataView/upsell-churn) */
  'upsell': OldPalette.blue.lighten[1],
  'downsell': OldPalette.ambar.self,
  'cancellation': OldPalette.red.lighten[1],
  'crosssell_cowconnect': OldPalette.deep_orange.lighten[2],
  'crosssell_monitoring': OldPalette.green.lighten[2],

  /* Panting Profile */
  'limit': OldPalette.yellow.accent[4],
  'thermal-comfort': OldPalette.deep_orange.lighten[3],

  /* Receiving packages */
  'packages_count': OldPalette.blue.darken[1],
  'average_delay': OldPalette.red.self,

  /* Discarded Animals */
  'discarded_animals': OldColors.voluntary_discard,

  /* Lactating Animals */
  'pre_delivery': OldColors.reproduction_pregnant_pre_delivery,
  'lactating': OldColors.colorMilkProduction,

  /* DTR/AREC */
  'arec_limit_1': OldPalette.ambar.lighten[1],
  'arec_limit_2': OldPalette.red.lighten[2],

  'meals': OldPalette.primary.self,
  'average': OldPalette.primary.darken[2],

  /* Rebanho */
  'calf_new_born': OldColors.reproduction_calf_new_born,
  'inapt_heifer': OldColors.reproduction_heifer_na,
  'primiparous': OldColors.primiparous_rate,
  'multiparous': OldColors.multiparous_rate,
  'adult': OldColors.adult,
  'adult_average': OldPalette.black.self,

  /* Rates */
  'service_rate': '#6CE5E8',
  'conception_rate': '#41B8D5',
  'pregnancy_rate': '#2D8ABA',
  'expected_rate': OldColors.expected_rate,
  'gestational_loss_rate': '#e57373',

  /* Revisados */
  'panting_percentage': Palette.pink.dark[300],
  'panting_average': Palette.pink.dark[500],
  'panting-profile': Palette.pink.dark[200],
  'panting-adjusted': Palette.pink.dark[500],

  /* Taxa de reposição */
  'animals': '#000',
  'month_rate': OldPalette.blue.lighten[3],
  'six_month_rate': OldPalette.blue.darken[1],
  'twelve_month_rate': OldPalette.blue.darken[4],

  /* Perdas Gestacionais */
  'abortions': OldPalette.red.darken[2],
  'negative_pregnancies': OldPalette.red.lighten[3],
  'accumulated_losses': OldPalette.black.self,
  'losses': OldPalette.red.lighten[2],
  'total': OldPalette.black.self,

  /* Picos de atividade */
  'activity_peak_1': Palette.red.salmon[300],
  'activity_peak_2': Palette.red.self[600],

  'accumulated_discarded_animals': OldPalette.orange.lighten[1],
}
