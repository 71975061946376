import { fetcher } from '@chipinside/fetcher'
import { LoadingIconButton, SeedIcon } from '@chipinside/frontend'
import React, { useState } from 'react'

import { isLocal } from '#/utils/processEnvironment'

export default function RenderPopulateIcon(controller, refresh) {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    setIsLoading(true)
    fetcher({ controller, params: { seed_data: 1 } }).then(() => {
      setIsLoading(false)
      refresh()
    })
  }

  if (isLocal) {
    return (
      <LoadingIconButton
        title="Seed"
        size="small"
        onClick={handleClick}
        loading={isLoading}
        icon={<SeedIcon sx={{ fontSize: 32 }} />}
      />
    )
  }
  return null
}
