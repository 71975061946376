import { Translate } from '@chipinside/frontend'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import { Avatar, Box, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const StyledReqCancellationBadge = styled(Avatar)({
  ...Colors.feedback.error,
  width: '24px',
  height: '24px',
  marginLeft: '6px',
})

const TypeContractStatus = ({ value }) => {
  if (!value) return <span>-</span>

  const {
    text,
    status,
    cancellation_requested: hasCancellationRequestBadge,
  } = value

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <CowmedChip customStyle={Colors?.contract?.[status]} value={text} />
      {hasCancellationRequestBadge && (
        <Tooltip title={Translate({ messageKey: 'in_cancellation' })}>
          <StyledReqCancellationBadge>
            <PriorityHighRoundedIcon sx={{ fontSize: 18 }} />
          </StyledReqCancellationBadge>
        </Tooltip>
      )}
    </Box>
  )
}
export { TypeContractStatus }
