import { fmt, Moment, Translate } from '@chipinside/frontend'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import DateRangeDouble from '#/components/FormControl/Inputs/Picker/DateRangeDouble'

const DateRangePickerWrapper = ({
  filters,
  containerClass = '',
  onChangeFilter = () => {},
  containerStyle = {},
  disabled,
}) => {
  const formatDates = dates => {
    const [defaultFrom, defaultTo] = dates

    if (defaultFrom && defaultTo) {
      return {
        from: Moment(defaultFrom).startOf('d').format(fmt.api),
        to: Moment(defaultTo).endOf('d').format(fmt.api),
      }
    }
    return null
  }

  return filters.map((filter, index) => {
    let currentValues = []

    if (!isEmpty(filter.current)) {
      //default values from API
      const fromDb = get(filter.current, 'from', undefined)
      const toDb = get(filter.current, 'to', undefined)
      if (fromDb && toDb) {
        currentValues = [
          Moment(fromDb).startOf('d').format(fmt.api),
          Moment(toDb).endOf('d').format(fmt.api),
        ]
      }
    }

    return (
      <div key={index} className={containerClass} style={{ ...containerStyle }}>
        <DateRangeDouble
          key={index}
          index={index}
          id={filter.name}
          name={`${filter.name}`}
          label={filter.title}
          format="ll"
          defaultValues={currentValues}
          onChange={values => {
            onChangeFilter(filter.name, formatDates(values))
          }}
          placeholder={Translate({ messageKey: 'select_range' })}
          disabled={disabled}
          clearable
          emptyLabel={Translate({ messageKey: 'select_range' })}
          containerClass={containerClass}
        />
      </div>
    )
  })
}

export default DateRangePickerWrapper
