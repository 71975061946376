import { Moment } from '@chipinside/frontend'

import dateFormatSafari from '#/utils/dateFormatSafari'
/**
 * Função de manipulação do tooltip/hover
 *
 * @return Retorna tooltip formatado
 */
function getPoints(points) {
  return points
    .map(
      point =>
        `<span style="color:${point.series.color}">●</span>
        ${point.series.name}: <b>${point.y}
        ${point.series.userOptions.tooltip?.valueSuffix ?? ''}</b><br/>`,
    )
    .join('')
}

export default function tooltipFormatter(Data_tooltip, tooltipFormat) {
  return {
    ...Data_tooltip,
    shared: true,
    crosshairs: [true, true],
    formatter(tooltip) {
      const dateVerify = this.key
        ? new Date(this.key)
        : new Date(dateFormatSafari(this.x))

      if (!isNaN(dateVerify.getMonth())) {
        let tooltipDate = Moment(dateVerify).format(tooltipFormat)
        return `<span style="font-size: 10px">${tooltipDate}</span><br/>${getPoints(
          this.points,
        )}`
      }
      return tooltip.defaultFormatter.call(this, tooltip)
    },
  }
}
