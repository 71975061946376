import React from 'react'

import Checkbox from '#/components/FormControl/Inputs/Checkbox'

const BooleanFilters = props => {
  const {
    onChangeFilter = () => {},
    containerClass = '',
    containerStyle = {
      display: 'flex',
      alignItems: 'center',
    },
    disabled,
  } = props

  return props.filters.map((filter, index) => {
    return (
      <div key={index} className={containerClass} style={containerStyle}>
        <Checkbox
          name={filter.name}
          checked={filter.current}
          label={filter.title}
          onChange={onChangeFilter}
          hint={filter.hint}
          disabled={disabled}
        />
      </div>
    )
  })
}

export default BooleanFilters
