import makeStyles from '@mui/styles/makeStyles'

import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Old/Colors'

export default makeStyles(() => ({
  outputFields: {
    'display': 'flex',
    'flexWrap': 'wrap',
    '& > div': {
      'display': 'flex',
      'flexDirection': 'column',
      'justifyContent': 'flex-start',
      '& > span': {
        minHeight: 32,
        lineHeight: '14px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: Fonts.fontSize.S,
        fontWeight: Fonts.fontWeight.normal,
      },
      '& > label': {
        fontSize: Fonts.fontSize.SS,
        color: Colors.textSecondary,
        margin: '8px 0',
      },
    },
  },
  outputFieldsVertical: {
    '& > div': {
      'display': 'flex',
      '&:last-child': {
        marginBottom: 0,
      },
      '& > span': {
        fontSize: Fonts.fontSize.S,
      },
    },
  },
  label: {
    color: Colors.textSecondary,
    padding: 0,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
}))
