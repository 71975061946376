/* eslint-disable no-prototype-builtins */
import { fmt, Moment, Translate } from '@chipinside/frontend'
import Paper from '@mui/material/Paper'
import get from 'lodash/get'
import snackCase from 'lodash/snakeCase'
import React from 'react'

import FormatCell from '#/components/FormatCell'
import OutputFields from '#/components/OutputFields'
import Colors from '#/styles/Old/Colors'
import plotToHtml from '#/utils/plotToHtml'

import classes from './PlotLines.module.css'
import styles from './styles'
import { basePlot, getPlotIconHtml, labelTextHandler } from './utils'

export default function BatchEvents(events, startOf) {
  let groupedByDate = {}
  const formattedPlotLines = []

  if (events) {
    events.forEach(event => {
      //sets event datetime 23h to match categories
      const key = Moment(event.timestamp).startOf(startOf).format(fmt.api)

      //starts if necessary and populate group array
      if (!groupedByDate[key]) groupedByDate[key] = []
      groupedByDate[key] = [...groupedByDate[key], event]
    })

    //iterates through date groups
    Object.entries(groupedByDate).forEach((group, idx) => {
      const [timestamp, events] = group

      const firstEvent = get(events, '0', null)
      if (firstEvent?.hasOwnProperty('virtual_type')) {
        const plotText = plotToHtml(
          formatPlotLabelText(events.length > 1 ? events : firstEvent),
        )
        const eventClass = firstEvent.virtual_type.startsWith('rumination')
          ? `diet`
          : `batch-${firstEvent.virtual_type.split('.')[0]}`
        const plotId = `plot-line-id-${idx}-handling`
        const plotClassName = `plot-line-${eventClass}`

        formattedPlotLines.push({
          ...basePlot,
          className: `${plotId} transition-opacity-300ms ${plotClassName}`,
          value: Moment(timestamp).valueOf(),
          color: Colors.handling_general,
          label: {
            ...basePlot.label,
            text: labelTextHandler({
              id: plotId,
              className: plotClassName,
              plotTextComponent: plotText,
            }),
          },
        })
      }
    })
  }

  return formattedPlotLines
}

const formatPlotLabelText = events => {
  if (Array.isArray(events)) {
    // multiples plots on same date
    const multiPlots = []
    events.forEach(e => {
      multiPlots.push(renderPlot(e))
      multiPlots.push(<br key={`line-break-${e.slug}`} />)
    })
    return multiPlots
  }
  // one single plot
  return renderPlot(events)
}

const renderPlot = event => {
  let name = get(event, 'virtual_type_text.0', '')
  let type = snackCase(event.virtual_type.split('.').slice(0, -1))
  const eventInfos = renderEventInfos(event)

  let customStyle = {}

  return (
    <div className={classes.headerContainer} key={`${type}-plot-${event.slug}`}>
      <div style={styles.labelContainer}>
        <span
          style={styles.icon}
          dangerouslySetInnerHTML={{ __html: getPlotIconHtml(type) }}
        />
        <span
          style={{
            ...styles.name,
            backgroundColor: Colors[type],
            ...customStyle,
          }}
        >
          {name}
        </span>
      </div>
      <Paper
        key={`${type}-plot-${event.slug}-popover`}
        className={classes.popover}
        elevation={2}
      >
        <p
          style={{
            margin: 0,
            fontSize: 16,
            lineHeight: 1.5,
            color: Colors[type],
          }}
        >
          {event.virtual_type_text}
        </p>
        <p
          style={{
            margin: 0,
            marginBottom: 5,
            lineHeight: 1.5,
          }}
        >
          <FormatCell value={event.timestamp} type="timestamp" />
        </p>
        {eventInfos}
      </Paper>
    </div>
  )
}

const renderEventInfos = event => {
  const fromAlgorithm = get(event, 'creator_type') === 'algorithm'
  let fieldsArray = [
    {
      label: fromAlgorithm
        ? Translate({ messageKey: 'detected_at' })
        : Translate({ messageKey: 'registered_at_date' }),

      value: Moment.view(event.confirmed_at),
    },
    {
      label: Translate({ messageKey: 'user_name' }),
      value: get(event, 'creator.name', '-'),
    },
    {
      label: Translate({ messageKey: 'description' }),
      value: get(event, 'parameters.description', '-'),
    },
  ]

  return <OutputFields direction="row" fields={fieldsArray} />
}
